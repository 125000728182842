import { Check } from "react-feather";
import { getStyles } from "src/shared/helpers/styleConverter";
import { AppearanceType } from "src/types";
import { CheckboxComponent } from "src/types/components/CheckboxComponent";

function CheckboxComponentView({
  component,
  appearance,
}: {
  component: CheckboxComponent;
  appearance: AppearanceType;
}) {
  const appearanceKey = appearance === "dark" ? "dark" : "light";

  const styles = getStyles({
    style: component.style,
    appearance: appearanceKey,
  });

  return (
    <div className="flex w-full items-center">
      <div
        style={{
          borderRadius: styles.borderRadius,
          backgroundColor: styles.backgroundColor,
        }}
        className="mr-3 w-6 h-6 flex-shrink-0 flex items-center justify-center"
      >
        <Check className="text-white" size={16} />
      </div>
      <p
        style={{
          color: styles.color,
          fontFamily: styles.fontFamily,
          fontSize: styles.fontSize,
          fontWeight: styles.fontWeight,
          lineHeight: styles.lineHeight,
        }}
        className="text-left w-full"
      >
        {component.label}
      </p>
    </div>
  );
}

export { CheckboxComponentView };
