import { Minus } from "react-feather";
import { ColorPicker } from "src/shared/components/ColorPicker";
import { CURRENT_THEME } from "src/shared/themes";
import { DividerComponent } from "src/types/components/DividerComponent";
import { ComponentFormItem, ComponentFormProps } from "..";
import { SpacedComponentForm } from "./SpacedComponentForm";

function DividerComponentForm(props: ComponentFormProps) {
  const component = props.component as DividerComponent;

  return (
    <ComponentFormItem onDelete={props.onDelete}>
      <div className="flex space-x-4 w-full">
        <SpacedComponentForm
          component={component}
          onChange={(margins) =>
            props.onChange({
              ...component,
              margins,
            })
          }
        >
          <Minus />
        </SpacedComponentForm>
        <div className="flex flex-col w-full pr-8">
          <div className="w-1/2 pt-5">
            <ColorPicker
              label="Color"
              value={component.style?.backgroundColor?.name}
              onChange={(backgroundColor) => {
                props.onChange({
                  ...component,
                  style: {
                    ...component.style,
                    backgroundColor: backgroundColor
                      ? CURRENT_THEME.colors[backgroundColor]
                      : undefined,
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
    </ComponentFormItem>
  );
}

export { DividerComponentForm };
