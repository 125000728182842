import { Image } from "react-feather";
import { Select } from "src/shared/components/Select";
import { TextInput } from "src/shared/components/TextInput";
import { ImageComponent } from "src/types/components/ImageComponent";
import { ComponentFormItem, ComponentFormProps } from "..";
import { SpacedComponentForm } from "./SpacedComponentForm";

function ImageComponentForm(props: ComponentFormProps) {
  const component = props.component as ImageComponent;

  return (
    <ComponentFormItem onDelete={props.onDelete}>
      <div className="flex space-x-4 w-full">
        <SpacedComponentForm
          component={component}
          onChange={(margins) =>
            props.onChange({
              ...component,
              margins,
            })
          }
        >
          <Image />
        </SpacedComponentForm>
        <div className="flex flex-col w-full pr-8">
          <div className="flex w-full mb-3">
            <div className="w-1/3">
              <TextInput
                label="Text"
                value={component.url}
                onChange={(url) => {
                  props.onChange({
                    ...component,
                    url,
                  });
                }}
              />
            </div>
            <div className="w-1/3 pl-4">
              <div className="flex w-full space-x-4">
                <div className="w-1/2">
                  <Select
                    label="Width"
                    value={component.width}
                    onChange={(width) => {
                      props.onChange({
                        ...component,
                        width: width === "100%" ? "100%" : parseInt(width),
                      });
                    }}
                    options={[
                      { label: "100%", value: "100%" },
                      { label: "4", value: 4 },
                      { label: "8", value: 8 },
                      { label: "12", value: 12 },
                      { label: "16", value: 16 },
                      { label: "20", value: 20 },
                      { label: "24", value: 24 },
                      { label: "28", value: 28 },
                      { label: "32", value: 32 },
                      { label: "36", value: 36 },
                      { label: "40", value: 40 },
                      { label: "44", value: 44 },
                      { label: "48", value: 48 },
                      { label: "64", value: 64 },
                      { label: "80", value: 80 },
                      { label: "96", value: 96 },
                      { label: "112", value: 112 },
                      { label: "128", value: 128 },
                      { label: "256", value: 256 },
                    ]}
                  />
                </div>
                <div className="w-1/2">
                  <Select
                    label="Height"
                    value={component.height}
                    onChange={(height) => {
                      props.onChange({
                        ...component,
                        height:
                          height === "100%"
                            ? "100%"
                            : height === "undefined"
                            ? undefined
                            : parseInt(height),
                      });
                    }}
                    options={[
                      { label: "Auto", value: "undefined" },
                      { label: "100%", value: "100%" },
                      { label: "4", value: 4 },
                      { label: "8", value: 8 },
                      { label: "12", value: 12 },
                      { label: "16", value: 16 },
                      { label: "20", value: 20 },
                      { label: "24", value: 24 },
                      { label: "28", value: 28 },
                      { label: "32", value: 32 },
                      { label: "36", value: 36 },
                      { label: "40", value: 40 },
                      { label: "44", value: 44 },
                      { label: "48", value: 48 },
                      { label: "64", value: 64 },
                      { label: "80", value: 80 },
                      { label: "96", value: 96 },
                      { label: "112", value: 112 },
                      { label: "128", value: 128 },
                      { label: "256", value: 256 },
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="w-1/3 pl-4">
              <Select
                label="Fill mode"
                value={component.fillMode}
                onChange={(fillMode) => {
                  props.onChange({
                    ...component,
                    fillMode,
                  });
                }}
                options={[
                  { label: "Fill", value: "cover" },
                  { label: "Contain", value: "contain" },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </ComponentFormItem>
  );
}

export { ImageComponentForm };
