import { getStyles } from "src/shared/helpers/styleConverter";
import { AppearanceType } from "src/types";
import { DividerComponent } from "src/types/components/DividerComponent";

function DividerComponentView({
  component,
  appearance,
}: {
  component: DividerComponent;
  appearance: AppearanceType;
}) {
  const appearanceKey = appearance === "dark" ? "dark" : "light";

  return (
    <div
      style={getStyles({ style: component.style, appearance: appearanceKey })}
      className="w-full"
    />
  );
}

export { DividerComponentView };
