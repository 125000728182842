import { useEffect } from "react";
import { WebViewScreen } from "src/types/screens/WebViewScreen";

function WebView({ screen }: { screen: WebViewScreen }) {
  const frameId = `webview_iframe_${screen.id}`;

  useEffect(() => {
    // TODO: remove this dirty hack
    setTimeout(() => {
      const iframe = document.getElementById(frameId) as any;
      if (iframe?.contentWindow?.fs_willAppear) {
        iframe?.contentWindow?.fs_willAppear();
      }
    }, 1000);
  }, [frameId]);

  return (
    <>
      {/* WebView content */}
      <iframe
        id={frameId}
        title={frameId}
        className="h-full w-full rounded-2xl"
        src={screen.url}
      />

      {/* To avoid clicks inside iframe */}
      <div className="absolute left-0 top-0 right-0 bottom-0"></div>
    </>
  );
}

export { WebView };
