import { getStyles } from "src/shared/helpers/styleConverter";
import { AppearanceType } from "src/types";
import { NumberInputComponent } from "src/types/components/NumberInputComponent";

function NumberInputComponentView({
  component,
  appearance,
}: {
  component: NumberInputComponent;
  appearance: AppearanceType;
}) {
  const defaultValue: number = (component.defaultValue as number) ?? 50;
  const appearanceKey = appearance === "dark" ? "dark" : "light";

  const styles = getStyles({
    style: component.style,
    appearance: appearanceKey,
  });

  let label = "";
  if (component.type === "weight") {
    label = "kg";
  }
  if (component.type === "height") {
    label = "cm";
  }

  const minLimit = component.limits?.min ?? -999999;
  const maxLimit = component.limits?.max ?? 999999;

  // TODO: Need to refactor
  return (
    <div
      style={{
        height: 308,
      }}
      className="flex w-full items-center justify-center"
    >
      {defaultValue - 4 > minLimit && (
        <div
          style={{
            transform: "scale(0.25)",
            marginTop: -280,
            color: styles.color,
            fontFamily: styles.fontFamily,
            fontSize: styles.fontSize,
            fontWeight: styles.fontWeight,
            lineHeight: styles.lineHeight,
            opacity: 0.2,
          }}
          className="absolute w-full text-center transform"
        >
          {defaultValue - 4}
        </div>
      )}

      {defaultValue - 3 > minLimit && (
        <div
          style={{
            transform: "scale(0.35)",
            marginTop: -235,
            color: styles.color,
            fontFamily: styles.fontFamily,
            fontSize: styles.fontSize,
            fontWeight: styles.fontWeight,
            lineHeight: styles.lineHeight,
            opacity: 0.3,
          }}
          className="absolute w-full text-center transform"
        >
          {defaultValue - 3}
        </div>
      )}

      {defaultValue - 2 > minLimit && (
        <div
          style={{
            marginTop: -180,
            color: styles.color,
            fontFamily: styles.fontFamily,
            fontSize: styles.fontSize,
            fontWeight: styles.fontWeight,
            lineHeight: styles.lineHeight,
            opacity: 0.3,
          }}
          className="absolute w-full text-center transform scale-50"
        >
          {defaultValue - 2}
        </div>
      )}

      {defaultValue - 1 > minLimit && (
        <div
          style={{
            marginTop: -110,
            color: styles.color,
            fontFamily: styles.fontFamily,
            fontSize: styles.fontSize,
            fontWeight: styles.fontWeight,
            lineHeight: styles.lineHeight,
            opacity: 0.5,
          }}
          className="absolute w-full text-center transform scale-75"
        >
          {defaultValue - 1}
        </div>
      )}

      <div
        style={{
          paddingTop: component.paddings?.top,
          paddingBottom: component.paddings?.bottom,
          paddingLeft: component.paddings?.left,
          paddingRight: component.paddings?.right,
          backgroundColor: styles.backgroundColor,
          borderRadius: styles.borderRadius,
        }}
        className="flex items-center justify-center w-full relative"
      >
        <p
          style={{
            color: styles.color,
            fontFamily: styles.fontFamily,
            fontSize: styles.fontSize,
            fontWeight: styles.fontWeight,
            lineHeight: styles.lineHeight,
          }}
        >
          {defaultValue}
        </p>
        <p
          style={{
            color: styles.color,
            marginLeft: 44,
            fontFamily: styles.fontFamily,
            fontSize: 16,
            lineHeight: 19,
          }}
          className="absolute left-1/2"
        >
          {label}
        </p>
      </div>

      {defaultValue + 4 < maxLimit && (
        <div
          style={{
            transform: "scale(0.25)",
            marginTop: 280,
            color: styles.color,
            fontFamily: styles.fontFamily,
            fontSize: styles.fontSize,
            fontWeight: styles.fontWeight,
            lineHeight: styles.lineHeight,
            opacity: 0.2,
          }}
          className="absolute w-full text-center transform"
        >
          {defaultValue + 4}
        </div>
      )}

      {defaultValue + 3 < maxLimit && (
        <div
          style={{
            transform: "scale(0.35)",
            marginTop: 235,
            color: styles.color,
            fontFamily: styles.fontFamily,
            fontSize: styles.fontSize,
            fontWeight: styles.fontWeight,
            lineHeight: styles.lineHeight,
            opacity: 0.3,
          }}
          className="absolute w-full text-center transform"
        >
          {defaultValue + 3}
        </div>
      )}

      {defaultValue + 2 < maxLimit && (
        <div
          style={{
            marginTop: 180,
            color: styles.color,
            fontFamily: styles.fontFamily,
            fontSize: styles.fontSize,
            fontWeight: styles.fontWeight,
            lineHeight: styles.lineHeight,
            opacity: 0.3,
          }}
          className="absolute w-full text-center transform scale-50"
        >
          {defaultValue + 2}
        </div>
      )}

      {defaultValue + 1 < maxLimit && (
        <div
          style={{
            marginTop: 110,
            color: styles.color,
            fontFamily: styles.fontFamily,
            fontSize: styles.fontSize,
            fontWeight: styles.fontWeight,
            lineHeight: styles.lineHeight,
            opacity: 0.5,
          }}
          className="absolute w-full text-center transform scale-75"
        >
          {defaultValue + 1}
        </div>
      )}
    </div>
  );
}

export { NumberInputComponentView };
