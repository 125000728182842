import { ActionScreen } from "./ActionsScreen";
import { AuthScreen } from "./AuthScreen";
import { ModalScreen } from "./ModalScreen";
import { PageScreen } from "./PageScreen";
import { PaywallScreen } from "./PaywallScreen";
import { WebViewScreen } from "./WebViewScreen";

export const CONDITIONS = [
  "==",
  "!=",
  ">",
  ">=",
  "<",
  "<=",
  "array-contains",
  "array-not-contains",
  "array-contains-any",
  "array-not-contains-any",
  "in",
  "not-in",
  "is-empty",
  "is-not-empty",
] as const;
export const VAR_TYPES = [
  "string",
  "number",
  "boolean",
  "date",
  "array",
  // "object", // TODO: add support for object type
] as const;

export type NavigationParams = {
  canGoBack: boolean;
  shouldBeSkippedWhenGoingBack: boolean;
  conditionsForShow: {
    variableName: string;
    variableType: (typeof VAR_TYPES)[number];
    condition: (typeof CONDITIONS)[number];
    value: any;
  }[];
};

export type BaseScreen = {
  id: string;
  name: string;
};

export type NavigatedScreen = {
  navigationParams: NavigationParams;
};

export type ScreenGroup = {
  title: string;
  icon?: {
    light: string;
    dark: string;
  };
};

export type GroupedScreen = {
  groupParams?: {
    hideGroup?: boolean;
    endOfGroup?: boolean;
    startGroup?: ScreenGroup;
  };
};

export type OnboardingScreen =
  | PageScreen
  | ModalScreen
  | ActionScreen
  | WebViewScreen
  | PaywallScreen
  | AuthScreen;
