type DateInputProps = {
  value: string;
  label: string;
  onChange: (newValue: Date | undefined) => void;
  className?: string;
};

function DateInput({ value, onChange, label, className }: DateInputProps) {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <input
        value={value}
        type="date"
        onChange={(e) =>
          e.target.value
            ? onChange(new Date(e.target.value))
            : onChange(undefined)
        }
        className={`${className} mt-1 block w-full rounded-md border-gray-300 py-2 px-3 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
      />
    </div>
  );
}

export { DateInput };
