import { List, Trash2 } from "react-feather";
import { AddButton } from "src/shared/components/AddButton";
import { Select } from "src/shared/components/Select";
import { TextInput } from "src/shared/components/TextInput";
import { ACTIONS, BundledIcon, ICONS } from "src/types";
import {
  ListInputComponent,
  ListInputOption,
} from "src/types/components/ListInputComponent";
import { ComponentFormItem, ComponentFormProps } from "..";

import { BindedDataInput } from "./BindedDataInput";
import { SpacedComponentForm } from "./SpacedComponentForm";
import { Checkbox } from "src/shared/components/Checkbox";

function ListInputComponentForm(props: ComponentFormProps) {
  const component = props.component as ListInputComponent;

  const addOption = () => {
    component.options.push({
      label: `Label ${component.options.length + 1}`,
      value: `value_${component.options.length + 1}`,
    });
    props.onChange({
      ...component,
    });
  };
  const deleteOption = (index: number) => {
    props.onChange({
      ...component,
      options: component.options.filter((el, i) => i !== index),
    });
  };
  const updateOption = (index: number, newVal: ListInputOption) => {
    component.options[index] = newVal;
    props.onChange({
      ...component,
    });
  };

  return (
    <ComponentFormItem onDelete={props.onDelete}>
      <div className="flex space-x-4 w-full pr-8">
        <SpacedComponentForm
          component={component}
          onChange={(margins) =>
            props.onChange({
              ...component,
              margins,
            })
          }
        >
          <List className="flex-shrink-0" />
        </SpacedComponentForm>

        <div className="flex flex-col w-full">
          <div className="flex w-full">
            <div className="w-1/2">
              <Select
                label="Type"
                value={component.type}
                onChange={(value) => {
                  props.onChange({
                    ...component,
                    type: value as ListInputComponent["type"],
                  });
                }}
                options={[
                  {
                    label: "Single selection",
                    value: "single",
                  },
                  {
                    label: "Multiple selection",
                    value: "multiple",
                  },
                ]}
              />
            </div>
            <div className="w-1/2 pl-4">
              <Select
                label="Action"
                value={component.action}
                onChange={(value) => {
                  props.onChange({
                    ...component,
                    action: value as ListInputComponent["action"],
                  });
                }}
                options={[
                  {
                    label: "None",
                    value: "undefined",
                  },
                  ...ACTIONS.map((action) => ({
                    label: action,
                    value: action,
                  })),
                ]}
              />
            </div>
          </div>
          <div className="w-full mt-4">
            <BindedDataInput
              component={{
                variableName: component.variableName,
                required: component.required,
              }}
              onChange={(updates) =>
                props.onChange({ ...component, ...updates })
              }
            />
          </div>

          <hr className="my-4" />
          {component.options.map((option, index) => (
            <div
              key={`option_${index}`}
              className="flex flex-col bg-slate-100 space-y-2 mb-2 rounded-lg pl-4 pb-4 pt-2"
            >
              <div className="flex flex-row space-x-2 relative pr-8">
                <div className="w-full flex flex-col space-y-2">
                  <div className="min-w-32 flex-shrink-0">
                    <Select
                      label="Icon"
                      value={(option.icon as BundledIcon)?.name ?? ""}
                      onChange={(icon) =>
                        updateOption(index, {
                          ...option,
                          icon: icon
                            ? {
                                type: "bundled",
                                name: icon,
                              }
                            : undefined,
                        })
                      }
                      options={[
                        { label: "no icon", value: "undefined" },
                        ...ICONS.map((icon) => ({
                          label: icon,
                          value: icon,
                        })),
                      ]}
                    />
                  </div>

                  <TextInput
                    label="Value"
                    value={option.value ?? ""}
                    onChange={(value) =>
                      updateOption(index, {
                        ...option,
                        value,
                      })
                    }
                  />
                </div>
                <div className="w-full flex flex-col space-y-2 mb-1">
                  <TextInput
                    label="Label"
                    value={option.label ?? ""}
                    onChange={(label) =>
                      updateOption(index, {
                        ...option,
                        label,
                      })
                    }
                  />
                  <TextInput
                    label="Subtitle"
                    value={option.subtitle ?? ""}
                    onChange={(subtitle) =>
                      updateOption(index, {
                        ...option,
                        subtitle,
                      })
                    }
                  />
                </div>

                <button
                  onClick={() => deleteOption(index)}
                  className="-mt-1 w-8 h-8 absolute right-0 top-0 flex items-center justify-center hover:scale-105"
                >
                  <Trash2 size={16} className="text-gray-500" />
                </button>
              </div>

              <Checkbox
                label="Should unselect others options"
                checked={!!option.shouldUnselectOthers}
                onChange={(value) => {
                  updateOption(index, {
                    ...option,
                    shouldUnselectOthers: value,
                  });
                }}
              />
            </div>
          ))}
          <AddButton
            label="Add option"
            className="mr-auto"
            onClick={() => addOption()}
          />
        </div>
      </div>
    </ComponentFormItem>
  );
}

export { ListInputComponentForm };
