import { SpacedComponent, StyledComponent } from ".";

export const TEXT_TYPES = [
  "title1",
  "title2",
  "question",
  "body",
  "answers",
  "answersOn",
  "small",
] as const;

export type TextType = typeof TEXT_TYPES[number];

export type TextComponent = {
  id: "text";
  text: string;
  type: TextType;
  containsMarkdown: boolean;
} & SpacedComponent &
  StyledComponent;
