import { getStyles } from "src/shared/helpers/styleConverter";
import { AppearanceType } from "src/types";
import { ButtonComponent } from "src/types/components/ButtonComponent";

function ButtonComponentView({
  component,
  appearance,
}: {
  component: ButtonComponent;
  appearance: AppearanceType;
}) {
  const appearanceKey = appearance === "dark" ? "dark" : "light";

  return (
    <div
      style={getStyles({ style: component.style, appearance: appearanceKey })}
      className="text-center w-full flex items-center justify-center"
    >
      {component.text}
    </div>
  );
}

export { ButtonComponentView };
