import { AuthScreen } from "src/types/screens/AuthScreen";

function AuthView({ screen }: { screen: AuthScreen }) {
  return (
    <div className="fixed w-full h-full left-0 top-0 flex flex-col items-center justify-center px-4">
      <h1
        style={{
          fontFamily: "Montserrat",
          fontWeight: 700,
          fontSize: "18px",
          lineHeight: "22px",
        }}
        className="text-center mx-2 mb-6"
      >
        {screen.title}
      </h1>
      <p
        style={{
          fontFamily: "SF Pro Text",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "19px",
        }}
        className="text-center mx-2 mb-4"
      >
        {screen.description}
      </p>

      <div className="flex flex-col space-y-3 w-full mt-20">
        {screen.authTypes.map((type) => (
          <div
            key={type}
            style={{
              fontFamily: "SF Pro Text",
              fontWeight: 500,
              fontSize: "22px",
              lineHeight: "27px",
              height: 52,
              backgroundColor: type === "apple" ? "black" : "transparent",
              color: type === "apple" ? "white" : "black",
            }}
            className="rounded-xl border w-full text-center flex items-center justify-center tracking-tight"
          >
            Continue with <span className="capitalize">{type}</span>
          </div>
        ))}
      </div>

      <p
        style={{
          fontFamily: "SF Pro Text",
          fontWeight: 400,
          fontSize: "13px",
          lineHeight: "16px",
          color: "#6A6D82",
        }}
        className="text-center mx-2 mt-4"
      >
        {screen.privacyText}
      </p>
    </div>
  );
}

export { AuthView };
