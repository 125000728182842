import { SCREEN_SIZE } from "src/config";
import { X } from "react-feather";
import { OnboardingScreen } from "src/types/screens";
import { TextInput } from "src/shared/components/TextInput";
import { PaywallScreen } from "src/types/screens/PaywallScreen";
import { ScreenNavParamsForm } from "../ScreenNavParamsForm";

function PaywallForm({
  screen,
  onClose,
  onChange,
}: {
  screen: PaywallScreen | null;
  onClose: () => void;
  onChange: (screen: OnboardingScreen) => void;
}) {
  return (
    <div
      className={`${
        !!screen
          ? "opacity-100 delay-300 translate-x-0"
          : "opacity-0 -translate-x-8 pointer-events-none"
      } transition duration-300 relative container mx-auto px-6`}
    >
      <div className="flex w-full">
        <div
          style={{
            width: SCREEN_SIZE.width,
            height: SCREEN_SIZE.height,
          }}
          className="flex-shrink-0 mr-12"
        />
        <div className="bg-white w-full shadow-xl rounded-xl flex flex-col p-8 relative">
          <button
            onClick={() => onClose()}
            className="absolute w-12 h-12 right-4 top-4 flex items-center justify-center hover:scale-105"
          >
            <X size={24} className="text-gray-500" />
          </button>
          <h2 className="text-base font-semibold mb-4">ID {screen?.id}</h2>
          <hr className="mb-4" />

          <div className="flex mb-4">
            <div className="w-1/2">
              <TextInput
                label="Paywall Screen Name"
                value={screen?.name ?? ""}
                onChange={(name) => {
                  if (!screen) return;
                  onChange({
                    ...screen,
                    name,
                  });
                }}
              />
            </div>
            <div className="w-1/2 pl-2">
              <p className="text-sm text-gray-500 mt-8">
                — using for Analytics (e.g. "{screen?.name ?? ""}Done")
              </p>
            </div>
          </div>
          <div className="flex mb-8">
            <div className="w-1/2">
              <TextInput
                label="Paywall URL"
                value={screen?.url ?? ""}
                onChange={(url) => {
                  if (!screen) return;
                  onChange({
                    ...screen,
                    url,
                  });
                }}
              />
            </div>
            <div className="w-1/2 pl-4 flex space-x-2 pt-6">
              <button
                onClick={() => {
                  if (!screen) return;
                  onChange({
                    ...screen,
                    url: "https://paywall.simple-life-app.com/#/paywall",
                  });
                }}
                className="text-xs rounded-full hover:bg-gray-200 px-4 py-1 bg-gray-100"
              >
                Set Default
              </button>
              <button
                onClick={() => {
                  if (!screen) return;
                  onChange({
                    ...screen,
                    url: "https://paywall.simple-life-app.com/#/workbooks",
                  });
                }}
                className="text-xs rounded-full hover:bg-gray-200 px-4 py-1 bg-gray-100"
              >
                Set Workbooks
              </button>
            </div>
          </div>

          <ScreenNavParamsForm
            screen={screen}
            onChange={(navigationParams) => {
              if (!screen) return;
              onChange({
                ...screen,
                navigationParams,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
}

export { PaywallForm };
