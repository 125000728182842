import { ScreenGroupsInfo } from "src/screens/App";

import { AppearanceType } from "src/types";
import { CURRENT_THEME } from "src/shared/themes";

function ScreenGroupHeader({
  groupsInfo,
  appearance,
}: {
  groupsInfo: ScreenGroupsInfo;
  appearance: AppearanceType;
}) {
  return (
    <div className="flex flex-col px-4 pt-8">
      <div className="flex items-center mb-4">
        {!!groupsInfo.group.icon && (
          <img
            src={groupsInfo.group.icon[appearance]}
            alt="screen group icon"
            className="mr-2 w-6 h-6"
          />
        )}
        <p
          style={{
            fontSize: 13,
            lineHeight: "15.85px",
            fontFamily: "Montserrat",
            fontWeight: 700,
            color: CURRENT_THEME.colors.textPrimary[appearance],
          }}
          className="uppercase"
        >
          {groupsInfo.group.title}
        </p>
      </div>
      {/* Progress bar */}
      <div className="flex w-full h-1 space-x-2 mb-5">
        {Array(groupsInfo.groupsCount)
          .fill(null)
          .map((_, index) => {
            const isCurrentGroup = index === groupsInfo.groupIndex;
            return (
              <div
                key={index}
                style={{
                  backgroundColor:
                    CURRENT_THEME.colors.fillProgressBar[appearance],
                }}
                className="relative w-full h-full rounded-full overflow-hidden"
              >
                {isCurrentGroup && (
                  <div
                    style={{
                      width: `${
                        (groupsInfo.currentGroupStep /
                          groupsInfo.currentGroupStepsCount) *
                        100
                      }%`,
                      backgroundColor:
                        CURRENT_THEME.colors.fillAccentGreen[appearance],
                    }}
                    className="absolute top-0 left-0 h-full rounded-full"
                  />
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export { ScreenGroupHeader };
