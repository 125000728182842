import { ComponentFormItem, ComponentFormProps } from "..";

function SpacerComponentForm(props: ComponentFormProps) {
  return (
    <ComponentFormItem onDelete={props.onDelete}>
      <div className="flex space-x-4 w-full">
        <div className="flex flex-col w-full pr-8">
          <p className="block text-sm font-medium text-gray-700">Spacer</p>
        </div>
      </div>
    </ComponentFormItem>
  );
}

export { SpacerComponentForm };
