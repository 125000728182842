import { CURRENT_THEME } from "src/shared/themes";
import { ButtonComponent } from "src/types/components/ButtonComponent";
import { PageScreen } from "src/types/screens/PageScreen";
import { ButtonComponentForm } from "../../../../ComponentForm/ui/ButtonComponentForm";

function PageScreenBottomBlockForm({
  screen,
  onChange,
}: {
  screen: PageScreen | null;
  onChange: (appearance: PageScreen["bottomBlock"]) => void;
}) {
  const createBottomBlock = () => {
    onChange({
      submitButton: {
        id: "button",
        type: "main",
        text: "Next",
        action: "next",
        style: {
          ...CURRENT_THEME.components.button.main,
        },
      },
    });
  };

  if (!screen) return null;

  if (!screen.bottomBlock?.submitButton) {
    // Hide by default
    return (
      <div className="bg-slate-100 p-2 flex flex-col w-full rounded-b-xl">
        <button onClick={() => createBottomBlock()}>
          <p className="text-xs font-semibold uppercase tracking-wider p-2">
            Add Bottom Block +
          </p>
        </button>
      </div>
    );
  }

  return (
    <div className="bg-slate-100 p-6 flex flex-col w-full rounded-b-xl space-y-4">
      <p className="text-xs font-semibold uppercase tracking-wider">
        Bottom Block
      </p>
      <div className="flex flex-col">
        <ButtonComponentForm
          component={screen.bottomBlock.submitButton}
          onChange={(component) => {
            onChange({
              ...screen.bottomBlock,
              submitButton: component as ButtonComponent,
            });
          }}
          onDelete={() => onChange(undefined)}
        />
      </div>
    </div>
  );
}

export { PageScreenBottomBlockForm };
