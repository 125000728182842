import { OnboardingScreen } from "src/types/screens";

// Welcome content
// Webview links
// Telegraph links

export const EXAMPLE_SCREENS: OnboardingScreen[] = [
  {
    id: "2GdzuBWcsyePu_4VlVQ-l",
    type: "page",
    name: "OnboardingWelcomeScreen",
    components: [
      {
        id: "image",
        url: "https://onboarding-builder.pages.dev/assets/image.png",
        width: "100%",
        fillMode: "cover",
        aspectRatio: 1.322,
      },
      {
        id: "text",
        type: "title2",
        text: "Start your journey",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 22,
          fontWeight: 700,
          lineHeight: 26.8,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "center",
        },
        margins: {
          left: 16,
          right: 16,
          top: 24,
        },
      },
      {
        id: "text",
        type: "body",
        text: "Build better nutrition habits to achieve your health and wellness goals",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "center",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "spacer",
      },
      {
        id: "checkbox",
        label:
          "I agree to submit my health data, to be treated in accordance with Simple’s [Privacy Policy](https://simple.life/privacy.htm)",
        containsMarkdown: true,
        style: {
          cornerRadius: 6,
          backgroundColor: {
            name: "fillAccent",
            light: "#7D8BF7",
            dark: "#8795FC",
          },
          borderColor: {
            name: "textSecondary",
            light: "#6A6D82",
            dark: "#8E90A1",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 13,
          fontWeight: 400,
          lineHeight: 15.5,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
        },
        variableName: "privacyPolicy",
        required: true,
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
      },
      {
        id: "button",
        type: "main",
        text: "Let’s go",
        action: "next",
        style: {
          height: 56,
          textColor: {
            name: "textInverse",
            light: "#ffffff",
            dark: "#323653",
          },
          backgroundColor: {
            name: "fillMain",
            light: "#323653",
            dark: "#FFFFFF",
          },
          cornerRadius: 12,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
        margins: {
          left: 16,
          right: 16,
          top: 16,
        },
      },
      {
        id: "button",
        type: "secondary",
        text: "Already Simple user? Sign in →",
        action: "signIn",
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 14,
          fontWeight: 700,
          lineHeight: 17,
          textAlign: "center",
          textColor: {
            name: "textSecondary",
            light: "#6A6D82",
            dark: "#8E90A1",
          },
        },
        margins: {
          left: 16,
          right: 16,
          top: 12,
        },
      },
    ],
    navigationParams: {
      canGoBack: false,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
      statusBarStyle: "light",
      ignoreSafeArea: true,
    },
  },
  {
    id: "IMNxkYNwbi04fuWaR9sTc",
    type: "auth",
    name: "OnboardingAuthorizationScreen",
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: true,
      conditionsForShow: [
        {
          variableName: "isAuthorized",
          variableType: "boolean",
          condition: "!=",
          value: true,
        },
      ],
    },
    title:
      "Jumpstart your wellness and nutrition journey with Simple, trusted by 13+ million members",
    description: "Sign in or create an account to save your progress:",
    authTypes: ["apple", "facebook", "google", "email"],
    privacyText:
      "By creating an account, you agree to Simple’s [Terms of Use](https://simple.life/tos.htm) and [Privacy Policy](https://simple.life/privacy.htm)",
  },
  {
    id: "vMt4T_2E41Uyp1iXt2Zes",
    type: "page",
    name: "OnboardingNameScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "What’s your name?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
      },
      {
        id: "textInput",
        type: "text",
        style: {
          borderColor: {
            name: "borderDivider",
            light: "#E2E3EE",
            dark: "#353745",
          },
          borderWidth: 1,
          fontFamily: "Montserrat",
          fontSize: 32,
          fontWeight: 300,
          lineHeight: 39,
          letterSpacing: -0.4,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
        },
        paddings: {
          bottom: 12,
        },
        variableName: "name",
        required: true,
        analytics: {
          analyticsKey: "name",
        },
        margins: {
          left: 16,
          right: 16,
          top: 54,
        },
      },
    ],
    navigationParams: {
      canGoBack: false,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
    bottomBlock: {
      submitButton: {
        id: "button",
        type: "main",
        text: "Let’s go",
        action: "next",
        hasInactiveState: true,
        style: {
          height: 56,
          textColor: {
            name: "textInverse",
            light: "#ffffff",
            dark: "#323653",
          },
          backgroundColor: {
            name: "fillMain",
            light: "#323653",
            dark: "#FFFFFF",
          },
          cornerRadius: 12,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
      },
    },
  },
  {
    id: "gySxygLlSENbTLzMia3L5",
    type: "webview",
    name: "OnboardingHelloScreen",
    url: "https://onboarding-builder.pages.dev/webviews/hello.html",
    navigationParams: {
      canGoBack: false,
      shouldBeSkippedWhenGoingBack: true,
      conditionsForShow: [],
    },
    appearance: {
      ignoreSafeArea: true,
      statusBarStyle: "light",
      backgroundColor: {
        name: "custom",
        light: "#282A3A",
        dark: "#282A3A",
      },
    },
  },
  {
    id: "qJi-VgliATPgGqJGN96_5",
    type: "page",
    name: "OnboardingGoalsScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "Which one goal matters the most to you?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "I want to lose weight ",
            value: "lose_weight",
            icon: {
              type: "bundled",
              name: "weight",
            },
          },
          {
            label: "I want to get healthier",
            value: "healthy",
            icon: {
              type: "bundled",
              name: "health",
            },
          },
          {
            label: "I want to practice mindful eating",
            value: "mindful_about_eat",
            icon: {
              type: "bundled",
              name: "food",
            },
          },
          {
            label: "I want to stay in shape",
            value: "fit",
            icon: {
              type: "bundled",
              name: "brain",
            },
          },
        ],
        variableName: "goal",
        required: true,
        analytics: {
          analyticsKey: "data",
          userPropertyKey: "Goals",
        },
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
        action: "next",
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "1CpN_FZUVwiGgCUVCkZcC",
    type: "page",
    name: "OnboardingSecondaryGoalsScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "Select as many additional goals as you want",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
      },
      {
        id: "listInput",
        type: "multiple",
        options: [
          {
            label: "Boost my immune system",
            value: "immune",
          },
          {
            label: "Reduce stress and anxiety",
            value: "stress",
          },
          {
            label: "Revitalize my energy",
            value: "energy",
          },
          {
            label: "Sleep better",
            value: "sleep",
          },
          {
            label: "Be more confident",
            value: "confident",
          },
          {
            label: "Focus",
            value: "think",
          },
          {
            label: "Live longer",
            value: "live_longer",
          },
          {
            label: "None of the above",
            value: "none",
            shouldUnselectOthers: true,
          },
        ],
        variableName: "secondaryGoals",
        required: true,
        analytics: {
          analyticsKey: "data",
          userPropertyKey: "Secondary Goals",
        },
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
    bottomBlock: {
      submitButton: {
        id: "button",
        type: "main",
        text: "Next",
        action: "next",
        style: {
          height: 56,
          textColor: {
            name: "textInverse",
            light: "#ffffff",
            dark: "#323653",
          },
          backgroundColor: {
            name: "fillMain",
            light: "#323653",
            dark: "#FFFFFF",
          },
          cornerRadius: 12,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
      },
    },
  },
  {
    id: "WjmveylD0vqiJzYN-GBgS",
    type: "page",
    name: "OnboardingFastingReasonsScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "Why do you want to try intermittent fasting?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
      },
      {
        id: "listInput",
        type: "multiple",
        options: [
          {
            label: "I already tried fasting I liked it",
            value: "already_practiced",
          },
          {
            label: "Someone recommended it",
            value: "friends",
          },
          {
            label: "I believe it works!",
            value: "believe_works",
          },
          {
            label: "I want to try something new",
            value: "something_new",
          },
          {
            label: "Religious reasons",
            value: "religious",
          },
          {
            label: "No particular reason",
            value: "none",
            shouldUnselectOthers: true,
          },
        ],
        variableName: "whyFasting",
        required: true,
        analytics: {
          analyticsKey: "data",
          userPropertyKey: "Fasting reasons",
        },
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
    bottomBlock: {
      submitButton: {
        id: "button",
        type: "main",
        text: "Next",
        action: "next",
        style: {
          height: 56,
          textColor: {
            name: "textInverse",
            light: "#ffffff",
            dark: "#323653",
          },
          backgroundColor: {
            name: "fillMain",
            light: "#323653",
            dark: "#FFFFFF",
          },
          cornerRadius: 12,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
      },
    },
  },
  {
    id: "V7W0WTHY4VGW9niMn2HY8",
    type: "page",
    name: "OnboardingGenderScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "What sex were you assigned at birth?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "text",
        type: "body",
        text: "Biological sex can affect many physical elements, like our metabolism, muscle mass, or hormones, so we use it in our calculations.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 16,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Male",
            value: "male",
          },
          {
            label: "Female",
            value: "female",
          },
        ],
        variableName: "gender",
        required: true,
        analytics: {
          analyticsKey: "data",
          userPropertyKey: "Gender",
        },
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
        action: "next",
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
    groupParams: {
      startGroup: {
        title: "BASIC DATA",
        icon: {
          light:
            "https://onboarding-builder.pages.dev/assets/screen-groups/weight.png",
          dark: "https://onboarding-builder.pages.dev/assets/screen-groups/weight-dark.png",
        },
      },
    },
  },
  {
    id: "hYqz5u38cC7aoTJF4M4Fn",
    type: "page",
    name: "OnboardingBirthdayScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "When were you born?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "text",
        type: "body",
        text: "We ask this to establish if Simple is safe for you",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 16,
        },
      },
      {
        id: "spacer",
      },
      {
        id: "dateInput",
        type: "date",
        style: {
          borderColor: {
            name: "borderDivider",
            light: "#E2E3EE",
            dark: "#353745",
          },
          borderWidth: 1,
          fontFamily: "Montserrat",
          fontSize: 40,
          fontWeight: 600,
          lineHeight: 48,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "center",
        },
        paddings: {
          bottom: 12,
        },
        variableName: "birthday",
        required: true,
        analytics: {
          analyticsKey: "date",
        },
        margins: {
          left: 16,
          right: 16,
          top: 0,
        },
        defaultValue: "todayMinus20years",
        limits: {
          max: "today",
        },
      },
      {
        id: "spacer",
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
    bottomBlock: {
      submitButton: {
        id: "button",
        type: "main",
        text: "Next",
        action: "next",
        style: {
          height: 56,
          textColor: {
            name: "textInverse",
            light: "#ffffff",
            dark: "#323653",
          },
          backgroundColor: {
            name: "fillMain",
            light: "#323653",
            dark: "#FFFFFF",
          },
          cornerRadius: 12,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
      },
    },
  },
  {
    id: "qfcAWRV9-8IwI5Ei3Js4N",
    type: "modal",
    name: "OnboardingBlockAgeModalScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "We don’t recommend intermittent fasting for you right now",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "center",
        },
        margins: {
          left: 16,
          right: 16,
          top: 40,
        },
      },
      {
        id: "text",
        type: "body",
        text: "We want to keep you healthy and safe, so we don’t advise fasting for anyone under 18.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "center",
        },
        margins: {
          left: 16,
          right: 16,
          top: 16,
        },
      },
      {
        id: "text",
        type: "body",
        text: "Sign up for our emails to stay updated on new options that will work better for you. We'll be here when you're ready!",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "center",
        },
        margins: {
          left: 16,
          right: 16,
          top: 16,
        },
      },
      {
        id: "divider",
        style: {
          height: 1,
          backgroundColor: {
            name: "borderDivider",
            light: "#E2E3EE",
            dark: "#353745",
          },
        },
        margins: {
          top: 40,
        },
      },
      {
        id: "button",
        type: "modalMain",
        text: "Edit my answer",
        action: "back",
        style: {
          height: 56,
          textColor: {
            name: "textAccent",
            light: "#7D8BF7",
            dark: "#8795FC",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
      {
        id: "divider",
        style: {
          height: 1,
          backgroundColor: {
            name: "borderDivider",
            light: "#E2E3EE",
            dark: "#353745",
          },
        },
      },
      {
        id: "button",
        type: "modalSecondary",
        text: "I’ll sign up later",
        action: "getEmail",
        style: {
          height: 56,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: true,
      conditionsForShow: [
        {
          variableName: "age",
          variableType: "number",
          condition: "<",
          value: 18,
        },
      ],
    },
    appearance: {
      style: {
        cornerRadius: 16,
        backgroundColor: {
          name: "bgContent",
          light: "#ffffff",
          dark: "#252736",
        },
      },
      statusBarStyle: "light",
      overlayColor: {
        name: "bgOverlay",
        light: "#00000080",
        dark: "#00000080",
      },
    },
  },
  {
    id: "DfRdtmRRHEYbnXijtUsVh",
    type: "page",
    name: "OnboardingMeasurementSystemScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "Measurement system",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Lbs and Ft",
            value: "imperial",
          },
          {
            label: "Kg and Cm",
            value: "metric",
          },
        ],
        variableName: "measurement_system",
        required: true,
        analytics: {
          analyticsKey: "data",
          userPropertyKey: "Measurement system",
        },
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
        action: "next",
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "-S39HedypYSu2iTcWZ7W6",
    type: "page",
    name: "OnboardingHeightScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "What is your height?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "spacer",
      },
      {
        id: "numberInput",
        type: "height",
        style: {
          backgroundColor: {
            name: "fillPicker",
            light: "#E9EAF2",
            dark: "#2F3142",
          },
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 12,
          fontFamily: "SF Pro Text",
          fontWeight: 600,
          lineHeight: 48,
          fontSize: 40,
        },
        paddings: {
          top: 8,
          bottom: 8,
        },
        variableName: "height",
        required: true,
        analytics: {
          analyticsKey: "data",
        },
        margins: {
          left: 16,
          right: 16,
          top: 0,
        },
        limits: {
          min: 50,
          max: 250,
        },
        defaultValue: 170,
      },
      {
        id: "spacer",
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
    bottomBlock: {
      submitButton: {
        id: "button",
        type: "main",
        text: "Next",
        action: "next",
        style: {
          height: 56,
          textColor: {
            name: "textInverse",
            light: "#ffffff",
            dark: "#323653",
          },
          backgroundColor: {
            name: "fillMain",
            light: "#323653",
            dark: "#FFFFFF",
          },
          cornerRadius: 12,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
      },
    },
  },
  {
    id: "_o_4v6iefe4Fqxi5nuJgh",
    type: "page",
    name: "OnboardingWeightScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "What is your weight?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "text",
        type: "body",
        text: "Weight can feel personal — we only ask so we can build the right solution for you.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 16,
        },
      },
      {
        id: "spacer",
      },
      {
        id: "numberInput",
        type: "weight",
        style: {
          backgroundColor: {
            name: "fillPicker",
            light: "#E9EAF2",
            dark: "#2F3142",
          },
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 12,
          fontFamily: "SF Pro Text",
          fontWeight: 600,
          lineHeight: 48,
          fontSize: 40,
        },
        paddings: {
          top: 8,
          bottom: 8,
        },
        variableName: "weight",
        required: true,
        analytics: {
          analyticsKey: "data",
        },
        margins: {
          left: 16,
          right: 16,
          top: 0,
        },
        defaultValue: 80,
        limits: {
          min: 20,
          max: 300,
        },
      },
      {
        id: "spacer",
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
    bottomBlock: {
      submitButton: {
        id: "button",
        type: "main",
        text: "Next",
        action: "next",
        style: {
          height: 56,
          textColor: {
            name: "textInverse",
            light: "#ffffff",
            dark: "#323653",
          },
          backgroundColor: {
            name: "fillMain",
            light: "#323653",
            dark: "#FFFFFF",
          },
          cornerRadius: 12,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
      },
    },
  },
  {
    id: "SFgnpKS8NXeFM3tzfuQdh",
    type: "modal",
    name: "OnboardingBMIBlockerModalScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "We don’t recommend intermittent fasting for you right now",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "center",
        },
        margins: {
          left: 16,
          right: 16,
          top: 40,
        },
      },
      {
        id: "text",
        type: "body",
        text: "Given your current height-to-weight ratio, intermittent fasting may not be a good fit for you.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "center",
        },
        margins: {
          left: 16,
          right: 16,
          top: 16,
        },
      },
      {
        id: "text",
        type: "body",
        text: "Sign up for our emails so we can let you know when we have other options that work better for you!",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "center",
        },
        margins: {
          left: 16,
          right: 16,
          top: 16,
        },
      },
      {
        id: "divider",
        style: {
          height: 1,
          backgroundColor: {
            name: "borderDivider",
            light: "#E2E3EE",
            dark: "#353745",
          },
        },
        margins: {
          top: 40,
        },
      },
      {
        id: "button",
        type: "modalMain",
        text: "Edit my answer",
        action: "back",
        style: {
          height: 56,
          textColor: {
            name: "textAccent",
            light: "#7D8BF7",
            dark: "#8795FC",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
      {
        id: "divider",
        style: {
          height: 1,
          backgroundColor: {
            name: "borderDivider",
            light: "#E2E3EE",
            dark: "#353745",
          },
        },
      },
      {
        id: "button",
        type: "modalSecondary",
        text: "I’ll sign up later",
        action: "getEmail",
        style: {
          height: 56,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: true,
      conditionsForShow: [
        {
          variableName: "bodyMassIndex",
          variableType: "number",
          condition: "<",
          value: 18.5,
        },
      ],
    },
    appearance: {
      style: {
        cornerRadius: 16,
        backgroundColor: {
          name: "bgContent",
          light: "#ffffff",
          dark: "#252736",
        },
      },
      statusBarStyle: "light",
      overlayColor: {
        name: "bgOverlay",
        light: "#00000080",
        dark: "#00000080",
      },
    },
  },
  {
    id: "GIsYmP3Gyw9XRQxzpQSpJ",
    type: "page",
    name: "OnboardingWeightGoalScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "Your target weight",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "spacer",
      },
      {
        id: "numberInput",
        type: "weight",
        style: {
          backgroundColor: {
            name: "fillPicker",
            light: "#E9EAF2",
            dark: "#2F3142",
          },
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 12,
          fontFamily: "SF Pro Text",
          fontWeight: 600,
          lineHeight: 48,
          fontSize: 40,
        },
        paddings: {
          top: 8,
          bottom: 8,
        },
        variableName: "weightGoal",
        required: true,
        analytics: {
          analyticsKey: "data",
        },
        margins: {
          left: 16,
          right: 16,
          top: 0,
        },
        defaultValue: "weight72OrInitialIfBigger",
        limits: {
          min: 20,
          max: 250,
        },
      },
      {
        id: "spacer",
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
    bottomBlock: {
      submitButton: {
        id: "button",
        type: "main",
        text: "Next",
        action: "next",
        style: {
          height: 56,
          textColor: {
            name: "textInverse",
            light: "#ffffff",
            dark: "#323653",
          },
          backgroundColor: {
            name: "fillMain",
            light: "#323653",
            dark: "#FFFFFF",
          },
          cornerRadius: 12,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
      },
    },
  },
  {
    id: "L420Z57_ys11qMm7CgI62",
    type: "modal",
    name: "OnboardingBlockerGainingWeightModalScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "Looks like you want to gain weight using Simple",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "center",
        },
        margins: {
          left: 16,
          right: 16,
          top: 40,
        },
      },
      {
        id: "text",
        type: "body",
        text: "Right now, we're only able to support people who want to lose weight.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "center",
        },
        margins: {
          left: 16,
          right: 16,
          top: 16,
        },
      },
      {
        id: "divider",
        style: {
          height: 1,
          backgroundColor: {
            name: "borderDivider",
            light: "#E2E3EE",
            dark: "#353745",
          },
        },
        margins: {
          top: 40,
        },
      },
      {
        id: "button",
        type: "modalMain",
        text: "Edit my answer",
        action: "back",
        style: {
          height: 56,
          textColor: {
            name: "textAccent",
            light: "#7D8BF7",
            dark: "#8795FC",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
      {
        id: "divider",
        style: {
          height: 1,
          backgroundColor: {
            name: "borderDivider",
            light: "#E2E3EE",
            dark: "#353745",
          },
        },
      },
      {
        id: "button",
        type: "modalSecondary",
        text: "I’ll sign up later",
        action: "getEmail",
        style: {
          height: 56,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: true,
      conditionsForShow: [
        {
          variableName: "isGoalToLoseWeight",
          variableType: "boolean",
          condition: "==",
          value: false,
        },
      ],
    },
    appearance: {
      style: {
        cornerRadius: 16,
        backgroundColor: {
          name: "bgContent",
          light: "#ffffff",
          dark: "#252736",
        },
      },
      statusBarStyle: "light",
      overlayColor: {
        name: "bgOverlay",
        light: "#00000080",
        dark: "#00000080",
      },
    },
  },
  {
    id: "ALSdOq7PCCw81ftd3YQhQ",
    type: "modal",
    name: "OnboardingBlockerAmbitiousGoalModalScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "Please choose a less ambitious goal",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "center",
        },
        margins: {
          left: 16,
          right: 16,
          top: 40,
        },
      },
      {
        id: "text",
        type: "body",
        text: "Losing a lot of weight to quickly may cause health problems if not supervised by a healthcare professional.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "center",
        },
        margins: {
          left: 16,
          right: 16,
          top: 16,
        },
      },
      {
        id: "divider",
        style: {
          height: 1,
          backgroundColor: {
            name: "borderDivider",
            light: "#E2E3EE",
            dark: "#353745",
          },
        },
        margins: {
          top: 40,
        },
      },
      {
        id: "button",
        type: "modalMain",
        text: "Edit my answer",
        action: "back",
        style: {
          height: 56,
          textColor: {
            name: "textAccent",
            light: "#7D8BF7",
            dark: "#8795FC",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
      {
        id: "divider",
        style: {
          height: 1,
          backgroundColor: {
            name: "borderDivider",
            light: "#E2E3EE",
            dark: "#353745",
          },
        },
      },
      {
        id: "button",
        type: "modalSecondary",
        text: "I’ll sign up later",
        action: "getEmail",
        style: {
          height: 56,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: true,
      conditionsForShow: [
        {
          variableName: "targetBodyMassIndex",
          variableType: "number",
          condition: "<",
          value: 18.5,
        },
      ],
    },
    appearance: {
      style: {
        cornerRadius: 16,
        backgroundColor: {
          name: "bgContent",
          light: "#ffffff",
          dark: "#252736",
        },
      },
      statusBarStyle: "light",
      overlayColor: {
        name: "bgOverlay",
        light: "#00000080",
        dark: "#00000080",
      },
    },
  },
  {
    id: "3Ccp0fmOaE2mA0mA9q6JX",
    type: "page",
    name: "OnboardingImproveHealthScreen",
    components: [
      {
        id: "image",
        url: "https://telegra.ph/file/f2d85e483fd3ef3974ae7.png",
        width: 96,
        fillMode: "cover",
        height: 96,
        margins: {
          top: 68,
          left: 24,
        },
      },
      {
        id: "text",
        type: "title2",
        text: "It takes just {$weightFivePercent} to improve your health",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 22,
          fontWeight: 700,
          lineHeight: 26.8,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 24,
          right: 24,
          top: 64,
        },
      },
      {
        id: "text",
        type: "body",
        text: "A little goes a long way, and losing just 5% of your body weight can make a big difference, including reducing:",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 24,
          right: 24,
          top: 24,
        },
      },
      {
        id: "text",
        type: "body",
        text: "• The risk of diabetes",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 24,
          right: 24,
          top: 16,
        },
      },
      {
        id: "text",
        type: "body",
        text: "• High blood pressure",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 24,
          right: 24,
          top: 8,
        },
      },
      {
        id: "text",
        type: "body",
        text: "• High cholesterol",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 24,
          right: 24,
          top: 8,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgIntermission",
        light: "#282A3A",
        dark: "#0C0E1B",
      },
      statusBarStyle: "light",
      style: "dark",
    },
    groupParams: {
      hideGroup: true,
    },
    bottomBlock: {
      submitButton: {
        id: "button",
        type: "main",
        text: "Sounds good",
        action: "next",
        style: {
          height: 56,
          textColor: {
            name: "textInverse",
            light: "#ffffff",
            dark: "#323653",
          },
          backgroundColor: {
            name: "fillMain",
            light: "#323653",
            dark: "#FFFFFF",
          },
          cornerRadius: 12,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
      },
    },
  },
  {
    id: "nCA6ynKJHByxc-WG9ySsA",
    type: "page",
    name: "OnboardingMotivationEventScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "Do you have an event that’s inspiring you to lose weight?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "text",
        type: "body",
        text: "Having something to look forward to can help you stay on track.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 16,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Vacation",
            value: "vacation",
          },
          {
            label: "Wedding",
            value: "wedding",
          },
          {
            label: "Sports competition",
            value: "competition",
          },
          {
            label: "Summer",
            value: "summer",
          },
          {
            label: "Reunion",
            value: "reunion",
          },
          {
            label: "Birthday",
            value: "birthday",
          },
          {
            label: "Other",
            value: "other",
          },
          {
            label: "No, I don’t have an event",
            value: "none",
          },
        ],
        variableName: "specialEventType",
        required: true,
        analytics: {
          analyticsKey: "data",
          userPropertyKey: "Special Event Type",
        },
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
        action: "next",
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "fZ1CyE-qPFkxcjlsnAcAI",
    type: "page",
    name: "OnboardingMotivationEventDateScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "When is your {$specialEventTypeText}?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "spacer",
      },
      {
        id: "dateInput",
        type: "date",
        style: {
          borderColor: {
            name: "borderDivider",
            light: "#E2E3EE",
            dark: "#353745",
          },
          borderWidth: 1,
          fontFamily: "Montserrat",
          fontSize: 40,
          fontWeight: 600,
          lineHeight: 48,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "center",
        },
        paddings: {
          bottom: 12,
        },
        variableName: "specialEvent",
        required: false,
        analytics: {
          analyticsKey: "date",
        },
        margins: {
          left: 16,
          right: 16,
          top: 0,
        },
        limits: {
          min: "today",
        },
      },
      {
        id: "button",
        type: "secondary",
        text: "Skip this question",
        action: "skip",
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 14,
          fontWeight: 700,
          lineHeight: 17,
          textAlign: "center",
          textColor: {
            name: "textSecondary",
            light: "#6A6D82",
            dark: "#8E90A1",
          },
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
      },
      {
        id: "spacer",
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [
        {
          variableName: "specialEventType",
          variableType: "array",
          condition: "array-not-contains",
          value: ["none"],
        },
      ],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
    bottomBlock: {
      submitButton: {
        id: "button",
        type: "main",
        text: "Next",
        action: "next",
        style: {
          height: 56,
          textColor: {
            name: "textInverse",
            light: "#ffffff",
            dark: "#323653",
          },
          backgroundColor: {
            name: "fillMain",
            light: "#323653",
            dark: "#FFFFFF",
          },
          cornerRadius: 12,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
      },
    },
    groupParams: {
      endOfGroup: true,
    },
  },
  {
    id: "ngglFXftlWrblbDzl2PbK",
    type: "webview",
    name: "OnboardingWeightPredictLoaderScreen",
    url: "https://onboarding-builder.pages.dev/webviews/loader.html",
    navigationParams: {
      canGoBack: false,
      shouldBeSkippedWhenGoingBack: true,
      conditionsForShow: [],
    },
    appearance: {
      ignoreSafeArea: true,
      backgroundColor: {
        name: "custom",
        light: "#f5f6f8",
        dark: "#f5f6f8",
      },
    },
  },
  {
    id: "ddPrsb6b9cDJeP5iLvJWH",
    type: "webview",
    name: "OnboardingWeightPredictResultScreen",
    url: "https://onboarding-charts.pages.dev/chart-1",
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      ignoreSafeArea: true,
      backgroundColor: {
        name: "custom",
        light: "#f5f6f8",
        dark: "#f5f6f8",
      },
    },
  },
  {
    id: "fPxOU32OzRagZ1dUEGMBa",
    type: "page",
    name: "OnboardingMainMealsCountScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "How many meals do you eat per day?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "1",
            value: "1",
          },
          {
            label: "2",
            value: "2",
          },
          {
            label: "3",
            value: "3",
          },
          {
            label: "4+",
            value: "4+",
          },
        ],
        variableName: "numberOfMeals",
        required: true,
        analytics: {
          analyticsKey: "data",
          userPropertyKey: "Meals Count",
        },
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
        action: "next",
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
    groupParams: {
      endOfGroup: false,
      startGroup: {
        title: "HABITS AND BEHAVIOR",
        icon: {
          light:
            "https://onboarding-builder.pages.dev/assets/screen-groups/brain.png",
          dark: "https://onboarding-builder.pages.dev/assets/screen-groups/brain-dark.png",
        },
      },
    },
  },
  {
    id: "y2mrRDGlcGoWMh5jCllHf",
    type: "page",
    name: "OnboardingDietScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "Are you currently following a specific dietary pattern?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "listInput",
        type: "multiple",
        options: [
          {
            label: "I’ll eat mostly anything",
            value: "regular",
            shouldUnselectOthers: true,
          },
          {
            label: "Vegetarian",
            value: "vegetarian",
          },
          {
            label: "Fully plant-based",
            value: "vegan",
          },
          {
            label: "Pescatarian",
            value: "pescetarian",
          },
          {
            label: "Lactose-free",
            value: "lactose_free",
          },
          {
            label: "Gluten-free",
            value: "gluten_free",
          },
          {
            label: "Keto",
            value: "keto",
          },
          {
            label: "Other",
            value: "other",
          },
        ],
        variableName: "diet",
        required: true,
        analytics: {
          analyticsKey: "data",
          userPropertyKey: "Diet",
        },
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
    bottomBlock: {
      submitButton: {
        id: "button",
        type: "main",
        text: "Continue",
        action: "next",
        style: {
          height: 56,
          textColor: {
            name: "textInverse",
            light: "#ffffff",
            dark: "#323653",
          },
          backgroundColor: {
            name: "fillMain",
            light: "#323653",
            dark: "#FFFFFF",
          },
          cornerRadius: 12,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
      },
    },
  },
  {
    id: "Os_vyIVqF5O6iSUvCmhWW",
    type: "page",
    name: "OnboardingBreakfastTimeScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "When do your usually have breakfast?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "spacer",
      },
      {
        id: "dateInput",
        type: "time",
        style: {
          borderColor: {
            name: "borderDivider",
            light: "#E2E3EE",
            dark: "#353745",
          },
          borderWidth: 1,
          fontFamily: "Montserrat",
          fontSize: 40,
          fontWeight: 600,
          lineHeight: 48,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "center",
        },
        paddings: {
          bottom: 12,
        },
        variableName: "breakfastTime",
        defaultValue: "08:00",
        required: false,
        analytics: {
          analyticsKey: "date",
        },
        margins: {
          left: 16,
          right: 16,
          top: 0,
        },
      },
      {
        id: "button",
        type: "secondary",
        text: "I don’t eat breakfast usually",
        action: "skip",
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 14,
          fontWeight: 700,
          lineHeight: 17,
          textAlign: "center",
          textColor: {
            name: "textSecondary",
            light: "#6A6D82",
            dark: "#8E90A1",
          },
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
      },
      {
        id: "spacer",
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
    bottomBlock: {
      submitButton: {
        id: "button",
        type: "main",
        text: "Continue",
        action: "next",
        style: {
          height: 56,
          textColor: {
            name: "textInverse",
            light: "#ffffff",
            dark: "#323653",
          },
          backgroundColor: {
            name: "fillMain",
            light: "#323653",
            dark: "#FFFFFF",
          },
          cornerRadius: 12,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
      },
    },
  },
  {
    id: "qhxyxfvk304otvwfmOkRo",
    type: "page",
    name: "OnboardingDinnerTimeScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "When do your usually have dinner?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 32,
          top: 8,
        },
      },
      {
        id: "spacer",
      },
      {
        id: "dateInput",
        type: "time",
        style: {
          borderColor: {
            name: "borderDivider",
            light: "#E2E3EE",
            dark: "#353745",
          },
          borderWidth: 1,
          fontFamily: "Montserrat",
          fontSize: 40,
          fontWeight: 600,
          lineHeight: 48,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "center",
        },
        paddings: {
          bottom: 12,
        },
        variableName: "dinnerTime",
        required: false,
        analytics: {
          analyticsKey: "date",
        },
        defaultValue: "18:00",
        margins: {
          left: 16,
          right: 16,
          top: 0,
        },
      },
      {
        id: "button",
        type: "secondary",
        text: "I don’t eat dinner usually",
        action: "skip",
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 14,
          fontWeight: 700,
          lineHeight: 17,
          textAlign: "center",
          textColor: {
            name: "textSecondary",
            light: "#6A6D82",
            dark: "#8E90A1",
          },
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
      },
      {
        id: "spacer",
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [
        {
          variableName: "breakfastTime",
          variableType: "date",
          condition: "is-empty",
          value: null,
        },
      ],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
    bottomBlock: {
      submitButton: {
        id: "button",
        type: "main",
        text: "Continue",
        action: "next",
        style: {
          height: 56,
          textColor: {
            name: "textInverse",
            light: "#ffffff",
            dark: "#323653",
          },
          backgroundColor: {
            name: "fillMain",
            light: "#323653",
            dark: "#FFFFFF",
          },
          cornerRadius: 12,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
      },
    },
  },
  {
    id: "racb0OwjeC1osPXrsszCQ",
    type: "page",
    name: "OnboardingWaterIntakeScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "What's your daily water intake?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "I mainly drink coffee, tea or other beverages",
            value: "coffee",
          },
          {
            label: "About 2 glasses of water",
            value: "2",
          },
          {
            label: "2 to 6 glasses of water",
            value: "6",
          },
          {
            label: "More than 6 glasses",
            value: "6+",
          },
        ],
        variableName: "waterIntake",
        required: true,
        analytics: {
          analyticsKey: "data",
          userPropertyKey: "Water Intake",
        },
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
        action: "next",
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "uSnkdweM0WsC9reV7biQz",
    type: "page",
    name: "OnboardingWorkTypeScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "What's your lifestyle like?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Sedentary",
            value: "sedentary",
          },
          {
            label: "Moderately active",
            value: "mid_active",
          },
          {
            label: "Very active",
            value: "phisically_demanding",
          },
        ],
        variableName: "work",
        required: true,
        analytics: {
          analyticsKey: "data",
          userPropertyKey: "Work Type",
        },
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
        action: "next",
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "owHkNOI9BfZqyQsnjREqB",
    type: "page",
    name: "OnboardingChildrenScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "Do you have children?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Yes, we live together",
            value: "yes_together",
          },
          {
            label: "Yes, but we live separetly",
            value: "yes_separately",
          },
          {
            label: "No children",
            value: "no",
          },
        ],
        variableName: "children",
        required: true,
        analytics: {
          analyticsKey: "data",
          userPropertyKey: "Children",
        },
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
        action: "next",
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
    groupParams: {
      endOfGroup: true,
    },
  },
  {
    id: "vMxT-Ai1C1uEbhH56895H",
    type: "webview",
    name: "OnboardingLoaderWebScreen",
    url: "https://onboarding-builder.pages.dev/webviews/slideshow.html",
    navigationParams: {
      canGoBack: false,
      shouldBeSkippedWhenGoingBack: true,
      conditionsForShow: [],
    },
    appearance: {
      ignoreSafeArea: true,
      backgroundColor: {
        name: "custom",
        light: "#ffffff",
        dark: "#ffffff",
      },
    },
  },
  {
    id: "aFF5TGUBg77S0_gwSt0qg",
    type: "webview",
    name: "OnboardingWeightPredictModifiedResultScreen",
    url: "https://onboarding-charts.pages.dev/chart-2",
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      ignoreSafeArea: true,
      backgroundColor: {
        name: "custom",
        light: "#f5f6f8",
        dark: "#f5f6f8",
      },
    },
  },
  {
    id: "zW4eghTf9ac4r7EyKYuhX",
    type: "page",
    name: "OnboardingWhatDrivesScreen",
    components: [
      {
        id: "image",
        url: "https://telegra.ph/file/8d5fad12b1e0aefdb1db0.png",
        width: 96,
        fillMode: "cover",
        height: 96,
        margins: {
          left: 24,
          top: 68,
        },
      },
      {
        id: "text",
        type: "title2",
        text: "What drives you? Let's find out",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 22,
          fontWeight: 700,
          lineHeight: 26.8,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 24,
          right: 24,
          top: 64,
        },
      },
      {
        id: "text",
        type: "body",
        text: "Research shows that keeping your motivation top of mind helps sustain long-term changes.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 24,
          right: 24,
          top: 24,
        },
      },
      {
        id: "text",
        type: "body",
        text: "We have just a few more questions to finalize your journey.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 24,
          right: 24,
          top: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
      style: "dark",
      statusBarStyle: "light",
    },
    bottomBlock: {
      submitButton: {
        id: "button",
        type: "main",
        text: "Let’s go!",
        action: "next",
        style: {
          height: 56,
          textColor: {
            name: "textInverse",
            light: "#ffffff",
            dark: "#323653",
          },
          backgroundColor: {
            name: "fillMain",
            light: "#323653",
            dark: "#FFFFFF",
          },
          cornerRadius: 12,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
      },
    },
  },
  {
    id: "sBJHdOs2KozZh30sWj8xy",
    type: "page",
    name: "OnboardingDescribeMotivationScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "How would you describe your motivation to lose weight?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "It’s pretty consistent",
            value: "consistent",
          },
          {
            label: "It ebbs and flows",
            value: "flows",
          },
          {
            label: "I'm still finding it",
            value: "still_finding",
          },
        ],
        variableName: "describeMotivation",
        required: true,
        analytics: {
          analyticsKey: "data",
        },
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
        action: "next",
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
    groupParams: {
      startGroup: {
        title: "GOAL AND MOTIVATION",
        icon: {
          light:
            "https://onboarding-builder.pages.dev/assets/screen-groups/goal.png",
          dark: "https://onboarding-builder.pages.dev/assets/screen-groups/goal-dark.png",
        },
      },
    },
  },
  {
    id: "yh2_WC932ymH5Xqfj4Zi6",
    type: "page",
    name: "OnboardingCurrentWeightAffectScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "Does your current weight affect the way you feel about yourself?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Yes, a lot",
            value: "yes_a_lot",
          },
          {
            label: "Sure, a bit",
            value: "sure_a_bit",
          },
          {
            label: "It used to, but not anymore",
            value: "not_anymore",
          },
          {
            label: "No, not really",
            value: "no",
          },
        ],
        variableName: "currentWeightAffect",
        required: true,
        analytics: {
          analyticsKey: "data",
        },
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
        action: "next",
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "hZ-QzONyp0sg6eWs2qjjt",
    type: "page",
    name: "OnboardingHopeAchieveScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "What do you hope to achieve by losing weight?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "I want to feel better",
            value: "feel_better",
          },
          {
            label: "I want to change how I look",
            value: "change_look",
          },
          {
            label: "I want to improve my health and  live longer",
            value: "improve_health",
          },
          {
            label: "I want to improve my mental clarity and focus",
            value: "improve_mental_clarity",
          },
          {
            label: "I want to be more confident meeting new people",
            value: "more_confident",
          },
        ],
        variableName: "changeMostImportant",
        required: true,
        analytics: {
          analyticsKey: "data",
        },
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
        action: "next",
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "8Ui6rGsLI1RjxVlsujnT-",
    type: "page",
    name: "OnboardingChangeMostImportantScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "When you think about changing how you feel, what's the most important?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Having more energy",
            value: "more_energy",
          },
          {
            label: "Gaining confidence",
            value: "improve_confidence",
          },
          {
            label: "Feeling better in my clothes",
            value: "feeling_better_in_clothes",
          },
          {
            label: "Being more comfortable physically",
            value: "more_comfortably_physically",
          },
        ],
        variableName: "motivationFeel",
        required: true,
        analytics: {
          analyticsKey: "data",
        },
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
        action: "next",
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [
        {
          variableName: "changeMostImportant",
          variableType: "array",
          condition: "array-contains",
          value: ["feel_better"],
        },
      ],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "UHAVmvmOfWMlH0pmq3TCz",
    type: "page",
    name: "OnboardingChangeMostImportantScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "When you think about changing how you look, what's the most important?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Looking better in my clothes",
            value: "looking_better_in_clothes",
          },
          {
            label: "Changing the size of my body",
            value: "change_size_of_body",
          },
          {
            label: "Lowering my body fat percentage",
            value: "lower_fat_pecentage",
          },
          {
            label: "Gaining muscle",
            value: "gaining_muscle",
          },
          {
            label: "Being happier with how I look",
            value: "being_happier_with_look",
          },
        ],
        variableName: "changeLook",
        required: true,
        analytics: {
          analyticsKey: "data",
        },
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
        action: "next",
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [
        {
          variableName: "changeMostImportant",
          variableType: "array",
          condition: "array-contains",
          value: ["change_look"],
        },
      ],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "6nacbEKda_cuM-eNvjPNN",
    type: "page",
    name: "OnboardingChangeMostImportantScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "When it comes to your health, what is most important?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Being generally healthy",
            value: "generally_healthy",
          },
          {
            label: "Preventing my existing health condition",
            value: "prevent_existing_health_conditions",
          },
          {
            label: "Boosting my immune system",
            value: "boosting_immune_system",
          },
          {
            label: "Reducing stress",
            value: "reducing_stress",
          },
          {
            label: "Getting better sleep",
            value: "getting_better_sleep",
          },
          {
            label: "Improving my gut health",
            value: "imrpove_gut_health",
          },
          {
            label: "Living longer",
            value: "living_longer",
          },
        ],
        variableName: "liveLonger",
        required: true,
        analytics: {
          analyticsKey: "data",
        },
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
        action: "next",
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [
        {
          variableName: "changeMostImportant",
          variableType: "array",
          condition: "array-contains",
          value: ["improve_health"],
        },
      ],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "XrF9zFswogNdEJpR8-N2m",
    type: "page",
    name: "OnboardingChangeMostImportantScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "Why is it important for you to improve mental clarity and focus?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "So I can be more productive at work",
            value: "productive_at_work",
          },
          {
            label: "To feel more engaged socially",
            value: "engaged_socially",
          },
          {
            label: "To be more clear in my decisions",
            value: "clear_decisions",
          },
          {
            label: "To improve my memory",
            value: "improve_memory",
          },
        ],
        variableName: "clarity",
        required: true,
        analytics: {
          analyticsKey: "data",
        },
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
        action: "next",
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [
        {
          variableName: "changeMostImportant",
          variableType: "array",
          condition: "array-contains",
          value: ["improve_mental_clarity"],
        },
      ],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "cosMNsfCvdf4rvehVhIC8",
    type: "page",
    name: "OnboardingChangeMostImportantScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "Why is it important to feel more confident around new people?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "To make new friends",
            value: "make_friends",
          },
          {
            label: "To experience new things",
            value: "experience_new_things",
          },
          {
            label: "To perform better at work",
            value: "better_at_work",
          },
          {
            label: "To be a role model for my family",
            value: "role_model_for_family",
          },
        ],
        variableName: "meetingPeople",
        required: true,
        analytics: {
          analyticsKey: "data",
        },
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
        action: "next",
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [
        {
          variableName: "changeMostImportant",
          variableType: "array",
          condition: "array-contains",
          value: ["more_confident"],
        },
      ],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "J4lUSHpvRWK_Z-lNK7rg-",
    type: "page",
    name: "OnboardingHowToFeelScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "During my weight loss journey, I'd like to feel:",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "listInput",
        type: "multiple",
        options: [
          {
            label: "Happy",
            value: "happy",
          },
          {
            label: "At peace",
            value: "at_peace",
          },
          {
            label: "Inspired",
            value: "inspired",
          },
          {
            label: "Grateful",
            value: "grateful",
          },
          {
            label: "Empowered",
            value: "empowered",
          },
          {
            label: "Informed",
            value: "informed",
          },
          {
            label: "Other",
            value: "other",
          },
        ],
        variableName: "feel",
        required: true,
        analytics: {
          analyticsKey: "data",
        },
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
    bottomBlock: {
      submitButton: {
        id: "button",
        type: "main",
        text: "Next",
        action: "next",
        style: {
          height: 56,
          textColor: {
            name: "textInverse",
            light: "#ffffff",
            dark: "#323653",
          },
          backgroundColor: {
            name: "fillMain",
            light: "#323653",
            dark: "#FFFFFF",
          },
          cornerRadius: 12,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
      },
    },
  },
  {
    id: "kmkvkRG2w0EBYshtApapT",
    type: "page",
    name: "OnboardingAlsoToExploreScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "What else do you want to explore during your weight loss journey?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "listInput",
        type: "multiple",
        options: [
          {
            label: "My brain",
            value: "brain",
          },
          {
            label: "My behaviors",
            value: "behaviours",
          },
          {
            label: "Digestive health",
            value: "digestive_health",
          },
          {
            label: "Better sleep",
            value: "sleep",
          },
          {
            label: "Conquering stress",
            value: "stress",
          },
          {
            label: "Boosting my energy",
            value: "energy",
          },
          {
            label: "My nutrition knowledge",
            value: "nutrition",
          },
        ],
        variableName: "explore",
        required: true,
        analytics: {
          analyticsKey: "data",
        },
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
    bottomBlock: {
      submitButton: {
        id: "button",
        type: "main",
        text: "Next",
        action: "next",
        style: {
          height: 56,
          textColor: {
            name: "textInverse",
            light: "#ffffff",
            dark: "#323653",
          },
          backgroundColor: {
            name: "fillMain",
            light: "#323653",
            dark: "#FFFFFF",
          },
          cornerRadius: 12,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
      },
    },
  },
  {
    id: "5_D1ZsFZlSEaZdZvsf_Wf",
    type: "page",
    name: "OnboardingHowMotivatedScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "Right now, how motivated are you to reach your goal weight?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "I’m 100% in",
            value: "in",
          },
          {
            label: "I’m pretty ready!",
            value: "pretty_ready",
          },
          {
            label: "I'm worried I'll get stuck",
            value: "will_get_stuck",
          },
          {
            label: "I have a few doubts",
            value: "few_doubts",
          },
        ],
        variableName: "how_motivated",
        required: true,
        analytics: {
          analyticsKey: "data",
        },
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
        action: "next",
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "WmJ5RK9C2KV1L43NkVRqE",
    type: "page",
    name: "OnboardingMotivationDoubtsScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "What’s giving you doubts?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "listInput",
        type: "multiple",
        options: [
          {
            label: "I haven’t had much luck in the past",
            value: "dont_have_luck_past",
          },
          {
            label: "Previous diets were too restrictive",
            value: "restrictive_diets",
          },
          {
            label: "I worry I’ll just gain the weight back",
            value: "gain_weight_back",
          },
          {
            label: "I think I’ll plateau",
            value: "will_plateau",
          },
          {
            label: "I question my motivation",
            value: "my_motivation",
          },
          {
            label: "It doesn’t seem realistic",
            value: "not_realistic",
          },
          {
            label: "Other",
            value: "other",
          },
        ],
        variableName: "doubts",
        required: true,
        analytics: {
          analyticsKey: "data",
        },
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [
        {
          variableName: "how_motivated",
          variableType: "array",
          condition: "array-not-contains",
          value: ["in"],
        },
      ],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
    bottomBlock: {
      submitButton: {
        id: "button",
        type: "main",
        text: "Next",
        action: "next",
        style: {
          height: 56,
          textColor: {
            name: "textInverse",
            light: "#ffffff",
            dark: "#323653",
          },
          backgroundColor: {
            name: "fillMain",
            light: "#323653",
            dark: "#FFFFFF",
          },
          cornerRadius: 12,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
      },
    },
    groupParams: {
      endOfGroup: true,
    },
  },
  {
    id: "jvBHc42DulshYqXLZ2Gjv",
    type: "page",
    name: "OnboardingKeepMotivationScreen",
    components: [
      {
        id: "image",
        url: "https://telegra.ph/file/c9554656e4c3cb1db50e3.png",
        width: 96,
        fillMode: "cover",
        height: 96,
        margins: {
          top: 68,
          left: 24,
        },
      },
      {
        id: "text",
        type: "title2",
        text: "Keeping motivation front‑of‑mind is key!",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 22,
          fontWeight: 700,
          lineHeight: 26.8,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 24,
          right: 24,
          top: 64,
        },
      },
      {
        id: "text",
        type: "body",
        text: "You’re starting strong, and we’ll continue to build on this momentum with your personalized journey!",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 24,
          right: 24,
          top: 24,
        },
      },
      {
        id: "text",
        type: "body",
        text: "In this last section, we have just a few health questions before we finalize everything.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 24,
          right: 24,
          top: 24,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
      style: "dark",
      statusBarStyle: "light",
    },
    bottomBlock: {
      submitButton: {
        id: "button",
        type: "main",
        text: "Let’s finish this!",
        action: "next",
        style: {
          height: 56,
          textColor: {
            name: "textInverse",
            light: "#ffffff",
            dark: "#323653",
          },
          backgroundColor: {
            name: "fillMain",
            light: "#323653",
            dark: "#FFFFFF",
          },
          cornerRadius: 12,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
      },
    },
  },
  {
    id: "M2FjBnkH3HD8aLCW89Ag3",
    type: "page",
    name: "OnboardingPregnancyScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "Are you pregnant or breastfeeding?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "text",
        type: "body",
        text: "We ask for this information to provide you with a safer fasting experience",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 16,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "I am neither pregnant, nor breastfeeding",
            value: "no",
          },
          {
            label: "I am pregnant",
            value: "pregnant",
          },
          {
            label: "I am breastfeeding",
            value: "breastfeeding",
          },
          {
            label: "Prefer not to answer",
            value: "notAnswered",
          },
        ],
        variableName: "pregnancy",
        required: true,
        analytics: {
          analyticsKey: "data",
          userPropertyKey: "Pregnancy",
        },
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
        action: "next",
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [
        {
          variableName: "gender",
          variableType: "array",
          condition: "array-contains",
          value: ["female"],
        },
      ],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
    groupParams: {
      startGroup: {
        title: "MEDICAL CONDITIONS",
        icon: {
          light:
            "https://onboarding-builder.pages.dev/assets/screen-groups/heart.png",
          dark: "https://onboarding-builder.pages.dev/assets/screen-groups/heart-dark.png",
        },
      },
    },
  },
  {
    id: "LKS5DDBNvkUOnnk9GDEoe",
    type: "modal",
    name: "OnboardingBlockerPregnancyModalScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "We don’t recommend intermittent fasting for you right now",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "center",
        },
        margins: {
          left: 16,
          right: 16,
          top: 40,
        },
      },
      {
        id: "text",
        type: "body",
        text: "Sign up for our emails to stay updated and join us when fasting is a better option for you. (And congratulations on the new arrival!)",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "center",
        },
        margins: {
          left: 16,
          right: 16,
          top: 16,
        },
      },
      {
        id: "divider",
        style: {
          height: 1,
          backgroundColor: {
            name: "borderDivider",
            light: "#E2E3EE",
            dark: "#353745",
          },
        },
        margins: {
          top: 40,
        },
      },
      {
        id: "button",
        type: "modalMain",
        text: "Edit my answer",
        action: "back",
        style: {
          height: 56,
          textColor: {
            name: "textAccent",
            light: "#7D8BF7",
            dark: "#8795FC",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
      {
        id: "divider",
        style: {
          height: 1,
          backgroundColor: {
            name: "borderDivider",
            light: "#E2E3EE",
            dark: "#353745",
          },
        },
      },
      {
        id: "button",
        type: "modalSecondary",
        text: "I’ll sign up later",
        action: "getEmail",
        style: {
          height: 56,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: true,
      conditionsForShow: [
        {
          variableName: "pregnancy",
          variableType: "array",
          condition: "array-contains-any",
          value: ["pregnant", "breastfeeding"],
        },
      ],
    },
    appearance: {
      style: {
        cornerRadius: 16,
        backgroundColor: {
          name: "bgContent",
          light: "#ffffff",
          dark: "#252736",
        },
      },
      statusBarStyle: "light",
      overlayColor: {
        name: "bgOverlay",
        light: "#00000080",
        dark: "#00000080",
      },
    },
  },
  {
    id: "G-mQgXOnR-zYEG4Y20Peb",
    type: "modal",
    name: "OnboardingAlertPregnancyModalScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "Prioritizing your health and safety",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "center",
        },
        margins: {
          left: 16,
          right: 16,
          top: 40,
        },
      },
      {
        id: "text",
        type: "body",
        text: "No worries — we respect that you may not want to share this. Please be aware that fasting isn't safe for anyone pregnant or breastfeeding.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "center",
        },
        margins: {
          left: 16,
          right: 16,
          top: 16,
        },
      },
      {
        id: "divider",
        style: {
          height: 1,
          backgroundColor: {
            name: "borderDivider",
            light: "#E2E3EE",
            dark: "#353745",
          },
        },
        margins: {
          top: 40,
        },
      },
      {
        id: "button",
        type: "modalMain",
        text: "Continue",
        action: "next",
        style: {
          height: 56,
          textColor: {
            name: "textAccent",
            light: "#7D8BF7",
            dark: "#8795FC",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: true,
      conditionsForShow: [
        {
          variableName: "pregnancy",
          variableType: "array",
          condition: "array-contains",
          value: ["notAnswered"],
        },
      ],
    },
    appearance: {
      style: {
        cornerRadius: 16,
        backgroundColor: {
          name: "bgContent",
          light: "#ffffff",
          dark: "#252736",
        },
      },
      statusBarStyle: "light",
      overlayColor: {
        name: "bgOverlay",
        light: "#00000080",
        dark: "#00000080",
      },
    },
  },
  {
    id: "O9lNaC8kyYGsjw6o45Rb7",
    type: "page",
    name: "OnboardingHealthConditionsScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "Have you been diagnosed with any of the following health conditions?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "text",
        type: "body",
        text: "We ask for this information to provide you with a safer fasting experience",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 16,
        },
      },
      {
        id: "listInput",
        type: "multiple",
        options: [
          {
            label: "I don’t have any medical conditions",
            value: "none",
            shouldUnselectOthers: true,
          },
          {
            label: "Diabetes",
            value: "diabetes",
          },
          {
            label: "Heart disease",
            value: "cardiovascular_disease",
          },
          {
            label: "High cholesterol",
            value: "high_cholesterol",
          },
          {
            label: "Mental health disorders",
            value: "mental_health",
          },
          {
            label: "High blood pressure",
            value: "high_blood_pressure",
          },
          {
            label: "Chronic kidney disease (CKD)",
            value: "kidney",
          },
          {
            label: "Cancer",
            value: "cancer",
          },
          {
            label: "Gastrointestinal disorder",
            value: "gastrointestinal_disorder",
          },
          {
            label: "Physical disability",
            value: "physical_disability",
          },
        ],
        variableName: "healthConditions",
        required: true,
        analytics: {
          analyticsKey: "data",
          userPropertyKey: "Conditions",
        },
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
    bottomBlock: {
      submitButton: {
        id: "button",
        type: "main",
        text: "Next",
        action: "next",
        style: {
          height: 56,
          textColor: {
            name: "textInverse",
            light: "#ffffff",
            dark: "#323653",
          },
          backgroundColor: {
            name: "fillMain",
            light: "#323653",
            dark: "#FFFFFF",
          },
          cornerRadius: 12,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
      },
    },
  },
  {
    id: "7eknHv9HPmMRON8TdOa4C",
    type: "modal",
    name: "OnboardingAlertHealthConditionsModalScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "Prioritizing your health and safety",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "center",
        },
        margins: {
          left: 16,
          right: 16,
          top: 40,
        },
      },
      {
        id: "text",
        type: "body",
        text: "If you have any medical conditions, please speak to your doctor before you start fasting to make sure fasting is right for you.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "center",
        },
        margins: {
          left: 16,
          right: 16,
          top: 16,
        },
      },
      {
        id: "divider",
        style: {
          height: 1,
          backgroundColor: {
            name: "borderDivider",
            light: "#E2E3EE",
            dark: "#353745",
          },
        },
        margins: {
          top: 40,
        },
      },
      {
        id: "button",
        type: "modalSecondary",
        text: "Continue",
        action: "next",
        style: {
          height: 56,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: true,
      conditionsForShow: [
        {
          variableName: "healthConditions",
          variableType: "array",
          condition: "array-not-contains",
          value: ["none"],
        },
      ],
    },
    appearance: {
      style: {
        cornerRadius: 16,
        backgroundColor: {
          name: "bgContent",
          light: "#ffffff",
          dark: "#252736",
        },
      },
      statusBarStyle: "light",
      overlayColor: {
        name: "bgOverlay",
        light: "#00000080",
        dark: "#00000080",
      },
    },
  },
  {
    id: "OaDl59gH48PPxKSq3cybJ",
    type: "page",
    name: "OnboardingEatingDisordersScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "Are you currently diagnosed with an eating disorders?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "text",
        type: "body",
        text: "We ask for this information to provide you with a safer fasting experience",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 16,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Yes",
            value: "yes",
          },
          {
            label: "No",
            value: "no",
          },
        ],
        variableName: "eatingDisorders",
        required: true,
        analytics: {
          analyticsKey: "data",
          userPropertyKey: "eatingDisorders",
        },
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
        action: "next",
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "51vXFoN8qDblJ8Hf49IQh",
    type: "modal",
    name: "OnboardingBlockerEatingDisordersModalScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "We care about your health and safety",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "center",
        },
        margins: {
          left: 16,
          right: 16,
          top: 40,
        },
      },
      {
        id: "text",
        type: "body",
        text: "Fasting isn’t for everyone — especially if you have other health concerns. We recommend that you speak to your doctor for further advice and support.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "center",
        },
        margins: {
          left: 16,
          right: 16,
          top: 16,
        },
      },
      {
        id: "text",
        type: "body",
        text: "Sign up for our emails to stay in the loop, and we'll be here if fasting becomes a better fit for you!",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "center",
        },
        margins: {
          left: 16,
          right: 16,
          top: 16,
        },
      },
      {
        id: "divider",
        style: {
          height: 1,
          backgroundColor: {
            name: "borderDivider",
            light: "#E2E3EE",
            dark: "#353745",
          },
        },
        margins: {
          top: 40,
        },
      },
      {
        id: "button",
        type: "modalMain",
        text: "Edit my answer",
        action: "back",
        style: {
          height: 56,
          textColor: {
            name: "textAccent",
            light: "#7D8BF7",
            dark: "#8795FC",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
      {
        id: "divider",
        style: {
          height: 1,
          backgroundColor: {
            name: "borderDivider",
            light: "#E2E3EE",
            dark: "#353745",
          },
        },
      },
      {
        id: "button",
        type: "modalSecondary",
        text: "I’ll sign up later",
        action: "getEmail",
        style: {
          height: 56,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: true,
      conditionsForShow: [
        {
          variableName: "eatingDisorders",
          condition: "array-contains",
          variableType: "array",
          value: ["yes"],
        },
      ],
    },
    appearance: {
      style: {
        cornerRadius: 16,
        backgroundColor: {
          name: "bgContent",
          light: "#ffffff",
          dark: "#252736",
        },
      },
      statusBarStyle: "light",
      overlayColor: {
        name: "bgOverlay",
        light: "#00000080",
        dark: "#00000080",
      },
    },
  },
  {
    id: "yt8nA4KHdEu5Ely5Gg4fx",
    type: "page",
    name: "OnboardingDrugsScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "Are you taking any medications?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "text",
        type: "body",
        text: "We ask for this information to provide you with a safer fasting experience",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 16,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Yes",
            value: "yes",
          },
          {
            label: "No",
            value: "no",
          },
        ],
        variableName: "drugs",
        required: true,
        analytics: {
          analyticsKey: "data",
          userPropertyKey: "Drugs",
        },
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
        action: "next",
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "j8hw622o79HJaEXEbiqJ9",
    type: "modal",
    name: "OnboardingAlertDrugsModalScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "Prioritizing your health and safety",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "center",
        },
        margins: {
          left: 16,
          right: 16,
          top: 40,
        },
      },
      {
        id: "text",
        type: "body",
        text: "We recommend speaking with your doctor before making any dietary changes and to ensure you can fast safely with your current prescription.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "center",
        },
        margins: {
          left: 16,
          right: 16,
          top: 16,
        },
      },
      {
        id: "divider",
        style: {
          height: 1,
          backgroundColor: {
            name: "borderDivider",
            light: "#E2E3EE",
            dark: "#353745",
          },
        },
        margins: {
          top: 40,
        },
      },
      {
        id: "button",
        type: "modalSecondary",
        text: "Continue",
        action: "next",
        style: {
          height: 56,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: true,
      conditionsForShow: [
        {
          variableName: "drugs",
          condition: "array-contains",
          variableType: "array",
          value: ["yes"],
        },
      ],
    },
    appearance: {
      style: {
        cornerRadius: 16,
        backgroundColor: {
          name: "bgContent",
          light: "#ffffff",
          dark: "#252736",
        },
      },
      statusBarStyle: "light",
      overlayColor: {
        name: "bgOverlay",
        light: "#00000080",
        dark: "#00000080",
      },
    },
  },
  {
    id: "vRFWr5Vhk5ycGBabVnfHR",
    type: "page",
    name: "OnboardingFastingFamiliarityScreen",
    components: [
      {
        id: "text",
        type: "question",
        text: "Have you tried fasting before?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 8,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Never",
            value: "never",
          },
          {
            label: "Yes, occasionally",
            value: "irregularly",
          },
          {
            label: "I have a lot of fasting experience",
            value: "experienced",
          },
        ],
        variableName: "fastingFamiliarity",
        required: true,
        analytics: {
          analyticsKey: "data",
          userPropertyKey: "Fasting familiarity",
        },
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
          top: 32,
        },
        action: "next",
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
    groupParams: {
      endOfGroup: true,
    },
  },
  {
    id: "IWBPg-uIvtNJGucerOeDb",
    type: "page",
    name: "OnboardingHealthNoticeScreen",
    components: [
      {
        id: "image",
        url: "https://telegra.ph/file/ee64743f3be3be7ed7259.png",
        width: 96,
        fillMode: "cover",
        height: 96,
        margins: {
          top: 68,
          left: 24,
        },
      },
      {
        id: "text",
        type: "title2",
        text: "Disclaimer",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 22,
          fontWeight: 700,
          lineHeight: 26.8,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 24,
          right: 24,
          top: 64,
        },
      },
      {
        id: "text",
        type: "body",
        text: "Stay safe!",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 24,
          right: 24,
          top: 24,
        },
      },
      {
        id: "text",
        type: "body",
        text: "Fasting can improve your health and wellbeing. But it’s important to fast safely.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 24,
          right: 24,
          top: 24,
        },
      },
      {
        id: "text",
        type: "body",
        text: "Before making any changes to your diet, consult your doctor to see if fasting is appropriate for you, especially if you:",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 24,
          right: 24,
          top: 24,
        },
      },
      {
        id: "text",
        type: "body",
        text: "• have any medical condition;",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 32,
          right: 24,
          top: 24,
        },
      },
      {
        id: "text",
        type: "body",
        text: "• are taking any medication; and/or",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 32,
          right: 24,
        },
      },
      {
        id: "text",
        type: "body",
        text: "• plan to fast for longer than 18 hours.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 32,
          right: 24,
        },
      },
      {
        id: "text",
        type: "body",
        text: "Please note: If you’re underweight, under 18 years old, pregnant or breastfeeding, or have a diagnosed eating disorder, we don’t advise that you fast.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 24,
          right: 24,
        },
      },
      {
        id: "text",
        type: "body",
        text: "Simple isn’t a substitute for medical advice or treatment from your doctor or healthcare professional.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 24,
          right: 24,
          top: 24,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [
        {
          variableName: "hasNotices",
          variableType: "boolean",
          condition: "==",
          value: true,
        },
      ],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
      style: "dark",
      statusBarStyle: "light",
    },
    bottomBlock: {
      submitButton: {
        id: "button",
        type: "main",
        text: "I agree",
        action: "next",
        style: {
          height: 56,
          textColor: {
            name: "textInverse",
            light: "#ffffff",
            dark: "#323653",
          },
          backgroundColor: {
            name: "fillMain",
            light: "#323653",
            dark: "#FFFFFF",
          },
          cornerRadius: 12,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
      },
    },
  },
  {
    id: "rrAskNftJwkTSWUkIKcGp",
    type: "webview",
    name: "OnboardingWeightPredictLoaderScreen",
    url: "https://onboarding-builder.pages.dev/webviews/prepare.html",
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      ignoreSafeArea: true,
      backgroundColor: {
        name: "custom",
        light: "#f5f6f8",
        dark: "#f5f6f8",
      },
    },
  },
  {
    id: "yqale4cJdSST7sftL6WqJ",
    type: "webview",
    name: "OnboardingProgramPrepareScreen",
    url: "https://onboarding-charts.pages.dev/chart-3",
    navigationParams: {
      canGoBack: false,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      ignoreSafeArea: true,
      backgroundColor: {
        name: "custom",
        light: "#f5f6f8",
        dark: "#f5f6f8",
      },
    },
  },
];

export const EXAMPLE_SCREENS_1: OnboardingScreen[] = [
  {
    id: "47JPoKXENZUQW03sCOVjI",
    type: "page",
    name: "BODY_ANALYSIS_SCREEN",
    components: [
      {
        id: "image",
        url: "https://content.cdn-simple-life.com/static/assessment/onboarding_body_analysis.png",
        width: "100%",
        fillMode: "cover",
        margins: {
          bottom: 64,
        },
      },
      {
        id: "text",
        type: "title2",
        text: "Your body analysis",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 22,
          fontWeight: 700,
          lineHeight: 26.8,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
      {
        id: "text",
        type: "body",
        text: "Get to know your unique body with some simple measurements. We’ll help you see how your individual body indicators relate to your custom health profile.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textSecondary",
            light: "#6A6D82",
            dark: "#8E90A1",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: false,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
    groupParams: {
      startGroup: {
        title: "BODY FAT PERCENTAGE",
        icon: {
          light:
            "https://onboarding-builder.pages.dev/assets/screen-groups/brain.svg",
          dark: "https://onboarding-builder.pages.dev/assets/screen-groups/brain-dark.svg",
        },
      },
      hideGroup: true,
    },
    bottomBlock: {
      submitButton: {
        id: "button",
        type: "main",
        text: "Next",
        action: "next",
        style: {
          height: 56,
          textColor: {
            name: "textInverse",
            light: "#ffffff",
            dark: "#323653",
          },
          backgroundColor: {
            name: "fillMain",
            light: "#323653",
            dark: "#FFFFFF",
          },
          cornerRadius: 12,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
      },
    },
  },
  {
    id: "o4OPemjMAjE7TVrMBnDPX",
    type: "page",
    name: "BODY_FAT_PERCENTAGE_SCREEN",
    components: [
      {
        id: "image",
        url: "https://content.cdn-simple-life.com/static/assessment/female_fat_percentage.png",
        width: "100%",
        fillMode: "contain",
        margins: {
          bottom: 32,
          left: 16,
          right: 16,
        },
      },
      {
        id: "text",
        type: "question",
        text: "What's your body fat percentage?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 12,
        },
      },
      {
        id: "text",
        type: "body",
        text: "Choose the picture that best represents your body fat percentage.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "8",
            value: "8",
          },
          {
            label: "10",
            value: "10",
          },
          {
            label: "15",
            value: "15",
          },
          {
            label: "20",
            value: "20",
          },
          {
            label: "25",
            value: "25",
          },
          {
            label: "30",
            value: "30",
          },
          {
            label: "35",
            value: "35",
          },
          {
            label: "40",
            value: "40",
          },
        ],
        variableName: "bodyFatPercentage",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
    groupParams: {
      endOfGroup: true,
    },
  },
  {
    id: "TvYxlyERzNkTivtSakYyw",
    type: "page",
    name: "HUNGRY_AFTER_MEAL_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "I often feel hungry 2-3 hours after a meal.",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Definitely true for me",
            value: "1",
          },
          {
            label: "Somewhat true for me",
            value: "2",
            shouldUnselectOthers: false,
          },
          {
            label: "Not true at all",
            value: "3",
          },
        ],
        variableName: "metabolicFlexibility1",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
        action: "next",
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
    groupParams: {
      startGroup: {
        title: "METABOLIC FLEXIBILITY",
        icon: {
          light:
            "https://onboarding-builder.pages.dev/assets/screen-groups/weight.svg",
          dark: "https://onboarding-builder.pages.dev/assets/screen-groups/weight-dark.svg",
        },
      },
    },
  },
  {
    id: "Tt-UR45QLt6hUPw8_o8b-",
    type: "page",
    name: "TROUBLES_SKIPPING_MEAL_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "I have trouble skipping meals; if I miss a meal, I feel irritable, shaky, or tired.",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Definitely true for me",
            value: "1",
          },
          {
            label: "Somewhat true for me",
            value: "2",
          },
          {
            label: "Not true at all",
            value: "3",
          },
        ],
        variableName: "metabolicFlexibility2",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "8ZFVe-qz5WIwrrSal-Q0t",
    type: "page",
    name: "SNACKING_A_LOT",
    components: [
      {
        id: "text",
        type: "question",
        text: "I find myself snacking a lot.",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Definitely true for me",
            value: "1",
          },
          {
            label: "Somewhat true for me",
            value: "2",
          },
          {
            label: "Not true at all",
            value: "3",
          },
        ],
        variableName: "metabolicFlexibilityr3",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "D7f-2a1NqhlulnzaCdJfN",
    type: "page",
    name: "CRAVINGS_FOR_SUGAR_OR_CARBS_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "I have frequent or constant cravings for sugar or carbs.",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Definitely true for me",
            value: "1",
          },
          {
            label: "Somewhat true for me",
            value: "2",
          },
          {
            label: "Not true at all",
            value: "3",
          },
        ],
        variableName: "metabolicFlexibility4",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "qR1ClnB2cO46a7xFC4b4W",
    type: "page",
    name: "EATING_BEFORE_BED_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "I often find myself hungry after dinner and eating before bed.",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Definitely true for me",
            value: "1",
          },
          {
            label: "Somewhat true for me",
            value: "2",
          },
          {
            label: "Not true at all",
            value: "3",
          },
        ],
        variableName: "metabolicFlexibility5",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
    groupParams: {
      endOfGroup: false,
    },
  },
  {
    id: "VJFjUR7i_3JTT8DpVs440",
    type: "page",
    name: "EAT_AFTER_ACTIVITY_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "After physical activity, I feel a strong desire to eat",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Definitely true for me",
            value: "1",
          },
          {
            label: "Somewhat true for me",
            value: "2",
          },
          {
            label: "Not true at all",
            value: "3",
          },
        ],
        variableName: "metabolicFlexibility6",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "8J5DNS2YvFFuHYBOv-rQ3",
    type: "page",
    name: "TIRED_AFTER_ACTIVITY_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "After physical activity, I get very tired and my mood worsens.",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Definitely true for me",
            value: "1",
          },
          {
            label: "Somewhat true for me",
            value: "2",
          },
          {
            label: "Not true at all",
            value: "3",
          },
        ],
        variableName: "metabolicFlexibility7",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "pXcVyni9ePwU1-9YbzW-3",
    type: "page",
    name: " EXERCISE_ON_EMPTY_STOMACH_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "It's hard for me to exercise on an empty stomach or 3-4 hours after a meal.",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Definitely true for me",
            value: "1",
          },
          {
            label: "Somewhat true for me",
            value: "2",
          },
          {
            label: "Not true at all",
            value: "3",
          },
        ],
        variableName: "metabolicFlexibility8",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "Jo8WP1VHFUE51F2eNUNDX",
    type: "page",
    name: "START_WORKING_WITHOUT_SNACK_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "It's hard for me to start working without a snack.",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Definitely true for me",
            value: "1",
          },
          {
            label: "Somewhat true for me",
            value: "2",
          },
          {
            label: "Not true at all",
            value: "3",
          },
        ],
        variableName: "metabolicFlexibility9",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "2eWx9EaN9yzKS8aKDX2XP",
    type: "page",
    name: "SLEEP_ON_EMPTY_STOMACH_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "I don't sleep well on an empty stomach.",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Definitely true for me",
            value: "1",
          },
          {
            label: "Somewhat true for me",
            value: "2",
          },
          {
            label: "Not true at all",
            value: "3",
          },
        ],
        variableName: "metabolicFlexibility10",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "sXailyOZOf7Igg6DPDmBB",
    type: "page",
    name: "NUTRITION_QUALITY_SCREEN",
    components: [
      {
        id: "image",
        url: "https://content.cdn-simple-life.com/static/assessment/onboarding_nutrition_quality.png",
        width: "100%",
        fillMode: "cover",
      },
      {
        id: "text",
        type: "title2",
        text: "Your nutrition quality",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 22,
          fontWeight: 700,
          lineHeight: 26.8,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 24,
          right: 24,
          top: 64,
        },
      },
      {
        id: "text",
        type: "body",
        text: "Tell us about what you normally eat. We won’t judge!",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textSecondary",
            light: "#6A6D82",
            dark: "#8E90A1",
          },
          textAlign: "left",
        },
        margins: {
          left: 24,
          right: 24,
          top: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: false,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      ignoreSafeArea: false,
    },
    bottomBlock: {
      submitButton: {
        id: "button",
        type: "main",
        text: "Next",
        action: "next",
        style: {
          height: 56,
          textColor: {
            name: "textInverse",
            light: "#ffffff",
            dark: "#323653",
          },
          backgroundColor: {
            name: "fillMain",
            light: "#323653",
            dark: "#FFFFFF",
          },
          cornerRadius: 12,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
      },
    },
    groupParams: {
      hideGroup: true,
      endOfGroup: true,
    },
  },
  {
    id: "SN95AOWql5bdV0R3EvQPH",
    type: "page",
    name: "REFINED_GRAIND_PRODUCTS_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "In an average week, how often do you usually eat refined grain products?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 12,
        },
      },
      {
        id: "text",
        type: "body",
        text: "E.g., white bread or bagels, refined breakfast cereals, white rice, pasta/noodles, crackers, etc.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Once or less",
            value: "0;3;3;0;0;0;0",
          },
          {
            label: "2-3 times",
            value: "1;2;0;0;2;0;0",
          },
          {
            label: "4-5 times",
            value: "2;1;0;0;0;1;0",
          },
          {
            label: "More than 5 times",
            value: "3;0;0;0;0;0;0",
          },
          {
            label: "I don't eat them",
            value: "4;3;0;3;0;0;0",
          },
        ],
        variableName: "dietQuality1",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
        action: "next",
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
    groupParams: {
      startGroup: {
        title: "DIET QUALITY",
        icon: {
          light:
            "https://onboarding-builder.pages.dev/assets/screen-groups/heart.svg",
          dark: "https://onboarding-builder.pages.dev/assets/screen-groups/heart-dark.svg",
        },
      },
    },
  },
  {
    id: "tZZt9RnnqR4gq4ofvRo6j",
    type: "page",
    name: "VEGETABLE_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "In an average week, how often do you usually eat dark green leafy vegetables?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 12,
        },
      },
      {
        id: "text",
        type: "body",
        text: "E.g.,  spinach, kale, Swiss chard, etc.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Once or less",
            value: "1;0;0;0;0;0;0",
          },
          {
            label: "2-3 times",
            value: "2;1;0;0;0;1;0",
          },
          {
            label: "4-5 times",
            value: "3;2;0;0;2;0;0",
          },
          {
            label: "More than 5 times",
            value: "4;3;3;3;0;0;0",
          },
          {
            label: "I don't eat them",
            value: "5;0;0;0;0;0;0",
          },
        ],
        variableName: "dietQuality2",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "odMo5YPzP4nX3c7dB6EIL",
    type: "page",
    name: "BEANS_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "In an average week, how often do you usually eat beans and legumes?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 12,
        },
      },
      {
        id: "text",
        type: "body",
        text: "E.g.,  chickpeas (garbanzo beans), black beans, lentils, etc.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Once or less",
            value: "1;0;0;0;0;0;0",
          },
          {
            label: "2-3 times",
            value: "2;1;0;0;1;1;0",
          },
          {
            label: "4-5 times",
            value: "3;2;0;0;0;0;0",
          },
          {
            label: "More than 5 times",
            value: "4;3;3;3;0;0;0",
          },
          {
            label: "I don't eat them",
            value: "5;0;0;0;0;0;0",
          },
        ],
        variableName: "dietQuality3",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "Sxrx8AvbbWA5wEkhLXd3H",
    type: "page",
    name: "NUTS_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "In an average week, how often do you usually eat nuts or seeds?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 12,
        },
      },
      {
        id: "text",
        type: "body",
        text: "E.g.,  walnuts, pecans, almonds, sesame or hemp seeds, etc.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 0,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Once or less",
            value: "1;0;0;0;0;0;0",
          },
          {
            label: "2-3 times",
            value: "2;1;0;0;0;1;1",
          },
          {
            label: "4-5 times",
            value: "3;2;0;0;2;0;0",
          },
          {
            label: "More than 5 times",
            value: "4;3;3;3;0;0;0",
          },
          {
            label: "I don't eat them",
            value: "5;0;0;0;0;0;0",
          },
        ],
        variableName: "dietQuality4",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "52OUKujQmOiTQu6mKjTRa",
    type: "page",
    name: "WHOLE_GRAINS_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "In an average week, how often do you usually eat whole grains?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 12,
        },
      },
      {
        id: "text",
        type: "body",
        text: "E.g.,  brown or wild rice, quinoa, steel-cut oats, buckwheat groats, etc.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Once or less",
            value: "1;0;0;0;0;0;0",
          },
          {
            label: "2-3 times",
            value: "2;1;0;0;0;1;0",
          },
          {
            label: "4-5 times",
            value: "3;2;0;0;2;0;0",
          },
          {
            label: "More than 5 times",
            value: "4;3;3;3;0;0;0",
          },
          {
            label: "I don't eat them",
            value: "5;0;0;0;0;0;0",
          },
        ],
        variableName: "dietQuality5",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "x7WYDZsa5uIILgSygjG7J",
    type: "page",
    name: "FAST_FOODS_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "In an average week, how often do you usually eat fried or highly-processed fast foods?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 12,
        },
      },
      {
        id: "text",
        type: "body",
        text: "E.g.,  fish and chips, chicken nuggets, fast-food pizza or burgers, etc",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Once or less",
            value: "1;3;3;0;0;0;0",
          },
          {
            label: "2-3 times",
            value: "2;2;0;0;2;2;0",
          },
          {
            label: "4-5 times",
            value: "3;1;0;0;0;0;0",
            shouldUnselectOthers: false,
          },
          {
            label: "More than 5 times",
            value: "4;0;0;0;0;0;0",
          },
          {
            label: "I don't eat them",
            value: "5;3;0;3;0;0;0",
          },
        ],
        variableName: "dietQuality6",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "2zwLKq0M3sJ4x7N9DsyGu",
    type: "page",
    name: "ADDED_SUGAR_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "In an average week, how often do you usually eat foods with added/refined sugar?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 12,
        },
      },
      {
        id: "text",
        type: "body",
        text: "E.g.,  cookies, cakes, desserts, pastries, ice cream, chocolate, candy, jams or jellies, etc.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Once or less",
            value: "1;3;3;3;0;0;0",
          },
          {
            label: "2-3 times",
            value: "2;2;0;0;0;0;0",
          },
          {
            label: "4-5 times",
            value: "3;1;0;0;1;1;1",
          },
          {
            label: "More than 5 times",
            value: "4;0;0;0;0;0;0",
          },
          {
            label: "I don't eat them",
            value: "5;3;0;0;0;0;0",
          },
        ],
        variableName: "dietQuality7",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "azQRFuoDYjbVoe1TmAsD8",
    type: "page",
    name: "REFINED_VEGETABLE_OILS_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "In an average week, how often do you usually cook with refined vegetable oils?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 12,
        },
      },
      {
        id: "text",
        type: "body",
        text: "E.g.,  cooking spray, sunflower oil, peanut oil, canola oil, etc.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 0,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Once or less",
            value: "1;3;3;0;0;0;0",
          },
          {
            label: "2-3 times",
            value: "2;2;0;0;2;0;0",
          },
          {
            label: "4-5 times",
            value: "3;1;0;0;0;1;1",
          },
          {
            label: "More than 5 times",
            value: "4;0;0;0;0;0;0",
          },
          {
            label: "I don’t use these",
            value: "5;3;0;3;0;0;0",
          },
        ],
        variableName: "dietQuality8",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "5U98pH8zhctLPpztyz0tg",
    type: "page",
    name: "HIGH_SALT_FOODS_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "In an average week, how often do you usually eat high-salt foods?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 12,
        },
      },
      {
        id: "text",
        type: "body",
        text: "E.g.,  processed meats, soy sauce, prepackaged foods, salty snack foods, lots of salt sprinkled on food, etc.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Once or less",
            value: "1;3;3;0;0;0;0",
          },
          {
            label: "2-3 times",
            value: "2;2;0;2;2;0;0",
          },
          {
            label: "4-5 times",
            value: "3;1;0;0;0;1;0",
          },
          {
            label: "More than 5 times",
            value: "4;0;0;0;0;0;0",
          },
          {
            label: "I don't eat them",
            value: "5;3;0;0;0;0;0",
          },
        ],
        variableName: "dietQuality9",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "ZdzwZgeaclVXPY4ODHX90",
    type: "page",
    name: "DRINK_ALCOHOL_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "In an average week, how often do you drink alcohol?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 12,
        },
      },
      {
        id: "text",
        type: "body",
        text: "E.g., wine, beer, cocktails",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Once or less",
            value: "1;3;3;3;0;0;0",
          },
          {
            label: "2-3 times",
            value: "2;2;0;0;2;2;0",
          },
          {
            label: "4-5 times",
            value: "3;1;0;0;0;0;0",
          },
          {
            label: "More than 5 times",
            value: "4;0;0;0;0;0;0",
          },
          {
            label: "I don’t drink alcohol",
            value: "5;3;0;0;0;0;0",
          },
        ],
        variableName: "dietQuality10",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
    groupParams: {
      endOfGroup: false,
    },
  },
  {
    id: "uD4vodB-sylp0uKClQD_7",
    type: "page",
    name: "COLORFUL_FRUITS_VEGETABLES_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "How many servings of colorful fruits and/or vegetables do you eat each day?",
        containsMarkdown: true,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 12,
        },
      },
      {
        id: "text",
        type: "body",
        text: "E.g.,  red peppers, purple cabbage, oranges, green beans, blueberries",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "1 or fewer",
            value: "1;0;0;0;0;0;0",
          },
          {
            label: "2-3",
            value: "2;1;0;0;1;1;0",
          },
          {
            label: "4-5",
            value: "3;2;0;0;0;0;0",
          },
          {
            label: "More than 5",
            value: "4;3;3;3;0;0;0",
          },
          {
            label: "I don't eat any",
            value: "5;0;0;0;0;0;0",
          },
        ],
        variableName: "dietQuality11",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
        action: "next",
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
    groupParams: {},
  },
  {
    id: "kFXH16CXQ4PEI7c6IjnZ7",
    type: "page",
    name: "HIGH_PROTEIN_FOODS_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "How many servings of high-protein foods do you eat each day?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 12,
        },
      },
      {
        id: "text",
        type: "body",
        text: "E.g.,  lean beef or pork, chicken, fish, seafood, eggs, tofu, tempeh, Greek yogurt, etc. A serving is about the size of your palm (4-5 oz).",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "1 or fewer",
            value: "1;0;0;0;0;0;0",
          },
          {
            label: "2-3",
            value: "2;1;0;0;1;1;0",
          },
          {
            label: "4-5",
            value: "3;2;0;2;0;0;0",
          },
          {
            label: "More than 5",
            value: "4;3;3;0;0;0;0",
          },
          {
            label: "I don't eat any",
            value: "5;0;0;0;0;0;0",
          },
        ],
        variableName: "dietQuality12",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "S-S9Oq0LkzYJGuR3tZCFT",
    type: "page",
    name: "BUTTER_OR_MARGARINE_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "How many servings of butter, margarine, or cream do you eat daily?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "1 or fewer",
            value: "1;3;3;0;0;0;0",
          },
          {
            label: "2-3",
            value: "2;2;0;0;2;2;2",
          },
          {
            label: "4-5",
            value: "3;1;0;0;0;0;0",
          },
          {
            label: "More than 5",
            value: "4;0;0;0;0;0;0",
          },
          {
            label: "I don’t eat these",
            value: "5;3;0;3;0;0;0",
          },
        ],
        variableName: "dietQuality13",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "JRYJDjHt4sdDT3-YSZ2Eq",
    type: "page",
    name: "HIGH_CALORIE_SAUCES_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "How many servings of high-calorie sauces do you add to your food each day?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 12,
        },
      },
      {
        id: "text",
        type: "body",
        text: "E.g.,  mayonnaise; prepackaged salad dressings; sweet BBQ, chili, or chutney sauces, etc. A serving is about 1 thumb-sized portion.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "1 or fewer",
            value: "1;3;3;0;0;0;0",
          },
          {
            label: "2-3",
            value: "2;2;0;0;2;2;2",
          },
          {
            label: "4-5",
            value: "3;1;0;0;0;0;0",
          },
          {
            label: "More than 5",
            value: "4;0;0;0;0;0;0",
          },
          {
            label: "I don’t eat them",
            value: "5;3;0;3;0;0;0",
          },
        ],
        variableName: "dietQuality14",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "NIT_XQLGSSJf6svBF210V",
    type: "page",
    name: "WATER_DRINK_DAILY_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "How many cups of water do you drink daily?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "1 or fewer",
            value: "1;0;0;0;0;0;0",
          },
          {
            label: "2-3",
            value: "2;1;0;0;0;1;1",
          },
          {
            label: "4-5",
            value: "3;2;0;0;2;0;0",
          },
          {
            label: "More than 5",
            value: "4;3;3;3;0;0;0",
          },
        ],
        variableName: "dietQuality15",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "_F7jnIgqbyWK_mABldDZw",
    type: "page",
    name: "SUGAR_SWEETENED_BEVERAGES_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "How many sugar-sweetened beverages do you drink daily?",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 12,
        },
      },
      {
        id: "text",
        type: "body",
        text: "E.g.,  soda, sweetened coffee or tea, sugar-sweetened juices, etc.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "1 or fewer",
            value: "1;3;3;0;0;0;0",
          },
          {
            label: "2-3",
            value: "2;2;0;0;2;2;2",
          },
          {
            label: "4-5",
            value: "3;1;0;0;0;0;0",
          },
          {
            label: "More than 5",
            value: "4;0;0;0;0;0;0",
          },
          {
            label: "I don’t drink them",
            value: "5;3;0;3;0;0;0",
          },
        ],
        variableName: "dietQuality16",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "ZFDh-nLhNRDH03hWzG6nq",
    type: "page",
    name: "EATING_STYLE_SCREEN",
    components: [
      {
        id: "image",
        url: "https://content.cdn-simple-life.com/static/assessment/onboarding_eating_style.png",
        width: "100%",
        fillMode: "cover",
        margins: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },
      {
        id: "text",
        type: "title2",
        text: "Your eating style",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 22,
          fontWeight: 700,
          lineHeight: 26.8,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 24,
          right: 24,
          top: 64,
        },
      },
      {
        id: "text",
        type: "body",
        text: "Good health isn’t just about what you eat — it’s about how you eat too. These questions will tell us about your daily-life eating behaviors.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textSecondary",
            light: "#6A6D82",
            dark: "#8E90A1",
          },
          textAlign: "left",
        },
        margins: {
          left: 24,
          right: 24,
          top: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: false,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {},
    groupParams: {
      endOfGroup: true,
      hideGroup: true,
    },
    bottomBlock: {
      submitButton: {
        id: "button",
        type: "main",
        text: "Next",
        action: "next",
        style: {
          height: 56,
          textColor: {
            name: "textInverse",
            light: "#ffffff",
            dark: "#323653",
          },
          backgroundColor: {
            name: "fillMain",
            light: "#323653",
            dark: "#FFFFFF",
          },
          cornerRadius: 12,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 600,
          lineHeight: 19,
        },
      },
    },
  },
  {
    id: "X8gZh_7cabxsoNd3QJray",
    type: "page",
    name: "OFTEN_WANT_TO_EAT_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "I almost always want to eat, even if I'm not hungry. ",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 12,
        },
      },
      {
        id: "text",
        type: "body",
        text: "E.g., if I smell or see something delicious.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Definitely true for me",
            value: "1;3;0;0",
          },
          {
            label: "Somewhat true for me",
            value: "2;2;0;0",
          },
          {
            label: "Not that much like me",
            value: "3;1;0;0",
          },
          {
            label: "Definitely not like me",
            value: "4;0;0;0",
          },
        ],
        variableName: "eatingStyle1",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
    groupParams: {
      startGroup: {
        title: "EATING STYLE",
        icon: {
          light:
            "https://onboarding-builder.pages.dev/assets/screen-groups/goal.svg",
          dark: "https://onboarding-builder.pages.dev/assets/screen-groups/goal-dark.svg",
        },
      },
    },
  },
  {
    id: "CkxLt6PlV6UuCCOqY8FXC",
    type: "page",
    name: "STRESS_THEN_EAT_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "When I feel worried or stressed, I find myself eating.",
        containsMarkdown: true,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Definitely true for me",
            value: "1;0;3;0",
          },
          {
            label: "Somewhat true for me",
            value: "2;0;2;0",
          },
          {
            label: "Not that much like me",
            value: "3;0;1;0",
          },
          {
            label: "Definitely not like me",
            value: "4;0;0;0",
          },
        ],
        variableName: "eatingStyle2",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "lBHOF_Ke4OssJ4WIILJQ-",
    type: "page",
    name: "EATING_WHEN_FULL_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "Sometimes when I start eating, I just can't seem to stop, even if I'm full.",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Definitely true for me",
            value: "1;3;0;0",
          },
          {
            label: "Somewhat true for me",
            value: "2;2;0;0",
          },
          {
            label: "Not that much like me",
            value: "3;1;0;0",
          },
          {
            label: "Definitely not like me",
            value: "4;0;0;0",
          },
        ],
        variableName: "eatingStyle3",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "W5l6_EtrF-OxDWPI_grn4",
    type: "page",
    name: "MEALS_AHEAD_TIME_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "I almost always plan my meals ahead of time.",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Definitely true for me",
            value: "1;0;0;3",
          },
          {
            label: "Somewhat true for me",
            value: "2;0;0;2",
          },
          {
            label: "Not that much like me",
            value: "3;0;0;1",
          },
          {
            label: "Definitely not like me",
            value: "4;0;0;0",
          },
        ],
        variableName: "eatingStyle4",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "QOMJdqKKKC0eaFU6ImM2K",
    type: "page",
    name: "EAT_WHEN_SOMEONE_EATING_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "If other people around me are eating, I almost always want to eat too.",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Definitely true for me",
            value: "1;3;0;0",
          },
          {
            label: "Somewhat true for me",
            value: "2;2;0;0",
          },
          {
            label: "Somewhat true for me",
            value: "3;1;0;0",
          },
          {
            label: "Definitely not like me",
            value: "4;0;0;0",
          },
        ],
        variableName: "eatingStyle5",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "tMvgZVP2QzqYeB8yOyGpT",
    type: "page",
    name: "FEEL_DOWN_OVERATING_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "When I feel down, I often overeat.",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 12,
        },
      },
      {
        id: "text",
        type: "body",
        text: "E.g., if I feel lonely or sad.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          top: 0,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Definitely true for me",
            value: "1;0;3;0",
          },
          {
            label: "Somewhat true for me",
            value: "2;0;2;0",
          },
          {
            label: "Not that much like me",
            value: "3;0;1;0",
          },
          {
            label: "Definitely not like me",
            value: "4;0;0;0",
          },
        ],
        variableName: "eatingStyle6",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "3kXbsekkuKN4wbDUl2LX2",
    type: "page",
    name: "SLOW_DOWN_SAVOR_MEAL_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "I almost always take time to slow down and savor a meal.",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Definitely true for me",
            value: "1;0;0;3",
          },
          {
            label: "Somewhat true for me",
            value: "2;0;0;2",
          },
          {
            label: "Not that much like me",
            value: "3;0;0;1",
          },
          {
            label: "Definitely not like me",
            value: "4;0;0;0",
          },
        ],
        variableName: "eatingStyle7",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "kHFeCnnZdBw4h0o-DycvO",
    type: "page",
    name: "PHYSICAL_HUNGRY_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "I often eat when I feel like I should, rather than when I am physically hungry. ",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 12,
        },
      },
      {
        id: "text",
        type: "body",
        text: "E.g., if it's lunchtime on the clock.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Definitely true for me",
            value: "1;3;0;0",
          },
          {
            label: "Somewhat true for me",
            value: "2;2;0;0",
          },
          {
            label: "Not that much like me",
            value: "3;1;0;0",
          },
          {
            label: "Definitely not like me",
            value: "4;0;0;0",
          },
        ],
        variableName: "eatingStyle8",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "qcAyPghdd_b9b6nA4010g",
    type: "page",
    name: "ALWAYS_INVOLVES_FOOD",
    components: [
      {
        id: "text",
        type: "question",
        text: "If I want to celebrate or reward myself, it always involves food.",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Definitely true for me",
            value: "1;0;3;0",
          },
          {
            label: "Somewhat true for me",
            value: "2;0;2;0",
          },
          {
            label: "Not that much like me",
            value: "3;0;1;0",
          },
          {
            label: "Definitely not like me",
            value: "4;0;0;0",
          },
        ],
        variableName: "eatingStyle9",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "S6tUIWBDsjAcm37lCfMqT",
    type: "page",
    name: "SOCIAL_EVENT_FOOD_SCREEN",
    components: [
      {
        id: "text",
        type: "question",
        text: "When I go to a social event where there's plenty of food, I almost always want to eat a lot.",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 12,
        },
      },
      {
        id: "text",
        type: "body",
        text: "E.g. parties, family gatherings, etc.",
        containsMarkdown: false,
        style: {
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Definitely true for me",
            value: "1;3;0;0",
          },
          {
            label: "Somewhat true for me",
            value: "2;2;0;0",
          },
          {
            label: "Not that much like me",
            value: "3;1;0;0",
          },
          {
            label: "Definitely not like me",
            value: "4;0;0;0",
          },
        ],
        variableName: "eatingStyle10",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
  },
  {
    id: "j3as7OwgM643rUVJZ973c",
    type: "page",
    name: "NOTICE_TASTES_AND_TEXTURES_EATING",
    components: [
      {
        id: "text",
        type: "question",
        text: "I always try to be present and notice the tastes and textures of what I'm eating.",
        containsMarkdown: false,
        style: {
          fontFamily: "Montserrat",
          fontSize: 18,
          fontWeight: 700,
          lineHeight: 22,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          textAlign: "left",
        },
        margins: {
          left: 16,
          right: 16,
          bottom: 32,
        },
      },
      {
        id: "listInput",
        type: "single",
        options: [
          {
            label: "Definitely true for me",
            value: "1;0;0;3",
          },
          {
            label: "Somewhat true for me",
            value: "2;0;0;2",
          },
          {
            label: "Not that much like me",
            value: "3;0;0;1",
          },
          {
            label: "Definitely not like me",
            value: "4;0;0;0",
          },
        ],
        variableName: "eatingStyle11",
        required: true,
        style: {},
        selectedOptionStyle: {
          backgroundColor: {
            name: "fillAnswered",
            light: "#E6F2EB",
            dark: "#253338",
          },
          borderColor: {
            name: "borderActive",
            light: "#56A97A",
            dark: "#56A97A",
          },
          borderWidth: 1,
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        optionStyle: {
          backgroundColor: {
            name: "bgContent",
            light: "#ffffff",
            dark: "#252736",
          },
          fontFamily: "SF Pro Text",
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 19,
          textColor: {
            name: "textPrimary",
            light: "#323653",
            dark: "#ffffff",
          },
          cornerRadius: 16,
        },
        margins: {
          left: 16,
          right: 16,
        },
      },
    ],
    navigationParams: {
      canGoBack: true,
      shouldBeSkippedWhenGoingBack: false,
      conditionsForShow: [],
    },
    appearance: {
      backgroundColor: {
        name: "bgBase",
        light: "#f5f6f8",
        dark: "#1c1e2c",
      },
    },
    groupParams: {
      endOfGroup: true,
    },
  },
];
