import { SCREEN_SIZE } from "src/config";
import { X } from "react-feather";
import { OnboardingScreen } from "src/types/screens";
import { TextInput } from "src/shared/components/TextInput";
import { ScreenNavParamsForm } from "../ScreenNavParamsForm";
import { useState } from "react";
import { AuthScreen } from "src/types/screens/AuthScreen";
import { AuthFormContent } from "./ui/AuthFormContent";

const TABS = ["Content", "Navigation"];

function AuthForm({
  screen,
  onClose,
  onChange,
}: {
  screen: AuthScreen | null;
  onClose: () => void;
  onChange: (screen: OnboardingScreen) => void;
}) {
  const [currentTab, setCurrentTab] = useState(TABS[0]);

  return (
    <div
      className={`${
        !!screen
          ? "opacity-100 delay-300 translate-x-0"
          : "opacity-0 -translate-x-8 pointer-events-none"
      } transition duration-300 relative container mx-auto px-6`}
    >
      <div className="flex w-full">
        <div
          style={{
            width: SCREEN_SIZE.width,
            height: SCREEN_SIZE.height,
          }}
          className="flex-shrink-0 mr-12"
        />
        <div className="bg-white w-full shadow-xl rounded-xl flex flex-col p-8 relative">
          <button
            onClick={() => onClose()}
            className="absolute w-12 h-12 right-4 top-4 flex items-center justify-center hover:scale-105"
          >
            <X size={24} className="text-gray-500" />
          </button>
          <h2 className="text-base font-semibold mb-4">ID {screen?.id}</h2>
          <hr className="mb-4" />

          <div className="flex">
            <div className="w-1/2">
              <TextInput
                label="Auth Screen Name"
                value={screen?.name ?? ""}
                onChange={(name) => {
                  if (!screen) return;
                  onChange({
                    ...screen,
                    name,
                  });
                }}
              />
            </div>
            <div className="w-1/2 pl-2">
              <p className="text-sm text-gray-500 mt-8">
                — using for Analytics (e.g. "{screen?.name ?? ""}Done")
              </p>
            </div>
          </div>

          <div className="border-b border-gray-200">
            <nav className="mt-4 -mb-px flex space-x-2">
              {TABS.map((tab) => (
                <button
                  key={tab}
                  onClick={() => setCurrentTab(tab)}
                  className={`${
                    tab === currentTab
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  } 
              whitespace-nowrap border-b-2 py-4 px-2 text-sm font-medium`}
                >
                  {tab}
                </button>
              ))}
            </nav>
          </div>

          {currentTab === "Content" && (
            <AuthFormContent
              screen={screen}
              onChange={(newScreen) => {
                if (!screen) return;
                onChange(newScreen);
              }}
            />
          )}

          {currentTab === "Navigation" && (
            <ScreenNavParamsForm
              screen={screen}
              onChange={(navigationParams) => {
                if (!screen) return;
                onChange({
                  ...screen,
                  navigationParams,
                });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export { AuthForm };
