import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  Compass,
  DollarSign,
  LogIn,
  PlusCircle,
  Smartphone,
} from "react-feather";

import { useAppStore } from "src/store/appStore";

import {
  GroupedScreen,
  OnboardingScreen,
  ScreenGroup,
} from "src/types/screens";

import popupIcon from "src/assets/popup-icon.svg";

import { SCREEN_SIZE } from "src/config";

import { ScreenView } from "./components/ScreenView";
import { ScreenForm } from "./components/ScreenForm";
import { PageScreen } from "src/types/screens/PageScreen";

export type ScreenGroupsInfo = {
  group: ScreenGroup;
  groupIndex: number;
  groupsCount: number;
  currentGroupStepsCount: number;
  currentGroupStep: number;
};

const SCREEN_ICONS = {
  page: <Smartphone className="text-black" size={24} />,
  modal: <img className="w-6 h-6" src={popupIcon} alt="Popup" />,
  paywall: <DollarSign className="text-black" size={24} />,
  webview: <Compass className="text-black" size={24} />,
  action: <PlusCircle className="text-black" size={24} />,
  auth: <LogIn className="text-black" size={24} />,
} as const;

const SCREEN_DESCRIPTIONS = {
  page: "Default page in onboarding flow",
  modal: "Popup or alert, shows above previous screen",
  paywall: "Paywall uses to show webview with payment form",
  webview: "Any custob webpage in onboarding flow",
  auth: "Authorization screen",
  action: "Action (not supported yet)",
} as const;

function screenIsGrouped(
  screen: OnboardingScreen
): screen is GroupedScreen & PageScreen {
  const groupScreenIds = ["page"];
  return groupScreenIds.includes(screen.type);
}

function App() {
  const [exportedJSON, showExportedJSON] = useState<string | null>(null);
  const {
    screens,
    updateScreen,
    addScreen,
    deleteScreen,
    moveScreen,
    cleanScreens,
  } = useAppStore();
  const screensScrollRef = useRef<HTMLDivElement>(null);
  const [selectedScreen, setSelectedScreen] = useState<OnboardingScreen | null>(
    null
  );
  const [addScreenAfterId, setAddScreenAfterId] = useState<string | null>();

  const chooseScreen = useCallback(
    (screen: OnboardingScreen) => {
      if (selectedScreen?.id === screen.id) {
        setSelectedScreen(null);
        return;
      }
      setSelectedScreen(screen);

      const foundIndex = screens.findIndex((s) => s.id === screen.id);

      setTimeout(() => {
        screensScrollRef.current?.scrollTo({
          left: SCREEN_SIZE.width * foundIndex * 0.4,
          behavior: "smooth",
        });
      }, 300);
    },
    [screens, selectedScreen]
  );

  const exportJSON = useCallback(() => {
    // Copy to clipboard
    showExportedJSON(
      JSON.stringify({ id: "onboarding_v4", screens, canClose: true }, null, 2)
    );
  }, [screens]);

  const screenGroupsRanges: number[][] = useMemo(() => {
    const groups: number[][] = [];
    screens.forEach((screen, index) => {
      if (!screenIsGrouped(screen)) {
        return;
      }

      if (screen.groupParams?.endOfGroup) {
        const lastGroup = groups[groups.length - 1];
        if (lastGroup) {
          lastGroup[1] = index;
        } else {
          console.error("Error screen group end at index", index);
        }
      }

      if (!!screen.groupParams?.startGroup) {
        groups.push([index, screens.length - 1]);
      }
    });
    return groups;
  }, [screens]);

  return (
    <>
      <div className="hidden bg-slate-100"></div>
      <div className="container mx-auto px-6 my-12 relative">
        <div className="flex w-full">
          <h1 className="text-2xl font-semibold">Onboarding Builder</h1>
          <button
            onClick={() => {
              if (window.confirm("Are you sure? It will delete all screens")) {
                cleanScreens();
              }
            }}
            className="ml-auto text-xs px-4 py-2 bg-indigo-100 rounded-full text-indigo-600"
          >
            New onboarding
          </button>
          <button
            onClick={() => exportJSON()}
            className="ml-4 text-xs px-4 py-2 bg-indigo-600 rounded-full text-white"
          >
            Export JSON
          </button>
        </div>
        <hr className="my-8" />
      </div>

      <div
        ref={screensScrollRef}
        className="flex absolute w-full px-20 pb-12 overflow-auto"
      >
        <div className="flex pt-12">
          {screens.map((screen, index) => {
            const groupIndex = screenGroupsRanges.findIndex(
              ([start, end]) => index >= start && index <= end
            );
            const groupInitiatorIndex = screenGroupsRanges.find(
              ([start, end]) => index >= start && index <= end
            );
            const screenGroup = groupInitiatorIndex
              ? (screens[groupInitiatorIndex[0]] as GroupedScreen).groupParams
                  ?.startGroup
              : undefined;

            const groupsInfo: ScreenGroupsInfo | undefined = screenGroup
              ? {
                  group: screenGroup,
                  groupIndex,
                  groupsCount: screenGroupsRanges.length,
                  currentGroupStep: index - groupInitiatorIndex![0] + 1,
                  currentGroupStepsCount:
                    groupInitiatorIndex![1] - groupInitiatorIndex![0] + 1,
                }
              : undefined;

            return (
              <ScreenView
                groupsInfo={groupsInfo}
                key={`ScreenView_${screen.id}`}
                screen={screen}
                selectedScreen={selectedScreen}
                onChooseScreen={(screen) => chooseScreen(screen)}
                onDeleteScreen={(screenId) => deleteScreen(screenId)}
                onMoveScreen={(screenId, direction) =>
                  moveScreen(screenId, direction)
                }
                onAddAfterScreen={setAddScreenAfterId}
              />
            );
          })}
          {screens.length === 0 && (
            <button
              onClick={() => addScreen("page")}
              style={{
                width: SCREEN_SIZE.width,
                height: SCREEN_SIZE.height,
                marginRight: -SCREEN_SIZE.width * 0.3,
                marginLeft: -SCREEN_SIZE.width * 0.3,
              }}
              className={`bg-white scale-30 group shadow-xl rounded-2xl transform origin-top flex items-center justify-center`}
            >
              <PlusCircle size={96} className="text-gray-500" />
            </button>
          )}
          <div className="w-screen"></div>
        </div>
      </div>

      {selectedScreen && (
        <ScreenForm
          selectedScreen={selectedScreen}
          setSelectedScreen={(screen) => setSelectedScreen(screen)}
          updateScreen={(screen) => updateScreen(screen)}
        />
      )}

      {addScreenAfterId && (
        <div className="fixed w-full h-full left-0 top-0 flex items-center justify-center">
          <button
            onClick={() => setAddScreenAfterId(null)}
            className="absolute w-full h-full bg-black/30"
          ></button>
          <div className="text-xs relative w-full max-w-lg bg-white p-6 rounded-xl">
            <h3 className="text-lg text-center font-semibold mb-4">
              Add new screen
            </h3>
            <div className="flex flex-col">
              {["page", "modal", "webview", "paywall", "auth"].map(
                (screenType) => {
                  const screenTypeUnwrapped =
                    screenType as OnboardingScreen["type"];
                  return (
                    <button
                      key={`add_${screenTypeUnwrapped}`}
                      onClick={() => {
                        addScreen(screenTypeUnwrapped, addScreenAfterId);
                        setAddScreenAfterId(null);
                      }}
                      className="hover:text-indigo-600 flex items-center text-xs w-full border-t py-4"
                    >
                      {SCREEN_ICONS[screenTypeUnwrapped] ?? null}
                      <div className="ml-4 flex flex-col items-start">
                        <p className="font-semibold tracking-wider uppercase">
                          {screenType}
                        </p>
                        <p className="text-xs text-center mt-1">
                          {SCREEN_DESCRIPTIONS[screenTypeUnwrapped]}
                        </p>
                      </div>
                    </button>
                  );
                }
              )}
            </div>
          </div>
        </div>
      )}

      {exportedJSON !== null && (
        <div className="fixed w-full h-full left-0 top-0 flex items-center justify-center">
          <button
            onClick={() => showExportedJSON(null)}
            className="absolute w-full h-full bg-black/30"
          ></button>
          <div className="text-xs relative w-full max-w-lg bg-white p-6 rounded-xl">
            <h3 className="text-lg font-semibold mb-4">Exported JSON</h3>
            <textarea
              style={{ fontFamily: "monospace" }}
              value={exportedJSON}
              readOnly
              className="text-xs aspect-square w-full bg-slate-800 rounded-md border-0 text-yellow-100"
            />
          </div>
        </div>
      )}
    </>
  );
}

export default App;
