/**
 * NOTE: Implemented as separate component in order to:
 * - Use as select from dictionary with allowed fields
 * - Use between whole admin app
 */
import { Save } from "react-feather";
import { Checkbox } from "src/shared/components/Checkbox";
import { TextInput } from "src/shared/components/TextInput";
import { InputComponent } from "src/types/components";

function BindedDataInput({
  component,
  onChange,
}: {
  component: InputComponent;
  onChange: (newComponent: InputComponent) => void;
}) {
  return (
    <div className="flex space-x-2 w-full">
      <div className="w-1/2 relative">
        <Save size={14} className="absolute ml-2 mt-8 top-1 left-0.5" />
        <TextInput
          label="Store data in variable"
          className="pl-8"
          value={component.variableName ?? ""}
          onChange={(value) => {
            onChange({
              ...component,
              variableName: value ?? "",
            });
          }}
        />
      </div>

      <div className="w-1/2 pt-8 pl-1">
        <Checkbox
          label="Required"
          checked={!!component.required}
          onChange={(value) => {
            onChange({
              ...component,
              required: value,
            });
          }}
        />
      </div>
    </div>
  );
}

export { BindedDataInput };
