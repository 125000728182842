import { SpacedComponent } from "src/types/components";

type MiniNumberInputProps = {
  value: number;
  onChange: (newValue: number) => void;
  className?: string;
};

function MiniNumberInput({ value, onChange, className }: MiniNumberInputProps) {
  return (
    <input
      value={value === 0 ? "" : value}
      type="text"
      onChange={(e) =>
        onChange(isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value))
      }
      className={`${className} absolute block w-8 h-7 text-center rounded-md border-gray-300 py-1 px-1 text-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500`}
    />
  );
}

function SpacedComponentForm({
  component,
  onChange,
  children,
}: {
  component: SpacedComponent;
  onChange: (component: SpacedComponent["margins"]) => void;
  children: React.ReactNode;
}) {
  return (
    <div className="w-28 h-28 bg-slate-100 rounded-md flex-shrink-0 flex items-center justify-center relative">
      <MiniNumberInput
        className="top-2"
        value={component.margins?.top ?? 0}
        onChange={(value) => {
          onChange({
            ...component.margins,
            top: value,
          });
        }}
      />
      <MiniNumberInput
        className="left-2 top-1/2 transform -translate-y-1/2"
        value={component.margins?.left ?? 0}
        onChange={(value) => {
          onChange({
            ...component.margins,
            left: value,
          });
        }}
      />
      <MiniNumberInput
        className="right-2 top-1/2 transform -translate-y-1/2"
        value={component.margins?.right ?? 0}
        onChange={(value) => {
          onChange({
            ...component.margins,
            right: value,
          });
        }}
      />
      <MiniNumberInput
        className="bottom-2"
        value={component.margins?.bottom ?? 0}
        onChange={(value) => {
          onChange({
            ...component.margins,
            bottom: value,
          });
        }}
      />
      {children}
    </div>
  );
}

export { SpacedComponentForm };
