import { PaletteColor, Theme } from ".";

const COLORS = {
  // Background
  bgBase: {
    light: "#f5f6f8",
    dark: "#1c1e2c",
  },
  bgContent: {
    light: "#ffffff",
    dark: "#252736",
  },
  bgIntermission: {
    light: "#282A3A",
    dark: "#0C0E1B",
  },
  bgOverlay: {
    light: "#00000080",
    dark: "#00000080",
  },
  // Text
  textPrimary: {
    light: "#323653",
    dark: "#ffffff",
  },
  textInverse: {
    light: "#ffffff",
    dark: "#323653",
  },
  textOnWhite: {
    light: "#323653",
    dark: "#323653",
  },
  textOnColor: {
    light: "#ffffff",
    dark: "#ffffff",
  },
  textAccent: {
    light: "#7D8BF7",
    dark: "#8795FC",
  },
  textSecondary: {
    light: "#6A6D82",
    dark: "#8E90A1",
  },
  textOffload: {
    light: "#9396A5",
    dark: "#8D8E95",
  },
  // Border
  borderDivider: {
    light: "#E2E3EE",
    dark: "#353745",
  },
  borderActive: {
    light: "#56A97A",
    dark: "#56A97A",
  },
  borderCheckmark: {
    light: "#ffffff",
    dark: "#ffffff",
  },
  borderLoader: {
    light: "#c8cbdd",
    dark: "#8e90a1",
  },
  // Fill
  fillMain: {
    light: "#323653",
    dark: "#FFFFFF",
  },
  fillInactive: {
    light: "#F5F6F8",
    dark: "#595B6E",
  },
  fillBackButton: {
    light: "#E3E4EC",
    dark: "#242632",
  },
  fillAccent: {
    light: "#7D8BF7",
    dark: "#8795FC",
  },
  fillAccentGreen: {
    light: "#56A97A",
    dark: "#56A97A",
  },
  fillAnswered: {
    light: "#E6F2EB",
    dark: "#253338",
  },
  fillPicker: {
    light: "#E9EAF2",
    dark: "#2F3142",
  },
  fillFootnote: {
    light: "#E7EAF9",
    dark: "#3A3D56",
  },
  fillProgressBar: {
    light: "#56A97A33",
    dark: "#56A97A33",
  },
  // Extensions
  extBlue100: {
    light: "#A0ABFF",
    dark: "#A0ABFF",
  },
  extBlue20: {
    light: "#A0ABFF",
    dark: "#A0ABFF",
  },
  extBlue0: {
    light: "#4E5378",
    dark: "#3C4164",
  },
  extFuchsia100: {
    light: "#F886AF",
    dark: "#F886AF",
  },
  extFuchsia20: {
    light: "#F886AF",
    dark: "#F886AF",
  },
  extFuchsia0: {
    light: "#6A475F",
    dark: "#57354B",
  },
  extEmerald100: {
    light: "#7FD0A2",
    dark: "#7FD0A2",
  },
  extEmerald20: {
    light: "#7FD0A2",
    dark: "#7FD0A2",
  },
  extEmerald0: {
    light: "#445E5B",
    dark: "#314C47",
  },
  extLilac100: {
    light: "#D8ADED",
    dark: "#D8ADED",
  },
  extLilac20: {
    light: "#D8ADED",
    dark: "#D8ADED",
  },
  extLilac0: {
    light: "#5D4F70",
    dark: "#4B3E5C",
  },
  extPear100: {
    light: "#A8D287",
    dark: "#A8D287",
  },
  extPear20: {
    light: "#A8D287",
    dark: "#A8D287",
  },
  extPear0: {
    light: "#515F52",
    dark: "#3E4D3E",
  },
  extAmber100: {
    light: "#F8C780",
    dark: "#F8C780",
  },
  extAmber20: {
    light: "#F8C780",
    dark: "#F8C780",
  },
  extAmber0: {
    light: "#6A5C50",
    dark: "#58493C",
  },
};

const themedColors: Record<string, PaletteColor> = {};
Object.entries(COLORS).forEach(([key, value]) => {
  const color: PaletteColor = {
    name: key,
    light: value.light,
    dark: value.dark,
  };
  themedColors[key] = color;
});

export const iOS_THEME: Theme = {
  colors: themedColors,
  screens: {
    modal: {
      style: {
        cornerRadius: 16,
        backgroundColor: themedColors.bgContent,
      },
      statusBarStyle: "light",
      overlayColor: themedColors.bgOverlay,
    },
  },
  components: {
    text: {
      title1: {
        fontFamily: "Montserrat",
        fontSize: 32,
        fontWeight: 800,
        lineHeight: 39,
        letterSpacing: -0.4,
        textColor: themedColors.textPrimary,
        textAlign: "left",
      },
      title2: {
        fontFamily: "Montserrat",
        fontSize: 22,
        fontWeight: 700,
        lineHeight: 26.8,
        textColor: themedColors.textPrimary,
        textAlign: "left",
      },
      question: {
        fontFamily: "Montserrat",
        fontSize: 18,
        fontWeight: 700,
        lineHeight: 22,
        textColor: themedColors.textPrimary,
        textAlign: "left",
      },
      body: {
        fontFamily: "SF Pro Text",
        fontSize: 16,
        fontWeight: 400,
        lineHeight: 19,
        textColor: themedColors.textPrimary,
        textAlign: "left",
      },
      answers: {
        fontFamily: "SF Pro Text",
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 19,
        textColor: themedColors.textPrimary,
        textAlign: "left",
      },
      answersOn: {
        fontFamily: "SF Pro Text",
        fontSize: 16,
        fontWeight: 600,
        lineHeight: 19,
        textColor: themedColors.textPrimary,
        textAlign: "left",
      },
      small: {
        fontFamily: "SF Pro Text",
        fontSize: 13,
        fontWeight: 400,
        lineHeight: 15.5,
        textColor: themedColors.textPrimary,
        textAlign: "left",
      },
    },
    divider: {
      style: {
        height: 1,
        backgroundColor: themedColors.borderDivider,
      },
    },
    button: {
      main: {
        height: 56,
        textColor: themedColors.textInverse,
        backgroundColor: themedColors.fillMain,
        cornerRadius: 12,
        fontFamily: "SF Pro Text",
        fontSize: 16,
        fontWeight: 600,
        lineHeight: 19,
      },
      secondary: {
        fontFamily: "SF Pro Text",
        fontSize: 14,
        fontWeight: 700,
        lineHeight: 17,
        textAlign: "center",
        textColor: themedColors.textSecondary,
      },
      modalMain: {
        height: 56,
        textColor: themedColors.textAccent,
        fontFamily: "SF Pro Text",
        fontSize: 16,
        fontWeight: 600,
        lineHeight: 19,
      },
      modalSecondary: {
        height: 56,
        textColor: themedColors.textPrimary,
        fontFamily: "SF Pro Text",
        fontSize: 16,
        fontWeight: 600,
        lineHeight: 19,
      },
    },
    listInput: {
      list: {},
      selectedOption: {
        backgroundColor: themedColors.fillAnswered,
        borderColor: themedColors.borderActive,
        borderWidth: 1,
        fontFamily: "SF Pro Text",
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 19,
        textColor: themedColors.textPrimary,
        cornerRadius: 16,
      },
      option: {
        backgroundColor: themedColors.bgContent,
        fontFamily: "SF Pro Text",
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 19,
        textColor: themedColors.textPrimary,
        cornerRadius: 16,
      },
    },
    checkbox: {
      cornerRadius: 6,
      backgroundColor: themedColors.fillAccent,
      borderColor: themedColors.textSecondary,
      fontFamily: "SF Pro Text",
      fontSize: 13,
      fontWeight: 400,
      lineHeight: 15.5,
      textColor: themedColors.textPrimary,
      borderWidth: 1,
    },
    textInput: {
      style: {
        borderColor: themedColors.borderDivider,
        borderWidth: 1,
        fontFamily: "Montserrat",
        fontSize: 32,
        fontWeight: 300,
        lineHeight: 39,
        letterSpacing: -0.4,
        textColor: themedColors.textPrimary,
      },
      paddings: {
        bottom: 12,
      },
    },
    dateInput: {
      style: {
        borderColor: themedColors.borderDivider,
        borderWidth: 1,
        fontFamily: "Montserrat",
        fontSize: 40,
        fontWeight: 600,
        lineHeight: 48,
        textColor: themedColors.textPrimary,
        textAlign: "center",
      },
      paddings: {
        bottom: 12,
      },
    },
    numberInput: {
      style: {
        backgroundColor: themedColors.fillPicker,
        textColor: themedColors.textPrimary,
        cornerRadius: 12,
        fontFamily: "SF Pro Text",
        fontWeight: 600,
        lineHeight: 48,
        fontSize: 40,
      },
      paddings: {
        top: 8,
        bottom: 8,
      },
    },
  },
};
