import { OnboardingScreen } from "src/types/screens";
import { ModalScreen } from "src/types/screens/ModalScreen";
import { PageScreen } from "src/types/screens/PageScreen";
import { PaywallScreen } from "src/types/screens/PaywallScreen";
import { WebViewScreen } from "src/types/screens/WebViewScreen";
import { ModalForm } from "./ui/ModalForm";
import { PageScreenForm } from "./ui/PageForm";
import { PaywallForm } from "./ui/PaywallForm";
import { WebViewForm } from "./ui/WebViewForm";
import { AuthScreen } from "src/types/screens/AuthScreen";
import { AuthForm } from "./ui/AuthForm";

function ScreenForm({
  selectedScreen,
  setSelectedScreen,
  updateScreen,
}: {
  selectedScreen: OnboardingScreen;
  setSelectedScreen: (screen: OnboardingScreen | null) => void;
  updateScreen: (screen: OnboardingScreen) => void;
}) {
  switch (selectedScreen.type) {
    case "page":
      return (
        <PageScreenForm
          screen={selectedScreen as PageScreen}
          onChange={(screen) => {
            if (!selectedScreen) return;
            setSelectedScreen(screen);
            updateScreen(screen);
          }}
          onClose={() => setSelectedScreen(null)}
        />
      );
    case "webview":
      return (
        <WebViewForm
          screen={selectedScreen as WebViewScreen}
          onChange={(screen) => {
            if (!selectedScreen) return;
            setSelectedScreen(screen);
            updateScreen(screen);
          }}
          onClose={() => setSelectedScreen(null)}
        />
      );
    case "paywall":
      return (
        <PaywallForm
          screen={selectedScreen as PaywallScreen}
          onChange={(screen) => {
            if (!selectedScreen) return;
            setSelectedScreen(screen);
            updateScreen(screen);
          }}
          onClose={() => setSelectedScreen(null)}
        />
      );
    case "modal":
      return (
        <ModalForm
          screen={selectedScreen as ModalScreen}
          onChange={(screen) => {
            if (!selectedScreen) return;
            setSelectedScreen(screen);
            updateScreen(screen);
          }}
          onClose={() => setSelectedScreen(null)}
        />
      );
    case "auth":
      return (
        <AuthForm
          screen={selectedScreen as AuthScreen}
          onChange={(screen) => {
            if (!selectedScreen) return;
            setSelectedScreen(screen);
            updateScreen(screen);
          }}
          onClose={() => setSelectedScreen(null)}
        />
      );
    default:
      return null;
  }
}

export { ScreenForm };
