import { SpacedComponent, StyledComponent } from "src/types/components";
import { ButtonType } from "src/types/components/ButtonComponent";
import { TextType } from "src/types/components/TextComponent";
import { ModalScreen } from "src/types/screens/ModalScreen";
import { iOS_THEME } from "./ios";

export type PaletteColor = {
  name: string | "custom"; // "custom" is used for non-themed colors
  light: string;
  dark: string;
};

export type Theme = {
  colors: Record<string, PaletteColor>;
  screens: {
    modal: ModalScreen["appearance"] & StyledComponent;
  };
  components: {
    text: Record<TextType, StyledComponent["style"]>;
    listInput: {
      list: StyledComponent["style"];
      selectedOption: StyledComponent["style"];
      option: StyledComponent["style"];
    };
    button: Record<ButtonType, StyledComponent["style"]>;
    checkbox: StyledComponent["style"];
    textInput: {
      style: StyledComponent["style"];
      paddings: SpacedComponent["paddings"];
    };
    dateInput: {
      style: StyledComponent["style"];
      paddings: SpacedComponent["paddings"];
    };
    numberInput: {
      style: StyledComponent["style"];
      paddings: SpacedComponent["paddings"];
    };
    divider: {
      style: StyledComponent["style"];
    };
  };
};

export const CURRENT_THEME: Theme = iOS_THEME;
