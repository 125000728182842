import { Type } from "react-feather";
import { Checkbox } from "src/shared/components/Checkbox";
import { ColorPicker } from "src/shared/components/ColorPicker";
import { Select } from "src/shared/components/Select";
import { TextInput } from "src/shared/components/TextInput";
import { CURRENT_THEME } from "src/shared/themes";
import { TextComponent, TEXT_TYPES } from "src/types/components/TextComponent";
import { ComponentFormItem, ComponentFormProps } from "..";
import { SpacedComponentForm } from "./SpacedComponentForm";

function TextComponentForm(props: ComponentFormProps) {
  const component = props.component as TextComponent;

  return (
    <ComponentFormItem onDelete={props.onDelete}>
      <div className="flex space-x-4 w-full">
        <SpacedComponentForm
          component={component}
          onChange={(margins) =>
            props.onChange({
              ...component,
              margins,
            })
          }
        >
          <Type />
        </SpacedComponentForm>
        <div className="flex flex-col w-full pr-8">
          <div className="flex w-full mb-3">
            <div className="w-1/3">
              <div className="w-full flex space-x-4">
                <div className="w-1/2">
                  <Select
                    label="Text type"
                    value={component.type}
                    onChange={(value) => {
                      props.onChange({
                        ...component,
                        type: (value as TextComponent["type"]) ?? TEXT_TYPES[0],
                        style:
                          CURRENT_THEME.components.text[
                            value as TextComponent["type"]
                          ],
                      });
                    }}
                    options={TEXT_TYPES.map((type) => ({
                      label: type,
                      value: type,
                    }))}
                  />
                </div>
                <div className="w-1/2">
                  <Select
                    label="Alignment"
                    value={component.style?.textAlign ?? "left"}
                    onChange={(value) => {
                      props.onChange({
                        ...component,
                        style: {
                          ...component.style,
                          textAlign: value,
                        },
                      });
                    }}
                    options={["left", "center", "right"].map((type) => ({
                      label: type,
                      value: type,
                    }))}
                  />
                </div>
              </div>
            </div>
            <div className="w-1/3 pl-4">
              <TextInput
                label="Text"
                value={component.text ?? ""}
                onChange={(value) => {
                  props.onChange({
                    ...component,
                    text: value ?? "",
                  });
                }}
              />
            </div>
            <div className="w-1/3 pl-4">
              <ColorPicker
                label="Color"
                value={component.style?.textColor?.name}
                onChange={(value) => {
                  props.onChange({
                    ...component,
                    style: {
                      ...component.style,
                      textColor: value
                        ? CURRENT_THEME.colors[value]
                        : undefined,
                    },
                  });
                }}
              />
            </div>
          </div>
          <Checkbox
            label="Contains Markdown"
            checked={!!component.containsMarkdown}
            onChange={(value) => {
              props.onChange({
                ...component,
                containsMarkdown: value,
              });
            }}
          />
        </div>
      </div>
    </ComponentFormItem>
  );
}

export { TextComponentForm };
