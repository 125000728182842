import { Checkbox } from "src/shared/components/Checkbox";
import { ColorPicker } from "src/shared/components/ColorPicker";
import { Select } from "src/shared/components/Select";
import { CURRENT_THEME } from "src/shared/themes";
import { PageScreen } from "src/types/screens/PageScreen";

function PageScreenAppearanceForm({
  screen,
  onChange,
}: {
  screen: PageScreen | null;
  onChange: (appearance: PageScreen["appearance"]) => void;
}) {
  if (!screen) return null;

  return (
    <div className="bg-slate-100 p-6 flex flex-col w-full rounded-b-xl space-y-4">
      <p className="text-xs font-semibold uppercase tracking-wider">
        Appearance
      </p>
      <div className="flex flex-wrap">
        <div className="w-1/3">
          <Select
            value={screen.appearance?.style}
            onChange={(style) => {
              onChange({
                ...screen.appearance,
                style,
              });
            }}
            label="Screen Style"
            options={[
              { label: "Auto", value: "undefined" },
              { label: "Light", value: "light" },
              { label: "Dark", value: "dark" },
            ]}
          />
        </div>

        <div className="w-1/3 pl-4">
          <Select
            value={screen.appearance?.statusBarStyle}
            onChange={(statusBarStyle) => {
              onChange({
                ...screen.appearance,
                statusBarStyle,
              });
            }}
            label="Status Bar Style"
            options={[
              { label: "Auto", value: "undefined" },
              { label: "Light", value: "light" },
              { label: "Dark", value: "dark" },
            ]}
          />
        </div>

        <div className="w-1/3 pl-4">
          <ColorPicker
            value={screen.appearance?.backgroundColor?.name}
            onChange={(backgroundColorKey) => {
              onChange({
                ...screen.appearance,
                backgroundColor: backgroundColorKey
                  ? CURRENT_THEME.colors[backgroundColorKey]
                  : undefined,
              });
            }}
            label="Background Color"
          />
        </div>

        <div className="w-1/2 mt-4">
          <Checkbox
            checked={!!screen.appearance?.ignoreSafeArea}
            onChange={(ignoreSafeArea) => {
              onChange({
                ...screen.appearance,
                ignoreSafeArea,
              });
            }}
            label="Ignore Safe Area"
          />
        </div>
      </div>
    </div>
  );
}

export { PageScreenAppearanceForm };
