import { getStyles } from "src/shared/helpers/styleConverter";
import { AppearanceType } from "src/types";
import { TextComponent } from "src/types/components/TextComponent";

function TextComponentView({
  component,
  appearance,
}: {
  component: TextComponent;
  appearance: AppearanceType;
}) {
  const appearanceKey = appearance === "dark" ? "dark" : "light";

  return (
    <p
      style={getStyles({ style: component.style, appearance: appearanceKey })}
      className="w-full"
    >
      {component.text}
    </p>
  );
}

export { TextComponentView };
