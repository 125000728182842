import { CheckSquare } from "react-feather";
import { Checkbox } from "src/shared/components/Checkbox";
import { TextInput } from "src/shared/components/TextInput";
import { CheckboxComponent } from "src/types/components/CheckboxComponent";
import { ComponentFormItem, ComponentFormProps } from "..";
import { BindedDataInput } from "./BindedDataInput";
import { SpacedComponentForm } from "./SpacedComponentForm";

function CheckboxComponentForm(props: ComponentFormProps) {
  const component = props.component as CheckboxComponent;

  return (
    <ComponentFormItem onDelete={props.onDelete}>
      <div className="flex space-x-4 w-full">
        <SpacedComponentForm
          component={component}
          onChange={(margins) =>
            props.onChange({
              ...component,
              margins,
            })
          }
        >
          <CheckSquare />
        </SpacedComponentForm>
        <div className="flex flex-col w-full pr-8">
          <div className="flex w-full mb-3">
            <div className="w-2/3">
              <TextInput
                label="Checkbox label"
                value={component.label ?? ""}
                onChange={(value) => {
                  props.onChange({
                    ...component,
                    label: value ?? "",
                  });
                }}
              />
            </div>
            <div className="w-1/3 pl-4 pt-8">
              <Checkbox
                label="Contains Markdown"
                checked={component.containsMarkdown}
                onChange={(containsMarkdown) => {
                  props.onChange({
                    ...component,
                    containsMarkdown,
                  });
                }}
              />
            </div>
          </div>
          <div className="w-full">
            <BindedDataInput
              component={{
                variableName: component.variableName,
                required: component.required,
              }}
              onChange={(updates) =>
                props.onChange({ ...component, ...updates })
              }
            />
          </div>
        </div>
      </div>
    </ComponentFormItem>
  );
}

export { CheckboxComponentForm };
