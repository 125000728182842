import { useRef } from "react";

type TextInputProps = {
  value: string;
  label: string;
  onChange: (newValue: string) => void;
  onBlur?: (newValue: string) => void;
  className?: string;
};

function TextInput({
  value,
  onChange,
  onBlur,
  label,
  className,
}: TextInputProps) {
  const currentValue = useRef(value);

  const onChangeHandler = (e: any) => {
    currentValue.current = e.target.value;
    onChange(e.target.value);
  };

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <input
        value={value}
        onBlur={() => {
          if (onBlur) {
            onBlur(currentValue.current);
          }
        }}
        type="text"
        onChange={onChangeHandler}
        className={`${className} mt-1 block w-full rounded-md border-gray-300 py-2 px-3 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
      />
    </div>
  );
}

export { TextInput };
