import { useMemo } from "react";
import { AddButton } from "src/shared/components/AddButton";
import { Checkbox } from "src/shared/components/Checkbox";
import {
  CONDITIONS,
  NavigationParams,
  OnboardingScreen,
  VAR_TYPES,
} from "src/types/screens";
import { useAppStore } from "src/store/appStore";
import { OnboardingComponent } from "src/types";
import { InputComponent } from "src/types/components";
import { Select } from "src/shared/components/Select";
import { TextInput } from "src/shared/components/TextInput";
import { Trash2 } from "react-feather";
import { uniq } from "lodash";

const CALCULATED_VARS = [
  "age",
  "bodyMassIndex",
  "targetBodyMassIndex",
  "isGoalToLoseWeight",
  "hasNotices",
  "isAuthorized",
] as const;

const CALCULATED_VARS_LABEL = "--- Calculated variables ---";

function componentIsInput(
  component: OnboardingComponent
): component is OnboardingComponent & InputComponent {
  const inputComponents = [
    "checkbox",
    "dateInput",
    "numberInput",
    "listInput",
    "textInput",
  ];
  return inputComponents.includes(component.id);
}

const INPUT_HINTS: Record<(typeof VAR_TYPES)[number], string> = {
  array: "Comma separated values",
  boolean: "true or false",
  date: "YYYY-MM-DD",
  number: "Example: 123.01",
  string: "Text and ,-_:. symbols",
};

function ConditionsForShowForm({
  conditions,
  onChange,
}: {
  conditions: NavigationParams["conditionsForShow"];
  onChange: (conditions: NavigationParams["conditionsForShow"]) => void;
}) {
  const screens = useAppStore((state) => state.screens);

  const usedVariables = useMemo(() => {
    const vars: string[] = [];
    screens.forEach((screen) => {
      if (screen.type === "page") {
        screen.components.forEach((component) => {
          if (componentIsInput(component)) {
            vars.push(component.variableName);
          }
        });
      }
    });
    vars.push(CALCULATED_VARS_LABEL);
    vars.push(...CALCULATED_VARS);
    return uniq(vars);
  }, [screens]);

  if (usedVariables.length === 0) return null;

  return (
    <div className="bg-white p-4 rounded-xl w-full flex flex-col">
      <h3 className="text-lg font-semibold mb-4">Conditions for show</h3>
      {conditions.map((item, index) => {
        let isWrongFormat = false;
        switch (item.variableType) {
          case "number":
            isWrongFormat = isNaN(item.value);
            break;
          case "boolean":
            isWrongFormat = item.value !== true && item.value !== false;
            break;
          case "string":
            isWrongFormat = typeof item.value !== "string";
            break;
        }

        return (
          <div
            key={`${item.variableName}_${index}`}
            className="flex w-full bg-slate-100 p-2 pt-4 rounded-md mb-4 relative"
          >
            {index > 0 && (
              <p className="flex items-center font-bold justify-center w-10 h-8 bg-slate-300 absolute text-xs text-gray-600 rounded-md -top-6 -left-2">
                AND
              </p>
            )}
            <div className="w-1/4">
              <Select
                label="Variable"
                value={item.variableName}
                onChange={(variableName) => {
                  onChange([
                    ...conditions.slice(0, index),
                    {
                      ...item,
                      variableName,
                    },
                    ...conditions.slice(index + 1),
                  ]);
                }}
                options={usedVariables.map((variableName) => ({
                  label: variableName,
                  value: variableName,
                  disabled: variableName === CALCULATED_VARS_LABEL,
                }))}
              />
            </div>
            <div className="w-1/4 pl-4">
              <Select
                label="Type"
                value={item.variableType}
                onChange={(newType) => {
                  onChange([
                    ...conditions.slice(0, index),
                    {
                      ...item,
                      variableType: newType,
                    },
                    ...conditions.slice(index + 1),
                  ]);
                }}
                options={VAR_TYPES.map((c) => ({
                  label: c,
                  value: c,
                }))}
              />
            </div>
            <div className="w-1/4 pl-4">
              <Select
                label="Condition"
                value={item.condition}
                onChange={(newCondition) => {
                  onChange([
                    ...conditions.slice(0, index),
                    {
                      ...item,
                      condition: newCondition,
                    },
                    ...conditions.slice(index + 1),
                  ]);
                }}
                options={CONDITIONS.map((c) => ({
                  label: c,
                  value: c,
                }))}
              />
            </div>
            <div className="w-1/4 pl-4 flex flex-col">
              <TextInput
                label="Value"
                value={JSON.stringify(item.value).replace(
                  /[^0-9a-zA-Z,-:_.]/g,
                  ""
                )}
                onChange={(value) => {
                  onChange([
                    ...conditions.slice(0, index),
                    {
                      ...item,
                      value,
                    },
                    ...conditions.slice(index + 1),
                  ]);
                }}
                onBlur={(value) => {
                  let typedValue: any = value;
                  switch (item.variableType) {
                    case "boolean":
                      typedValue = value === "true";
                      break;
                    case "number":
                      typedValue = Number(value);
                      break;
                    case "string":
                      typedValue = value;
                      break;
                    case "array":
                      // remove spaces and split by comma
                      typedValue = value
                        .replace(/\s/g, "")
                        .replace(/[^0-9a-zA-Z,-:_]/g, "")
                        .split(",");
                      break;
                    case "date":
                      typedValue = new Date(value);
                      break;
                  }

                  onChange([
                    ...conditions.slice(0, index),
                    {
                      ...item,
                      value: typedValue,
                    },
                    ...conditions.slice(index + 1),
                  ]);
                }}
              />
              {isWrongFormat && (
                <span className="pt-1 text-red-500 text-xs">Wrong format</span>
              )}
              <span className="pt-1 text-gray-500 text-xs">
                {INPUT_HINTS[item.variableType]}
              </span>
            </div>
            <button
              onClick={() => onChange(conditions.filter((_, i) => i !== index))}
              className="flex-shrink-0 w-10 h-12 pt-9 -mr-1 flex items-center justify-center hover:scale-105"
            >
              <Trash2 size={16} className="text-gray-500" />
            </button>
          </div>
        );
      })}
      <div className="flex mr-auto">
        <AddButton
          label="Add condition"
          onClick={() =>
            onChange([
              ...conditions,
              {
                variableName: usedVariables[0],
                variableType: "string",
                condition: "==",
                value: "",
              },
            ])
          }
        />
      </div>
    </div>
  );
}

function ScreenNavParamsForm({
  screen,
  onChange,
}: {
  screen: OnboardingScreen | null;
  onChange: (appearance: OnboardingScreen["navigationParams"]) => void;
}) {
  if (!screen) return null;

  return (
    <div className="bg-slate-100 p-6 flex flex-col w-full rounded-b-xl space-y-4">
      <p className="text-xs font-semibold uppercase tracking-wider">
        Navigation Params
      </p>
      <div className="w-full flex">
        <ConditionsForShowForm
          conditions={screen.navigationParams.conditionsForShow}
          onChange={(conditionsForShow) => {
            onChange({
              ...screen.navigationParams,
              conditionsForShow,
            });
          }}
        />
        <div className="ml-4 flex flex-col space-y-3">
          <Checkbox
            label="Can go back"
            checked={screen.navigationParams.canGoBack}
            onChange={(canGoBack) => {
              onChange({
                ...screen.navigationParams,
                canGoBack,
              });
            }}
          />
          <Checkbox
            label="Should be skipped when going back"
            checked={screen.navigationParams.shouldBeSkippedWhenGoingBack}
            onChange={(shouldBeSkippedWhenGoingBack) => {
              onChange({
                ...screen.navigationParams,
                shouldBeSkippedWhenGoingBack,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
}

export { ScreenNavParamsForm };
