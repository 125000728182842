import React, { useCallback, useState } from "react";
import { SCREEN_SIZE } from "src/config";
import { Loader, Menu, Star, X } from "react-feather";
import { PageScreen } from "src/types/screens/PageScreen";
import { OnboardingScreen } from "src/types/screens";
import { OnboardingComponent } from "src/types";
import { PageScreenAppearanceForm } from "./ui/PageAppearanceForm";
import { TextInput } from "src/shared/components/TextInput";
import { ComponentForm } from "../../../ComponentForm";
import { AddComponentForm } from "../../../AddComponentForm";
import { PageScreenBottomBlockForm } from "./ui/PageBottomBlockForm";
import { ScreenNavParamsForm } from "../ScreenNavParamsForm";
import { uniq } from "lodash";
import { PageScreenGroupsForm } from "./ui/PageScreenGroupsForm";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { TextComponent } from "src/types/components/TextComponent";
import { CURRENT_THEME } from "src/shared/themes";
import { ListInputComponent } from "src/types/components/ListInputComponent";

const TABS = [
  "Components",
  "Appearance",
  "Bottom Block",
  "Navigation",
  "Screen Groups",
];

const SortableItem = SortableElement(
  ({
    component,
    componentIndex,
    screen,
    onUpdateComponent,
  }: {
    component: OnboardingComponent;
    componentIndex: number;
    screen: PageScreen | null;
    onUpdateComponent: (screen: OnboardingScreen) => void;
  }) => (
    <div className="flex relative mb-4">
      <div className="cursor-grabbing w-7 h-full flex items-center justify-center">
        <Menu className="text-gray-400" size={16} />
      </div>
      <div className="w-full">
        <ComponentForm
          component={component}
          onDelete={() => {
            if (!screen) return;
            onUpdateComponent({
              ...screen,
              components: screen.components.filter(
                (c, i) => i !== componentIndex
              ),
            });
          }}
          onChange={(newComponent) => {
            if (!screen) return;
            onUpdateComponent({
              ...screen,
              components: screen.components.map((c, i) =>
                i === componentIndex ? newComponent : c
              ),
            });
          }}
        />
      </div>
    </div>
  )
);

const SortableList = SortableContainer(
  ({
    components,
    screen,
    onUpdateComponents,
  }: {
    components: OnboardingComponent[];
    screen: PageScreen | null;
    onUpdateComponents: (screen: OnboardingScreen) => void;
  }) => {
    return (
      <div className="flex flex-col">
        {components.map((component, index) => (
          <SortableItem
            key={`${component.id}_${index}`}
            index={index}
            // @ts-ignore
            componentIndex={index}
            screen={screen}
            component={component}
            onUpdateComponent={onUpdateComponents}
          />
        ))}
      </div>
    );
  }
);

function PageScreenForm({
  screen,
  onClose,
  onChange,
}: {
  screen: PageScreen | null;
  onClose: () => void;
  onChange: (screen: OnboardingScreen) => void;
}) {
  const [currentTab, setCurrentTab] = useState(TABS[0]);
  const [loading, setLoading] = useState(false);

  const onAddComponent = useCallback(
    (component: OnboardingComponent) => {
      if (!screen) return;
      onChange({
        ...screen,
        components: [...screen.components, component],
      });
    },
    [onChange, screen]
  );

  const getAIRecommendation = useCallback(async () => {
    if (!screen) return;
    if (loading) return;
    setLoading(true);

    // get string from user by basic alert
    const input = prompt("1-3 words about this screen");
    if (!input) {
      setLoading(false);
      return;
    }

    // get AI recommendation
    try {
      const response = await fetch(
        "https://moveup-api.move-up-technical8452.workers.dev/v1/builder",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            screen: input,
          }),
        }
      );
      const json = await response.json();
      if (json.data && json.data.variableName) {
        const titleComponent: TextComponent = {
          id: "text",
          type: "question",
          text: json.data.question ?? "Question name",
          containsMarkdown: false,
          style: CURRENT_THEME.components.text["question"],
          margins: {
            top: 32,
            left: 16,
            right: 16,
          },
        };
        const listInputComponent: ListInputComponent = {
          id: "listInput",
          type: "single",
          options:
            json.data.answer.map((a: any, index: number) => ({
              label: a.label ?? "Option label",
              value: a.value ?? `option_${index}`,
            })) ?? [],
          variableName: json.data.variableName ?? "listInputVarName",
          required: false,
          style: CURRENT_THEME.components.listInput.list,
          selectedOptionStyle:
            CURRENT_THEME.components.listInput.selectedOption,
          optionStyle: CURRENT_THEME.components.listInput.option,
          margins: {
            top: 32,
            left: 16,
            right: 16,
          },
        };

        onChange({
          ...screen,
          components: [titleComponent, listInputComponent],
        });
      } else {
        alert("Error getting AI recommendation");
      }

      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }, [loading, onChange, screen]);

  return (
    <div
      className={`${
        !!screen
          ? "opacity-100 delay-300 translate-x-0"
          : "opacity-0 -translate-x-8 pointer-events-none"
      } transition duration-300 relative container mx-auto px-6`}
    >
      <div className="flex w-full">
        <div
          style={{
            width: SCREEN_SIZE.width,
            height: SCREEN_SIZE.height,
          }}
          className="flex-shrink-0 mr-12"
        />
        <div className="bg-white w-full shadow-xl rounded-xl flex flex-col p-8 relative">
          <button
            onClick={() => onClose()}
            className="absolute w-12 h-12 right-4 top-4 flex items-center justify-center hover:scale-105"
          >
            <X size={24} className="text-gray-500" />
          </button>
          <h2 className="text-base font-semibold mb-4">ID {screen?.id}</h2>
          <hr className="mb-4" />

          <div className="flex">
            <div className="w-1/2">
              <TextInput
                label="Screen Name"
                value={screen?.name ?? ""}
                onChange={(name) => {
                  if (!screen) return;
                  onChange({
                    ...screen,
                    name,
                  });
                }}
              />
            </div>
            <div className="w-1/2 pl-2">
              <p className="text-sm text-gray-500 mt-8">
                — using for Analytics (e.g. "{screen?.name ?? ""}Done")
              </p>
            </div>
          </div>

          <div className="border-b border-gray-200">
            <nav className="mt-4 -mb-px flex space-x-2">
              {TABS.map((tab) => (
                <button
                  key={tab}
                  onClick={() => setCurrentTab(tab)}
                  className={`${
                    tab === currentTab
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  } 
              whitespace-nowrap border-b-2 py-4 px-2 text-sm font-medium`}
                >
                  {tab}
                </button>
              ))}
            </nav>
          </div>

          {currentTab === "Appearance" && (
            <PageScreenAppearanceForm
              screen={screen}
              onChange={(appearance) => {
                if (!screen) return;
                onChange({
                  ...screen,
                  appearance,
                });
              }}
            />
          )}

          {currentTab === "Components" && (
            <div className="bg-slate-100 p-6 pl-0 flex flex-col w-full rounded-b-xl">
              <SortableList
                // @ts-ignore
                onUpdateComponents={onChange}
                components={screen?.components ?? []}
                screen={screen}
                onSortEnd={({ oldIndex, newIndex }) => {
                  if (!screen || oldIndex === newIndex) return;
                  onChange({
                    ...screen,
                    components: arrayMoveImmutable(
                      screen.components,
                      oldIndex,
                      newIndex
                    ),
                  });
                }}
              />

              {(screen?.components ?? []).length === 0 && (
                <button
                  onClick={getAIRecommendation}
                  className="text-sm flex items-center py-2 px-4 font-semibold bg-white text-indigo-600 rounded-xl border border-gray-300 ml-6 mr-auto mb-6"
                >
                  {loading ? (
                    <Loader className="w-4 mr-2 animate-spin" />
                  ) : (
                    <Star className="w-4 mr-2" />
                  )}
                  AI Generation
                </button>
              )}
              <AddComponentForm
                onAdd={onAddComponent}
                existedComponentTypes={uniq(
                  screen?.components.map((c) => c.id) ?? []
                )}
              />
            </div>
          )}

          {currentTab === "Bottom Block" && (
            <PageScreenBottomBlockForm
              screen={screen}
              onChange={(bottomBlock) => {
                if (!screen) return;
                onChange({
                  ...screen,
                  bottomBlock,
                });
              }}
            />
          )}

          {currentTab === "Navigation" && (
            <ScreenNavParamsForm
              screen={screen}
              onChange={(navigationParams) => {
                if (!screen) return;
                onChange({
                  ...screen,
                  navigationParams,
                });
              }}
            />
          )}

          {currentTab === "Screen Groups" && (
            <PageScreenGroupsForm
              screen={screen}
              onChange={(groupParams) => {
                if (!screen) return;
                onChange({
                  ...screen,
                  groupParams,
                });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export { PageScreenForm };
