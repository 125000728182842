import { CURRENT_THEME } from "../themes";

type ColorPickerProps = {
  value: string | undefined; // color key
  label: string;
  onChange: (newValue: string | undefined) => void;
};

function ColorPicker({ value, onChange, label }: ColorPickerProps) {
  const onChangeValue = (newValue: string) => {
    if (newValue === "undefined") {
      onChange(undefined);
    } else {
      onChange(newValue);
    }
  };

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div className="mt-1 flex items-center">
        <select
          value={value}
          onChange={(e) => onChangeValue(e.target.value)}
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        >
          <option value="undefined">Default color</option>
          {Object.keys(CURRENT_THEME.colors).map((key) => (
            <option key={key} value={key}>
              {key}
            </option>
          ))}
          <option disabled value="custom">
            Custom color
          </option>
        </select>
        {value !== "custom" && (
          <div className="h-5 ml-2 flex-shrink-0 flex">
            {value && (
              <>
                <div
                  className="w-5 mr-1 h-full border rounded-full"
                  style={{
                    backgroundColor: (CURRENT_THEME.colors as any)[value].light,
                  }}
                />
                <div
                  className="w-5 h-full border rounded-full"
                  style={{
                    backgroundColor: (CURRENT_THEME.colors as any)[value].dark,
                  }}
                />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export { ColorPicker };
