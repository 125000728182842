import { ButtonComponent } from "./components/ButtonComponent";
import { CheckboxComponent } from "./components/CheckboxComponent";
import { DateInputComponent } from "./components/DateInputComponent";
import { DividerComponent } from "./components/DividerComponent";
import { ImageComponent } from "./components/ImageComponent";
import { ListInputComponent } from "./components/ListInputComponent";
import { NumberInputComponent } from "./components/NumberInputComponent";
import { SpacerComponent } from "./components/SpacerComponent";
import { TextComponent } from "./components/TextComponent";
import { TextInputComponent } from "./components/TextInputComponent";

// Appearance
export const APPEARANCE_TYPES = ["light", "dark"] as const;
export type AppearanceType = (typeof APPEARANCE_TYPES)[number];

// Actions
export const ACTIONS = [
  "next",
  "skip",
  "back",
  "signIn",
  "signUp",
  "getEmail",
] as const;

export type Action = (typeof ACTIONS)[number];

// Icons
export const ICONS = ["brain", "food", "health", "weight"] as const;

export type Icon = BundledIcon | CustomIcon;

export type BundledIcon = {
  type: "bundled";
  name: (typeof ICONS)[number];
};

export type CustomIcon = {
  type: "custom";
  url: string;
};

// Components
export type OnboardingComponent =
  | TextComponent
  | ListInputComponent
  | ButtonComponent
  | CheckboxComponent
  | ImageComponent
  | TextInputComponent
  | DateInputComponent
  | NumberInputComponent
  | DividerComponent
  | SpacerComponent;
