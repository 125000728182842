import { Hash } from "react-feather";
import { NumberInput } from "src/shared/components/NumberInput";
import { Select } from "src/shared/components/Select";
import { NumberInputComponent } from "src/types/components/NumberInputComponent";
import { ComponentFormItem, ComponentFormProps } from "..";
import { BindedDataInput } from "./BindedDataInput";
import { SpacedComponentForm } from "./SpacedComponentForm";

function NumberInputComponentForm(props: ComponentFormProps) {
  const component = props.component as NumberInputComponent;

  return (
    <ComponentFormItem onDelete={props.onDelete}>
      <div className="flex space-x-4 w-full">
        <SpacedComponentForm
          component={component}
          onChange={(margins) =>
            props.onChange({
              ...component,
              margins,
            })
          }
        >
          <Hash />
        </SpacedComponentForm>
        <div className="flex flex-col w-full pr-8">
          <div className="flex w-full mb-3">
            <div className="w-1/3">
              <Select
                label="Input Type"
                value={component.type}
                options={[
                  { label: "number", value: "number" },
                  { label: "weight", value: "weight" },
                  { label: "height", value: "height" },
                ]}
                onChange={(value) => {
                  props.onChange({
                    ...component,
                    type: value,
                  });
                }}
              />
            </div>
            <div className="w-1/3 pl-4">
              <NumberInput
                label="Default Value"
                value={component.defaultValue as any}
                onChange={(value) => {
                  props.onChange({
                    ...component,
                    defaultValue: value,
                  });
                }}
              />
            </div>
            <div className="flex w-1/3">
              <div className="w-1/2 pl-4">
                <NumberInput
                  label="Min Limit"
                  value={component.limits?.min}
                  onChange={(value) => {
                    props.onChange({
                      ...component,
                      limits: {
                        ...component.limits,
                        min: value,
                      },
                    });
                  }}
                />
              </div>
              <div className="w-1/2 pl-4">
                <NumberInput
                  label="Max Limit"
                  value={component.limits?.max}
                  onChange={(value) => {
                    props.onChange({
                      ...component,
                      limits: {
                        ...component.limits,
                        max: value,
                      },
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="w-full">
            <BindedDataInput
              component={{
                variableName: component.variableName,
                required: component.required,
              }}
              onChange={(updates) =>
                props.onChange({ ...component, ...updates })
              }
            />
          </div>
        </div>
      </div>
    </ComponentFormItem>
  );
}

export { NumberInputComponentForm };
