import { PageScreen } from "src/types/screens/PageScreen";
import { ComponentView } from "../../ComponentView";
import { BottomBlock } from "../../ComponentView/ui/BottomBlock";
import { ScreenGroupsInfo } from "src/screens/App";
import { ScreenGroupHeader } from "../../ComponentView/ui/ScreenGroupHeader";
import { AppearanceType } from "src/types";

function PageView({
  screen,
  groupsInfo,
  appearance,
}: {
  screen: PageScreen;
  groupsInfo?: ScreenGroupsInfo;
  appearance: AppearanceType;
}) {
  const hideGroup = screen.groupParams?.hideGroup ?? false;
  const keyboardAtBottom = screen.components.some((c) => c.id === "dateInput");

  return (
    <>
      <div
        style={{
          paddingTop: screen.appearance?.ignoreSafeArea ? 0 : 44,
          paddingBottom: keyboardAtBottom ? 88 + 250 : 122,
        }}
        className="w-full h-full absolute overflow-hidden flex flex-col left-0 top-0"
      >
        {/* Screen Group */}
        {groupsInfo && !hideGroup && (
          <ScreenGroupHeader appearance={appearance} groupsInfo={groupsInfo} />
        )}

        {/* Components */}
        {screen.components.map((component, index) => (
          <ComponentView
            key={`${component.id}_${index}`}
            component={component}
            appearance={appearance}
          />
        ))}
      </div>

      <BottomBlock
        data={screen.bottomBlock}
        appearance={appearance}
        canGoBack={screen.navigationParams.canGoBack}
        keyboardAtBottom={keyboardAtBottom}
      />
    </>
  );
}

export { PageView };
