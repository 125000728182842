import React, { useCallback, useState } from "react";
import { SCREEN_SIZE } from "src/config";
import { Menu, X } from "react-feather";
import { OnboardingScreen } from "src/types/screens";
import { OnboardingComponent } from "src/types";

import { TextInput } from "src/shared/components/TextInput";
import { ComponentForm } from "../../../ComponentForm";
import { AddComponentForm } from "../../../AddComponentForm";
import { ScreenNavParamsForm } from "../ScreenNavParamsForm";
import { uniq } from "lodash";
import { ModalScreen } from "src/types/screens/ModalScreen";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";

const TABS = ["Components", "Navigation"];

const SortableItem = SortableElement(
  ({
    component,
    componentIndex,
    screen,
    onChange,
  }: {
    component: OnboardingComponent;
    componentIndex: number;
    screen: ModalScreen | null;
    onChange: (screen: OnboardingScreen) => void;
  }) => (
    <div className="flex relative mb-4">
      <div className="cursor-grabbing w-7 h-full flex items-center justify-center">
        <Menu className="text-gray-400" size={16} />
      </div>
      <div className="w-full">
        <ComponentForm
          component={component}
          onDelete={() => {
            if (!screen) return;
            onChange({
              ...screen,
              components: screen.components.filter(
                (c, i) => i !== componentIndex
              ),
            });
          }}
          onChange={(newComponent) => {
            if (!screen) return;
            onChange({
              ...screen,
              components: screen.components.map((c, i) =>
                i === componentIndex ? newComponent : c
              ),
            });
          }}
        />
      </div>
    </div>
  )
);

const SortableList = SortableContainer(
  ({
    components,
    screen,
    onChange,
  }: {
    components: OnboardingComponent[];
    screen: ModalScreen | null;
    onChange: (screen: OnboardingScreen) => void;
  }) => {
    return (
      <div className="flex flex-col">
        {components.map((component, index) => (
          <SortableItem
            key={`${component.id}_${index}`}
            index={index}
            // @ts-ignore
            componentIndex={index}
            screen={screen}
            component={component}
            onChange={onChange}
          />
        ))}
      </div>
    );
  }
);

function ModalForm({
  screen,
  onClose,
  onChange,
}: {
  screen: ModalScreen | null;
  onClose: () => void;
  onChange: (screen: OnboardingScreen) => void;
}) {
  const [currentTab, setCurrentTab] = useState(TABS[0]);

  const onAddComponent = useCallback(
    (component: OnboardingComponent) => {
      if (!screen) return;
      onChange({
        ...screen,
        components: [...screen.components, component],
      });
    },
    [onChange, screen]
  );

  return (
    <div
      className={`${
        !!screen
          ? "opacity-100 delay-300 translate-x-0"
          : "opacity-0 -translate-x-8 pointer-events-none"
      } transition duration-300 relative container mx-auto px-6`}
    >
      <div className="flex w-full">
        <div
          style={{
            width: SCREEN_SIZE.width,
            height: SCREEN_SIZE.height,
          }}
          className="flex-shrink-0 mr-12"
        />
        <div className="bg-white w-full shadow-xl rounded-xl flex flex-col p-8 relative">
          <button
            onClick={() => onClose()}
            className="absolute w-12 h-12 right-4 top-4 flex items-center justify-center hover:scale-105"
          >
            <X size={24} className="text-gray-500" />
          </button>
          <h2 className="text-base font-semibold mb-4">ID {screen?.id}</h2>
          <hr className="mb-4" />

          <div className="flex">
            <div className="w-1/2">
              <TextInput
                label="Screen Name"
                value={screen?.name ?? ""}
                onChange={(name) => {
                  if (!screen) return;
                  onChange({
                    ...screen,
                    name,
                  });
                }}
              />
            </div>
            <div className="w-1/2 pl-2">
              <p className="text-sm text-gray-500 mt-8">
                — using for Analytics (e.g. "{screen?.name ?? ""}Done")
              </p>
            </div>
          </div>

          <div className="border-b border-gray-200">
            <nav className="mt-4 -mb-px flex space-x-2">
              {TABS.map((tab) => (
                <button
                  key={tab}
                  onClick={() => setCurrentTab(tab)}
                  className={`${
                    tab === currentTab
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                  } 
              whitespace-nowrap border-b-2 py-4 px-2 text-sm font-medium`}
                >
                  {tab}
                </button>
              ))}
            </nav>
          </div>

          {currentTab === "Components" && (
            <div className="bg-slate-100 p-6 pl-0 flex flex-col w-full rounded-b-xl">
              <SortableList
                // @ts-ignore
                components={screen?.components ?? []}
                screen={screen}
                onChange={onChange}
                onSortEnd={({ oldIndex, newIndex }) => {
                  if (!screen) return;
                  onChange({
                    ...screen,
                    components: arrayMoveImmutable(
                      screen.components,
                      oldIndex,
                      newIndex
                    ),
                  });
                }}
              />
              <AddComponentForm
                onAdd={onAddComponent}
                existedComponentTypes={uniq(
                  screen?.components.map((c) => c.id) ?? []
                )}
              />
            </div>
          )}

          {currentTab === "Navigation" && (
            <ScreenNavParamsForm
              screen={screen}
              onChange={(navigationParams) => {
                if (!screen) return;
                onChange({
                  ...screen,
                  navigationParams,
                });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export { ModalForm };
