import { TextInput } from "src/shared/components/TextInput";
import { AUTH_TYPES, AuthScreen } from "src/types/screens/AuthScreen";

function AuthFormContent({
  screen,
  onChange,
}: {
  screen: AuthScreen | null;
  onChange: (screen: AuthScreen) => void;
}) {
  if (!screen) return null;

  return (
    <div className="bg-slate-100 p-6 flex flex-col w-full rounded-b-xl space-y-4">
      <p className="text-xs font-semibold uppercase tracking-wider">
        Content Params
      </p>
      <div className="w-full flex">
        <div className="w-full flex space-y-4 flex-col">
          <TextInput
            label="Title"
            value={screen?.title ?? ""}
            onChange={(title) => {
              if (!screen) return;
              onChange({
                ...screen,
                title,
              });
            }}
          />
          <TextInput
            label="Description"
            value={screen?.description ?? ""}
            onChange={(description) => {
              if (!screen) return;
              onChange({
                ...screen,
                description,
              });
            }}
          />
          <TextInput
            label="Privacy Text (with markdown)"
            value={screen?.privacyText ?? ""}
            onChange={(privacyText) => {
              if (!screen) return;
              onChange({
                ...screen,
                privacyText,
              });
            }}
          />

          <div className="flex flex-col">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Auth types
            </label>
            <div className="flex space-x-2">
              {AUTH_TYPES.map((type) => (
                <button
                  key={type}
                  onClick={() => {
                    if (!screen) return;
                    if (screen.authTypes.includes(type)) {
                      onChange({
                        ...screen,
                        authTypes: screen.authTypes.filter((t) => t !== type),
                      });
                    } else {
                      onChange({
                        ...screen,
                        authTypes: [...screen.authTypes, type],
                      });
                    }
                  }}
                  className={`${
                    screen.authTypes.includes(type)
                      ? "bg-indigo-600 text-white"
                      : "bg-indigo-100 text-indigo-600"
                  } py-2 px-4 rounded-full text-xs`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { AuthFormContent };
