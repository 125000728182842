import { PaywallScreen } from "src/types/screens/PaywallScreen";

function PaywallView({ screen }: { screen: PaywallScreen }) {
  return (
    <>
      <iframe
        title={`paywall_iframe_${screen.id}`}
        className="h-full w-full"
        src={screen.url}
      />

      {/* To avoid clicks inside iframe */}
      <div className="absolute left-0 top-0 right-0 bottom-0"></div>
    </>
  );
}

export { PaywallView };
