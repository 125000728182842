import { Plus } from "react-feather";

function AddButton({
  label,
  onClick,
  className,
}: {
  label: string;
  onClick: () => void;
  className?: string;
}) {
  return (
    <button
      onClick={() => onClick()}
      className={`${className} hover:scale-105 active:scale-95 transition flex items-center justify-center py-2 px-4 space-x-2 rounded-xl border border-gray-300`}
    >
      <Plus size={14} className="text-gray-500" />
      <p className="capitalize text-xs font-semibold text-gray-500">{label}</p>
    </button>
  );
}

export { AddButton };
