import {
  ListInputComponent,
  ListInputOption,
} from "src/types/components/ListInputComponent";

import brainIcon from "src/assets/icons/brain.svg";
import foodIcon from "src/assets/icons/food.svg";
import healthIcon from "src/assets/icons/health.svg";
import weightIcon from "src/assets/icons/weight.svg";
import { AppearanceType, BundledIcon } from "src/types";
import { SyntheticEvent, useState } from "react";
import { Check } from "react-feather";
import { getStyles } from "src/shared/helpers/styleConverter";

function ListInputComponentView({
  component,
  appearance,
}: {
  component: ListInputComponent;
  appearance: AppearanceType;
}) {
  const appearanceKey = appearance === "dark" ? "dark" : "light";
  const [selectedOption, setSelectedOption] = useState<string[]>([]);

  const getIconByName = (icon: BundledIcon) => {
    switch (icon.name) {
      case "brain":
        return brainIcon;
      case "food":
        return foodIcon;
      case "health":
        return healthIcon;
      case "weight":
        return weightIcon;

      default:
        return "";
    }
  };

  const clickOptionHandler = (e: SyntheticEvent, option: ListInputOption) => {
    e.stopPropagation();
    if (component.type === "multiple") {
      if (selectedOption.includes(option.value)) {
        return setSelectedOption(
          selectedOption.filter((item) => item !== option.value)
        );
      } else {
        return setSelectedOption([...selectedOption, option.value]);
      }
    }

    return setSelectedOption([option.value]);
  };

  const renderOptionCheckBoxIconByState = (option: ListInputOption) => {
    if (selectedOption.includes(option.value)) {
      return (
        <div
          style={{ background: "#56A97A" }}
          className="mr-3.5 w-6 h-6 rounded-md bg-white flex items-center justify-center"
        >
          <Check size={16} className="text-white font-bold" />
        </div>
      );
    } else {
      return (
        <div className="mr-3.5 w-6 h-6 rounded-md bg-white border border-gray-300 flex items-center justify-center"></div>
      );
    }
  };

  return (
    <div
      style={getStyles({ style: component.style, appearance: appearanceKey })}
      className="w-full"
    >
      {component.options.map((option, index) => (
        <div
          key={`listInput_option_${index}`}
          style={getStyles({
            style: selectedOption.includes(option.value)
              ? component.selectedOptionStyle
              : component.optionStyle,
            appearance: appearanceKey,
          })}
          className="box-content cursor-pointer h-6 px-4 py-6 mb-3 flex items-center justify-start"
          onClick={(e) => clickOptionHandler(e, option)}
        >
          {/* Multiple checkbox */}
          {component.type === "multiple" &&
            renderOptionCheckBoxIconByState(option)}

          {/* Single & icon */}
          {component.type === "single" && option.icon && (
            <>
              <img
                className="block mr-4"
                src={getIconByName(option.icon as BundledIcon)}
                alt={(option.icon as BundledIcon).name}
              />
            </>
          )}

          <div className="flex flex-col items-start">
            <p className="text-left block">{option.label}</p>
            {option.subtitle && (
              <span className="text-left block text-sm font-normal">
                {option.subtitle}
              </span>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export { ListInputComponentView };
