import { Checkbox } from "src/shared/components/Checkbox";
import { Select } from "src/shared/components/Select";
import { TextInput } from "src/shared/components/TextInput";
import { GroupedScreen } from "src/types/screens";

export const GROUP_SCREEN_ICONS = {
  brain: {
    light:
      "https://onboarding-builder.pages.dev/assets/screen-groups/brain.svg",
    dark: "https://onboarding-builder.pages.dev/assets/screen-groups/brain-dark.svg",
  },
  goal: {
    light: "https://onboarding-builder.pages.dev/assets/screen-groups/goal.svg",
    dark: "https://onboarding-builder.pages.dev/assets/screen-groups/goal-dark.svg",
  },
  heart: {
    light:
      "https://onboarding-builder.pages.dev/assets/screen-groups/heart.svg",
    dark: "https://onboarding-builder.pages.dev/assets/screen-groups/heart-dark.svg",
  },
  weight: {
    light:
      "https://onboarding-builder.pages.dev/assets/screen-groups/weight.svg",
    dark: "https://onboarding-builder.pages.dev/assets/screen-groups/weight-dark.svg",
  },
} as const;

function getIconKeyByUrl(url: string | undefined) {
  if (!url) return undefined;
  return Object.keys(GROUP_SCREEN_ICONS).find(
    (key) =>
      GROUP_SCREEN_ICONS[key as keyof typeof GROUP_SCREEN_ICONS].light === url
  );
}

function PageScreenGroupsForm({
  screen,
  onChange,
}: {
  screen: GroupedScreen | null;
  onChange: (groupParams: GroupedScreen["groupParams"]) => void;
}) {
  if (!screen) return null;

  return (
    <div className="bg-slate-100 p-6 flex flex-col w-full rounded-b-xl space-y-4">
      <p className="text-xs font-semibold uppercase tracking-wider">
        Group Params
      </p>
      <div className="flex flex-wrap">
        <div className="w-1/3">
          <Select
            value={getIconKeyByUrl(screen.groupParams?.startGroup?.icon?.light)}
            onChange={(icon) => {
              onChange({
                ...screen.groupParams,
                startGroup: {
                  title: screen.groupParams?.startGroup?.title ?? "Group name",
                  icon: GROUP_SCREEN_ICONS[
                    icon as keyof typeof GROUP_SCREEN_ICONS
                  ],
                },
              });
            }}
            label="Group Icon"
            options={[
              { label: "No icon", value: "undefined" },
              ...Object.keys(GROUP_SCREEN_ICONS).map((icon) => ({
                label: icon,
                value: icon,
              })),
            ]}
          />
        </div>

        <div className="w-1/3 pl-4">
          <TextInput
            label="Group Title"
            value={screen.groupParams?.startGroup?.title ?? ""}
            onChange={(title) => {
              onChange({
                ...screen.groupParams,
                startGroup:
                  title === ""
                    ? undefined
                    : {
                        title,
                        icon: screen.groupParams?.startGroup?.icon ?? undefined,
                      },
              });
            }}
          />
        </div>

        <div className="w-1/3 pl-4 flex flex-col space-y-2">
          <Checkbox
            checked={!!screen.groupParams?.hideGroup}
            onChange={(hideGroup) => {
              onChange({
                ...screen.groupParams,
                hideGroup,
              });
            }}
            label="Hide groups for this screen"
          />
          <Checkbox
            checked={!!screen.groupParams?.endOfGroup}
            onChange={(endOfGroup) => {
              onChange({
                ...screen.groupParams,
                endOfGroup,
              });
            }}
            label="Group ends here if it was started before this screen"
          />
        </div>
      </div>
    </div>
  );
}

export { PageScreenGroupsForm };
