import { getStyles } from "src/shared/helpers/styleConverter";
import { AppearanceType } from "src/types";
import { DateInputComponent } from "src/types/components/DateInputComponent";

function DateInputComponentView({
  component,
  appearance,
}: {
  component: DateInputComponent;
  appearance: AppearanceType;
}) {
  const appearanceKey = appearance === "dark" ? "dark" : "light";

  const styles = getStyles({
    style: component.style,
    appearance: appearanceKey,
  });

  let exampleText = "June 11, 1990";
  if (component.type === "time") {
    exampleText = (component.defaultValue as string) ?? "11:00 AM";
  }
  if (component.type === "datetime") {
    exampleText = "June 11, 11:00 AM";
  }

  return (
    <div
      style={{
        paddingLeft: component.paddings?.left,
        paddingRight: component.paddings?.right,
        paddingTop: component.paddings?.top,
        paddingBottom: component.paddings?.bottom,
        borderBottomWidth: styles.borderWidth,
        borderColor: styles.borderColor,
      }}
      className="flex w-full"
    >
      <p
        style={{
          color: styles.color,
          fontFamily: styles.fontFamily,
          fontSize: styles.fontSize,
          fontWeight: styles.fontWeight,
          lineHeight: styles.lineHeight,
          textAlign: styles.textAlign,
        }}
        className="text-left w-full"
      >
        {exampleText}
      </p>
    </div>
  );
}

export { DateInputComponentView };
