import { AppearanceType, OnboardingComponent } from "src/types";
import { SpacedComponent } from "src/types/components";
import { ButtonComponent } from "src/types/components/ButtonComponent";
import { CheckboxComponent } from "src/types/components/CheckboxComponent";
import { DateInputComponent } from "src/types/components/DateInputComponent";
import { DividerComponent } from "src/types/components/DividerComponent";
import { ImageComponent } from "src/types/components/ImageComponent";
import { ListInputComponent } from "src/types/components/ListInputComponent";
import { NumberInputComponent } from "src/types/components/NumberInputComponent";
import { TextComponent } from "src/types/components/TextComponent";
import { TextInputComponent } from "src/types/components/TextInputComponent";
import { ButtonComponentView } from "./ui/ButtonComponentView";
import { CheckboxComponentView } from "./ui/CheckboxComponentView";
import { DateInputComponentView } from "./ui/DateInputComponentView";
import { DividerComponentView } from "./ui/DividerComponentView";
import { ImageComponentView } from "./ui/ImageComponentView";
import { ListInputComponentView } from "./ui/ListInputComponentView";
import { NotSupported } from "./ui/NotSupported";
import { NumberInputComponentView } from "./ui/NumberInputComponentView";
import { TextComponentView } from "./ui/TextComponentView";
import { TextInputComponentView } from "./ui/TextInputComponentView";
import { SpacerComponent } from "src/types/components/SpacerComponent";
import { SpacerComponentView } from "./ui/SpacerComponentView";

function SpacedComponentView({
  component,
  children,
}: {
  component: SpacedComponent;
  children: React.ReactNode;
}) {
  return (
    <div
      className="w-full border-box"
      style={{
        paddingTop: component.margins?.top,
        paddingLeft: component.margins?.left,
        paddingRight: component.margins?.right,
        paddingBottom: component.margins?.bottom,
      }}
    >
      {children}
    </div>
  );
}

function ComponentView({
  component,
  appearance,
}: {
  component: OnboardingComponent;
  appearance: AppearanceType;
}) {
  const getComponent = (component: OnboardingComponent) => {
    switch (component.id) {
      case "text":
        return (
          <TextComponentView
            component={component as TextComponent}
            appearance={appearance}
          />
        );
      case "listInput":
        return (
          <ListInputComponentView
            component={component as ListInputComponent}
            appearance={appearance}
          />
        );
      case "button":
        return (
          <ButtonComponentView
            component={component as ButtonComponent}
            appearance={appearance}
          />
        );
      case "checkbox":
        return (
          <CheckboxComponentView
            component={component as CheckboxComponent}
            appearance={appearance}
          />
        );
      case "image":
        return (
          <ImageComponentView
            component={component as ImageComponent}
            appearance={appearance}
          />
        );
      case "textInput":
        return (
          <TextInputComponentView
            component={component as TextInputComponent}
            appearance={appearance}
          />
        );
      case "dateInput":
        return (
          <DateInputComponentView
            component={component as DateInputComponent}
            appearance={appearance}
          />
        );
      case "numberInput":
        return (
          <NumberInputComponentView
            component={component as NumberInputComponent}
            appearance={appearance}
          />
        );
      case "divider":
        return (
          <DividerComponentView
            component={component as DividerComponent}
            appearance={appearance}
          />
        );
      case "spacer":
        return <SpacerComponentView component={component as SpacerComponent} />;
      default:
        return (
          <NotSupported componentId={(component as OnboardingComponent).id} />
        );
    }
  };

  if (component.id === "spacer") {
    return getComponent(component);
  }

  return (
    <SpacedComponentView component={component}>
      {getComponent(component)}
    </SpacedComponentView>
  );
}

export { ComponentView };
