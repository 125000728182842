import { Edit3 } from "react-feather";
import { TextInputComponent } from "src/types/components/TextInputComponent";
import { ComponentFormItem, ComponentFormProps } from "..";
import { BindedDataInput } from "./BindedDataInput";
import { SpacedComponentForm } from "./SpacedComponentForm";

function TextInputComponentForm(props: ComponentFormProps) {
  const component = props.component as TextInputComponent;

  return (
    <ComponentFormItem onDelete={props.onDelete}>
      <div className="flex space-x-4 w-full">
        <SpacedComponentForm
          component={component}
          onChange={(margins) =>
            props.onChange({
              ...component,
              margins,
            })
          }
        >
          <Edit3 />
        </SpacedComponentForm>
        <div className="flex flex-col w-full pr-8">
          <div className="w-full pt-5">
            <BindedDataInput
              component={{
                variableName: component.variableName,
                required: component.required,
              }}
              onChange={(updates) =>
                props.onChange({ ...component, ...updates })
              }
            />
          </div>
        </div>
      </div>
    </ComponentFormItem>
  );
}

export { TextInputComponentForm };
