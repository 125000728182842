import { PaletteColor } from "src/shared/themes";

export const COMPONENTS = [
  "text",
  "button",
  "image",
  "listInput",
  "checkbox",
  "textInput",
  "dateInput",
  "numberInput",
  "divider",
  "spacer",
] as const;

export const FONT_FAMILIES = [
  "SF Pro Display",
  "SF Pro Text",
  "Montserrat",
] as const;

export type ComponentType = (typeof COMPONENTS)[number];

export type SpacedComponent = {
  margins?: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  };
  paddings?: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  };
};

export type StyledComponent = {
  style?: {
    height?: number;
    textAlign?: "left" | "center" | "right";
    backgroundColor?: PaletteColor;
    textColor?: PaletteColor;
    fontFamily?: (typeof FONT_FAMILIES)[number];
    fontWeight?: number;
    fontSize?: number;
    letterSpacing?: number;
    lineHeight?: number;
    borderWidth?: number;
    borderColor?: PaletteColor;
    cornerRadius?: number;
    shadow?: {
      color?: PaletteColor;
      opacity?: number;
      offset?: {
        x?: number;
        y?: number;
      };
      radius?: number;
    };
  };
};

export type PlainStyle = {
  height?: number;
  textAlign?: "left" | "center" | "right";
  backgroundColor?: string;
  color?: string; // key changed
  fontFamily?: (typeof FONT_FAMILIES)[number];
  fontWeight?: number;
  fontSize?: number;
  letterSpacing?: number;
  lineHeight?: string; // number to 'VALUE+px'
  borderWidth?: number;
  borderColor?: string;
  borderRadius?: number; // key changed
  shadow?: {
    color?: string;
    opacity?: number;
    offset?: {
      x?: number;
      y?: number;
    };
    radius?: number;
  };
};

export type InputComponent = {
  variableName: string;
  required: boolean;
  defaultValue?: string | number | boolean | Date;
  analytics?: {
    analyticsKey?: string;
    userPropertyKey?: string;
  };
};
