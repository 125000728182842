import { format } from "date-fns";
import { CheckSquare } from "react-feather";
import { DateInput } from "src/shared/components/DateInput";
import { Select } from "src/shared/components/Select";
import { DateInputComponent } from "src/types/components/DateInputComponent";
import { ComponentFormItem, ComponentFormProps } from "..";
import { BindedDataInput } from "./BindedDataInput";
import { SpacedComponentForm } from "./SpacedComponentForm";
import { TimeInput } from "src/shared/components/TimeInput";

function DateInputComponentForm(props: ComponentFormProps) {
  const component = props.component as DateInputComponent;

  return (
    <ComponentFormItem onDelete={props.onDelete}>
      <div className="flex space-x-4 w-full">
        <SpacedComponentForm
          component={component}
          onChange={(margins) =>
            props.onChange({
              ...component,
              margins,
            })
          }
        >
          <CheckSquare />
        </SpacedComponentForm>
        <div className="flex flex-col w-full pr-8">
          <div className="flex w-full mb-3">
            <div className="w-1/3">
              <Select
                label="Date Type"
                value={component.type}
                options={[
                  { label: "Date", value: "date" },
                  { label: "Time", value: "time" },
                  { label: "Date and Time", value: "datetime" },
                ]}
                onChange={(value) => {
                  props.onChange({
                    ...component,
                    type: value,
                  });
                }}
              />
            </div>
            <div className="w-1/3 pl-4">
              {component.type === "time" ? (
                <TimeInput
                  label="Min Limit"
                  value={
                    component.limits?.min
                      ? (component.limits.min as string)
                      : ""
                  }
                  onChange={(value) => {
                    props.onChange({
                      ...component,
                      limits: {
                        ...component.limits,
                        min: value,
                      },
                    });
                  }}
                />
              ) : (
                <DateInput
                  label="Min Limit"
                  value={
                    component.limits?.min
                      ? format(component.limits.min as Date, "yyyy-MM-dd")
                      : ""
                  }
                  onChange={(value) => {
                    props.onChange({
                      ...component,
                      limits: {
                        ...component.limits,
                        min: value,
                      },
                    });
                  }}
                />
              )}
            </div>
            <div className="w-1/3 pl-4">
              {component.type === "time" ? (
                <TimeInput
                  label="Max Limit"
                  value={
                    component.limits?.max
                      ? (component.limits.max as string)
                      : ""
                  }
                  onChange={(value) => {
                    props.onChange({
                      ...component,
                      limits: {
                        ...component.limits,
                        max: value,
                      },
                    });
                  }}
                />
              ) : (
                <DateInput
                  label="Max Limit"
                  value={
                    component.limits?.max
                      ? format(component.limits.max as Date, "yyyy-MM-dd")
                      : ""
                  }
                  onChange={(value) => {
                    props.onChange({
                      ...component,
                      limits: {
                        ...component.limits,
                        max: value,
                      },
                    });
                  }}
                />
              )}
            </div>
          </div>
          <div className="w-full">
            <BindedDataInput
              component={{
                variableName: component.variableName,
                required: component.required,
              }}
              onChange={(updates) =>
                props.onChange({ ...component, ...updates })
              }
            />
          </div>
        </div>
      </div>
    </ComponentFormItem>
  );
}

export { DateInputComponentForm };
