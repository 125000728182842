type NumberInputProps = {
  value: number | undefined;
  label: string;
  onChange: (newValue: number | undefined) => void;
  className?: string;
};

function NumberInput({ value, onChange, label, className }: NumberInputProps) {
  return (
    <div className="relative">
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <input
        value={value}
        type="number"
        onChange={(e) =>
          onChange(e.target.value ? parseInt(e.target.value) : undefined)
        }
        className={`${className} mt-1 block w-full rounded-md border-gray-300 py-2 px-3 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
      />
    </div>
  );
}

export { NumberInput };
