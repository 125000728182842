import { BaseScreen, NavigatedScreen } from ".";

export const AUTH_TYPES = ["apple", "facebook", "google", "email"] as const;

export type AuthScreen = {
  type: "auth";
  title: string;
  description: string;
  privacyText: string;
  authTypes: (typeof AUTH_TYPES)[number][];
} & BaseScreen &
  NavigatedScreen;
