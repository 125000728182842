type TimeInputProps = {
  value: string;
  label: string;
  onChange: (newValue: string | undefined) => void;
  className?: string;
};

function TimeInput({ value, onChange, label, className }: TimeInputProps) {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <input
        value={value}
        type="time"
        onChange={(e) => onChange(e.target.value ? e.target.value : undefined)}
        className={`${className} mt-1 block w-full rounded-md border-gray-300 py-2 px-3 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
      />
    </div>
  );
}

export { TimeInput };
