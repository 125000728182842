import { ArrowLeft } from "react-feather";
import { getStyles } from "src/shared/helpers/styleConverter";
import { CURRENT_THEME } from "src/shared/themes";
import { PageScreen } from "src/types/screens/PageScreen";

import iosDatePickerImage from "src/assets/ios-date-picker.png";

function BottomBlock({
  data,
  appearance,
  canGoBack,
  keyboardAtBottom,
}: {
  data: PageScreen["bottomBlock"];
  canGoBack: boolean;
  appearance: "light" | "dark";
  keyboardAtBottom: boolean;
}) {
  if (!data && !canGoBack) return null;
  return (
    <>
      <div
        style={{
          height: keyboardAtBottom ? 88 : 122,
          bottom: keyboardAtBottom ? 250 : 0,
        }}
        className="absolute left-0 right-0 p-4 flex"
      >
        {canGoBack && (
          <div
            style={{
              borderRadius: 12,
              backgroundColor: CURRENT_THEME.colors.fillBackButton[appearance],
            }}
            className="w-14 h-14 bg-black flex-shrink-0 mr-2 flex items-center justify-center"
          >
            <ArrowLeft
              size={20}
              color={appearance === "light" ? "#6A6D82" : "#F5F6F8"}
            />
          </div>
        )}
        {data && data.submitButton && (
          <button
            style={getStyles({ style: data.submitButton.style, appearance })}
            className="w-full"
          >
            {data.submitButton.text}
          </button>
        )}
      </div>
      {keyboardAtBottom && (
        <div
          style={{
            height: 250,
            backgroundImage: `url(${iosDatePickerImage})`,
          }}
          className="absolute bottom-0 left-0 right-0 bg-cover bg-no-repeat bg-center"
        />
      )}
    </>
  );
}

export { BottomBlock };
