import { Check } from "react-feather";

type CheckboxProps = {
  checked: boolean;
  onChange: (newChecked: boolean) => void;
  label: string;
};

function Checkbox({ checked, onChange, label }: CheckboxProps) {
  return (
    <button onClick={() => onChange(!checked)} className="flex items-center">
      <div className="pointer-events-none flex-shrink-0 w-5 h-5 rounded-md bg-white mr-3 border border-gray-300 flex items-center justify-center">
        {checked && <Check size={14} className="text-indigo-600" />}
      </div>
      <p className="pointer-events-none text-sm text-left">{label}</p>
    </button>
  );
}

export { Checkbox };
