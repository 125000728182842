type SelectProps = {
  value: any;
  label: string;
  onChange: (newValue: any) => void;
  options: { label: string; value: any; disabled?: boolean }[];
};

function Select({ value, onChange, label, options }: SelectProps) {
  const onChangeValue = (newValue: string) => {
    if (newValue === "undefined") {
      onChange(undefined);
    } else {
      onChange(newValue);
    }
  };

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <select
        value={value}
        onChange={(e) => onChangeValue(e.target.value)}
        className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
      >
        {options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            disabled={!!option.disabled}
          >
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export { Select };
