import { CURRENT_THEME } from "src/shared/themes";
import { ImageComponent } from "src/types/components/ImageComponent";

function ImageComponentView({
  component,
  appearance,
}: {
  component: ImageComponent;
  appearance: "light" | "dark";
}) {
  if (component.height === undefined) {
    // = auto
    return (
      <img
        style={{
          backgroundColor: CURRENT_THEME.colors.extBlue20[appearance],
          width: component.width,
        }}
        src={component.url}
        alt="component"
      />
    );
  }

  return (
    <div
      style={{
        // backgroundColor: CURRENT_THEME.colors.extBlue20[appearance],
        backgroundImage: `url(${component.url})`,
        width: component.width,
        height: component.height,
        backgroundSize: component.fillMode,
      }}
    />
  );
}

export { ImageComponentView };
