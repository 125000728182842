import { PlainStyle, StyledComponent } from "src/types/components";

function getStyles({
  style,
  appearance,
}: {
  style: StyledComponent["style"];
  appearance: "light" | "dark";
}): PlainStyle {
  if (!style) return {};

  return {
    height: style.height,
    textAlign: style.textAlign,
    backgroundColor: style.backgroundColor?.[appearance],
    color: style.textColor?.[appearance],
    fontFamily: style.fontFamily,
    fontWeight: style.fontWeight,
    fontSize: style.fontSize,
    letterSpacing: style.letterSpacing,
    lineHeight: style.lineHeight ? `${style.lineHeight}px` : undefined,
    borderWidth: style.borderWidth,
    borderColor: style.borderColor?.[appearance],
    borderRadius: style.cornerRadius,
    shadow: style.shadow && {
      color: style.shadow.color?.[appearance],
      opacity: style.shadow.opacity,
      offset: style.shadow.offset && {
        x: style.shadow.offset.x,
        y: style.shadow.offset.y,
      },
      radius: style.shadow.radius,
    },
  };
}

export { getStyles };
