import { create } from "zustand";
import { nanoid } from "nanoid";
import { OnboardingScreen } from "src/types/screens";
import { PageScreen } from "src/types/screens/PageScreen";
import { CURRENT_THEME } from "src/shared/themes";
import { EXAMPLE_SCREENS } from "./example";
import { WebViewScreen } from "src/types/screens/WebViewScreen";
import { PaywallScreen } from "src/types/screens/PaywallScreen";
import { ModalScreen } from "src/types/screens/ModalScreen";
import { arrayMoveImmutable } from "array-move";
import { AuthScreen } from "src/types/screens/AuthScreen";

interface AppState {
  screens: OnboardingScreen[];
  addScreen: (
    type: OnboardingScreen["type"],
    afterId?: OnboardingScreen["id"]
  ) => void;
  updateScreen: (screen: OnboardingScreen) => void;
  deleteScreen: (id: OnboardingScreen["id"]) => void;
  moveScreen: (id: OnboardingScreen["id"], direction: "left" | "right") => void;
  cleanScreens: () => void;
}

export const DEFAULT_SCREEN: PageScreen = {
  id: "UUID",
  type: "page",
  name: "NewScreen",
  components: [],
  navigationParams: {
    canGoBack: true,
    shouldBeSkippedWhenGoingBack: false,
    conditionsForShow: [],
  },
  appearance: {
    backgroundColor: CURRENT_THEME.colors.bgBase,
  },
};

export const DEFAULT_WEBVIEW_SCREEN: WebViewScreen = {
  id: "UUID",
  type: "webview",
  name: "NewWebViewScreen",
  url: "https://onboarding-builder.pages.dev/webview.html",
  navigationParams: {
    canGoBack: true,
    shouldBeSkippedWhenGoingBack: false,
    conditionsForShow: [],
  },
};

export const DEFAULT_PAYWALL_SCREEN: PaywallScreen = {
  id: "UUID",
  type: "paywall",
  name: "NewPaywall",
  url: "https://paywall.simple-life-app.com/#/paywall",
  navigationParams: {
    canGoBack: true,
    shouldBeSkippedWhenGoingBack: false,
    conditionsForShow: [],
  },
};

export const DEFAULT_MODAL_SCREEN: ModalScreen = {
  id: "UUID",
  type: "modal",
  name: "NewModalScreen",
  components: [],
  navigationParams: {
    canGoBack: true,
    shouldBeSkippedWhenGoingBack: true,
    conditionsForShow: [],
  },
  appearance: CURRENT_THEME.screens.modal,
};

export const DEFAULT_AUTH_SCREEN: AuthScreen = {
  id: "UUID",
  type: "auth",
  name: "AuthScreen",
  navigationParams: {
    canGoBack: true,
    shouldBeSkippedWhenGoingBack: true,
    conditionsForShow: [],
  },
  title:
    "Jumpstart your wellness and nutrition journey with Simple, trusted by 13+ million members",
  description: "Sign in or create an account to save your progress:",
  authTypes: ["apple", "facebook", "google", "email"],
  privacyText:
    "By creating an account, you agree to Simple’s [Terms of Use](https://simple.life/tos.htm) and [Privacy Policy](https://simple.life/privacy.htm)",
};

const useAppStore = create<AppState>()((set) => ({
  screens: EXAMPLE_SCREENS,
  addScreen: (type = "page", afterId) => {
    let newScreen: OnboardingScreen;

    switch (type) {
      case "page":
        newScreen = {
          ...DEFAULT_SCREEN,
          id: nanoid(),
        };
        break;
      case "paywall":
        newScreen = {
          ...DEFAULT_PAYWALL_SCREEN,
          id: nanoid(),
        };
        break;
      case "webview":
        newScreen = {
          ...DEFAULT_WEBVIEW_SCREEN,
          id: nanoid(),
        };
        break;
      case "modal":
        newScreen = {
          ...DEFAULT_MODAL_SCREEN,
          id: nanoid(),
        };
        break;
      case "auth":
        // If auth already exists, don't add another one
        if (useAppStore.getState().screens.some((s) => s.type === "auth")) {
          alert("You can only have one auth screen");
          return;
        }

        newScreen = {
          ...DEFAULT_AUTH_SCREEN,
          id: nanoid(),
        };
        break;
      default:
        break;
    }

    set((state) => {
      if (!afterId) {
        return {
          screens: [...state.screens, newScreen],
        };
      }

      const index = state.screens.findIndex((s) => s.id === afterId);
      const newScreens = [...state.screens];
      newScreens.splice(index + 1, 0, newScreen);

      return {
        screens: newScreens,
      };
    });
  },
  cleanScreens: () => {
    set(() => ({
      screens: [],
    }));
  },
  updateScreen: (screen: OnboardingScreen) =>
    set((state) => ({
      screens: state.screens.map((s) => (s.id === screen.id ? screen : s)),
    })),
  deleteScreen: (id: string) =>
    set((state) => ({
      screens: state.screens.filter((s) => s.id !== id),
    })),
  moveScreen: (id: string, direction: "left" | "right") => {
    set((state) => {
      const index = state.screens.findIndex((s) => s.id === id);
      const newIndex =
        direction === "left"
          ? Math.max(index - 1, 0)
          : Math.min(index + 1, state.screens.length - 1);
      const newScreens = arrayMoveImmutable(state.screens, index, newIndex);

      return {
        screens: newScreens,
      };
    });
  },
}));

export { useAppStore };
