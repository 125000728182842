import { AlertTriangle, Trash2 } from "react-feather";
import { OnboardingComponent } from "src/types";
import { ButtonComponentForm } from "./ui/ButtonComponentForm";
import { CheckboxComponentForm } from "./ui/CheckboxComponentForm";
import { DateInputComponentForm } from "./ui/DateInputComponentForm";
import { DividerComponentForm } from "./ui/DividerComponentForm";
import { ImageComponentForm } from "./ui/ImageComponentForm";
import { ListInputComponentForm } from "./ui/ListInputComponentForm";
import { NumberInputComponentForm } from "./ui/NumberInputComponentForm";
import { TextComponentForm } from "./ui/TextComponentForm";
import { TextInputComponentForm } from "./ui/TextInputComponentForm";
import { SpacerComponentForm } from "./ui/SpacerComponentForm";

export type ComponentFormProps = {
  component: OnboardingComponent;
  onChange: (component: OnboardingComponent) => void;
  onDelete: () => void;
};

export function ComponentFormItem(props: {
  onDelete: () => void;
  children: JSX.Element | JSX.Element[];
}) {
  return (
    <div className="bg-white rounded-xl p-4 flex flex-col relative">
      <button
        onClick={() => props.onDelete()}
        className="absolute w-12 h-12 right-0 top-0 flex items-center justify-center hover:scale-105"
      >
        <Trash2 size={20} className="text-gray-500 pointer-events-none" />
      </button>
      <div className="flex space-x-4">{props.children}</div>
    </div>
  );
}

function ComponentForm(props: ComponentFormProps) {
  switch (props.component.id) {
    case "text":
      return <TextComponentForm {...props} />;
    case "listInput":
      return <ListInputComponentForm {...props} />;
    case "button":
      return <ButtonComponentForm {...props} showMargins={true} />;
    case "checkbox":
      return <CheckboxComponentForm {...props} />;
    case "image":
      return <ImageComponentForm {...props} />;
    case "textInput":
      return <TextInputComponentForm {...props} />;
    case "dateInput":
      return <DateInputComponentForm {...props} />;
    case "numberInput":
      return <NumberInputComponentForm {...props} />;
    case "divider":
      return <DividerComponentForm {...props} />;
    case "spacer":
      return <SpacerComponentForm {...props} />;
    default:
      return (
        <div className="border-l-4 border-red-400 bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <AlertTriangle
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">
                <b>{(props.component as any).id}</b> form is not supported yet.
              </p>
            </div>
          </div>
        </div>
      );
  }
}

export { ComponentForm };
