import { ScreenGroupsInfo } from "src/screens/App";
import { OnboardingScreen } from "src/types/screens";
import { ModalScreen } from "src/types/screens/ModalScreen";
import { PageScreen } from "src/types/screens/PageScreen";
import { PaywallScreen } from "src/types/screens/PaywallScreen";
import { WebViewScreen } from "src/types/screens/WebViewScreen";
import { ModalView } from "./ui/ModalView";
import statusIcons from "src/assets/status-bar-icons.svg";
import statusIconsDark from "src/assets/status-bar-icons-dark.svg";

import { PageView } from "./ui/PageView";
import { PaywallView } from "./ui/PaywallView";
import { WebView } from "./ui/WebView";
import { SCREEN_SIZE } from "src/config";
import {
  ArrowLeft,
  ArrowRight,
  HelpCircle,
  PlusCircle,
  Trash2,
} from "react-feather";
import { AuthView } from "./ui/AuthView";
import { AuthScreen } from "src/types/screens/AuthScreen";

function ScreenWrapper({
  screen,
  children,
  onClick,
  opened,
  groupsInfo,
  onDelete,
  onMove,
  onAddAfter,
}: {
  children: React.ReactNode;
  screen: OnboardingScreen | any;
  opened: boolean;
  onClick: () => void;
  groupsInfo?: ScreenGroupsInfo;
  onDelete: (id: string) => void;
  onMove: (id: string, direction: "left" | "right") => void;
  onAddAfter: (id: string) => void;
}) {
  const screenStyle = screen.appearance?.style ?? "light";
  const backgroundColor = screen.appearance?.backgroundColor
    ? screen.appearance.backgroundColor[screenStyle]
    : "white";

  const isOptionalScreen = screen.navigationParams.conditionsForShow.length > 0;

  const isStartGroup = screen.groupParams?.startGroup ?? false;
  const hideGroup = screen.groupParams?.hideGroup ?? false;

  const isInGroup = groupsInfo !== undefined;

  return (
    <div
      style={{
        backgroundColor,
        width: SCREEN_SIZE.width,
        height: SCREEN_SIZE.height,
        marginRight: !opened ? -SCREEN_SIZE.width * 0.3 : 0,
        marginLeft: !opened ? -SCREEN_SIZE.width * 0.3 : 0,
        paddingTop: screen.appearance?.ignoreSafeArea ? 0 : 44,
      }}
      className={`${
        !opened ? "scale-30" : "scale-100"
      } group shadow-xl rounded-2xl transform origin-top transition-all duration-300 ease-in-out flex flex-col items-start justify-start relative`}
    >
      {/* Actions */}
      {!opened && (
        <div className="w-full h-32 absolute top-full mt-12 flex items-center justify-between">
          <button
            onClick={(e) => {
              e.stopPropagation();
              onMove(screen.id, "left");
            }}
          >
            <ArrowLeft
              className="text-gray-300 hover:text-indigo-600"
              size={64}
            />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onDelete(screen.id);
            }}
          >
            <Trash2 className="text-gray-300 hover:text-red-500" size={64} />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onMove(screen.id, "right");
            }}
          >
            <ArrowRight
              className="text-gray-300 hover:text-indigo-600"
              size={64}
            />
          </button>
        </div>
      )}
      {!opened && (
        <div className="w-32 left-full h-32 absolute top-1/2 -mt-16 p-6">
          <button
            onClick={(e) => {
              e.stopPropagation();
              onAddAfter(screen.id);
            }}
            className="w-full h-full items-center justify-center flex"
          >
            <PlusCircle
              size={64}
              className="text-gray-300 hover:text-indigo-600"
            />
          </button>
        </div>
      )}

      {isOptionalScreen && !opened && (
        <HelpCircle
          size={48}
          className="pointer-events-none text-gray-500 absolute -top-16 left-1/2 transform -translate-x-1/2"
        />
      )}

      {isInGroup && !isStartGroup && (
        <div
          style={{
            left: -SCREEN_SIZE.width * 0.34,
          }}
          className={`${
            hideGroup ? "border-dashed" : "border-solid"
          } transition pointer-events-none absolute right-0 -top-20 h-0 border-b-8 border-indigo-600`}
        ></div>
      )}

      {isStartGroup && (
        <div className="transition pointer-events-none absolute left-0 right-0 -top-20 h-0 border-b-8 border-indigo-600">
          <p className="absolute text-4xl font-bold tracking-wider uppercase -top-16 whitespace-nowrap">
            {screen.groupParams?.startGroup?.title}
          </p>
        </div>
      )}

      {children}

      {/* Status bar */}
      <div
        className="flex items-center absolute w-full top-0"
        style={{ height: 44, paddingLeft: 28, paddingRight: 8 }}
      >
        <div
          className={`${
            screen.appearance?.statusBarStyle === "light"
              ? "text-white"
              : "text-black"
          } text-base mr-auto font-semibold`}
        >
          9:41
        </div>
        <img
          style={{ height: 13 }}
          src={
            screen.appearance?.statusBarStyle === "light"
              ? statusIconsDark
              : statusIcons
          }
          alt="status"
        />
      </div>

      {/* Bottom bar */}
      <div
        className="absolute w-full bottom-0 left-0 flex justify-center"
        style={{ height: 34 }}
      >
        <div
          className="absolute bg-black rounded-full bottom-2"
          style={{ height: 5, width: 134 }}
        ></div>
      </div>

      {/* Clickable area to open screen */}
      <button
        onClick={() => onClick()}
        className="absolute w-full h-full hover:ring-8 ring-indigo-600 rounded-2xl top-0"
      ></button>
    </div>
  );
}

function ScreenView({
  screen,
  selectedScreen,
  onChooseScreen,
  onDeleteScreen,
  onMoveScreen,
  onAddAfterScreen,
  groupsInfo,
}: {
  screen: OnboardingScreen;
  selectedScreen: OnboardingScreen | null;
  onChooseScreen: (screen: OnboardingScreen) => void;
  onDeleteScreen: (id: string) => void;
  onMoveScreen: (id: string, direction: "left" | "right") => void;
  onAddAfterScreen: (id: string) => void;
  groupsInfo?: ScreenGroupsInfo;
}) {
  const opened = selectedScreen?.id === screen.id;

  const screenContent = () => {
    switch (screen.type) {
      case "page":
        const appearance = screen.appearance?.style ?? "light";
        return (
          <PageView
            key={screen.id}
            screen={screen as PageScreen}
            groupsInfo={groupsInfo}
            appearance={appearance}
          />
        );
      case "modal":
        return <ModalView key={screen.id} screen={screen as ModalScreen} />;
      case "webview":
        return <WebView key={screen.id} screen={screen as WebViewScreen} />;
      case "paywall":
        return <PaywallView key={screen.id} screen={screen as PaywallScreen} />;
      case "auth":
        return <AuthView key={screen.id} screen={screen as AuthScreen} />;
      default:
        return null;
    }
  };

  return (
    <ScreenWrapper
      screen={screen}
      opened={opened}
      onClick={() => onChooseScreen(screen)}
      groupsInfo={groupsInfo}
      onDelete={onDeleteScreen}
      onMove={onMoveScreen}
      onAddAfter={onAddAfterScreen}
    >
      {screenContent()}
    </ScreenWrapper>
  );
}

export { ScreenView };
