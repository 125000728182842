import { Crosshair } from "react-feather";
import { Select } from "src/shared/components/Select";
import { TextInput } from "src/shared/components/TextInput";
import { CURRENT_THEME } from "src/shared/themes";
import { ACTIONS } from "src/types";
import {
  ButtonComponent,
  BUTTON_TYPES,
} from "src/types/components/ButtonComponent";
import { ComponentFormItem, ComponentFormProps } from "..";
import { SpacedComponentForm } from "./SpacedComponentForm";

type ButtonComponentFormProps = ComponentFormProps & {
  showMargins?: boolean;
};

function ButtonComponentForm(props: ButtonComponentFormProps) {
  const component = props.component as ButtonComponent;

  return (
    <ComponentFormItem onDelete={props.onDelete}>
      <div className="flex space-x-4 w-full">
        {props.showMargins ? (
          <SpacedComponentForm
            component={component}
            onChange={(margins) =>
              props.onChange({
                ...component,
                margins,
              })
            }
          >
            <Crosshair />
          </SpacedComponentForm>
        ) : (
          <Crosshair className="flex-shrink-0" />
        )}
        <div className="flex flex-col w-full pr-8">
          <div className="flex w-full mb-3">
            <div className="w-1/3">
              <Select
                label="Button type"
                value={component.type}
                onChange={(value) => {
                  props.onChange({
                    ...component,
                    type: (value as ButtonComponent["type"]) ?? BUTTON_TYPES[0],
                    style:
                      CURRENT_THEME.components.button[
                        value as ButtonComponent["type"]
                      ],
                  });
                }}
                options={BUTTON_TYPES.map((type) => ({
                  label: type,
                  value: type,
                }))}
              />
            </div>
            <div className="w-1/3 pl-4">
              <TextInput
                label="Text"
                value={component.text ?? ""}
                onChange={(value) => {
                  props.onChange({
                    ...component,
                    text: value ?? "",
                  });
                }}
              />
            </div>
            <div className="w-1/3 pl-4">
              <Select
                label="Action"
                value={component.action}
                onChange={(value) => {
                  props.onChange({
                    ...component,
                    action: value as ButtonComponent["action"],
                  });
                }}
                options={ACTIONS.map((action) => ({
                  label: action,
                  value: action,
                }))}
              />
            </div>
          </div>
        </div>
      </div>
    </ComponentFormItem>
  );
}

export { ButtonComponentForm };
