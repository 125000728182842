import { AddButton } from "src/shared/components/AddButton";
import { CURRENT_THEME } from "src/shared/themes";
import { OnboardingComponent } from "src/types";
import { COMPONENTS, ComponentType } from "src/types/components";

function AddComponentForm({
  onAdd,
  existedComponentTypes,
}: {
  onAdd: (type: OnboardingComponent) => void;
  existedComponentTypes: OnboardingComponent["id"][];
}) {
  const handleAdd = (type: ComponentType) => {
    switch (type) {
      case "text":
        onAdd({
          id: "text",
          type: "title1",
          text: "Replace this text",
          containsMarkdown: false,
          style: CURRENT_THEME.components.text["title1"],
          margins: {
            left: 16,
            right: 16,
          },
        });
        break;
      case "listInput":
        onAdd({
          id: "listInput",
          type: "single",
          options: [],
          variableName: "listInputVarName",
          required: false,
          style: CURRENT_THEME.components.listInput.list,
          selectedOptionStyle:
            CURRENT_THEME.components.listInput.selectedOption,
          optionStyle: CURRENT_THEME.components.listInput.option,
          margins: {
            left: 16,
            right: 16,
          },
        });
        break;
      case "button":
        onAdd({
          id: "button",
          type: "main",
          text: "Button text",
          action: "next",
          style: CURRENT_THEME.components.button.main,
          margins: {
            left: 16,
            right: 16,
          },
        });
        break;
      case "checkbox":
        onAdd({
          id: "checkbox",
          label: "Checkbox label",
          containsMarkdown: false,
          style: CURRENT_THEME.components.checkbox,
          variableName: "checkboxVarName",
          required: false,
          margins: {
            left: 16,
            right: 16,
          },
        });
        break;
      case "image":
        onAdd({
          id: "image",
          url: "https://onboarding-builder.pages.dev/assets/image.png",
          width: "100%",
          fillMode: "cover",
        });
        break;
      case "textInput":
        onAdd({
          id: "textInput",
          type: "text",
          style: CURRENT_THEME.components.textInput.style,
          paddings: CURRENT_THEME.components.textInput.paddings,
          variableName: "textInputVarName",
          required: false,
          margins: {
            left: 16,
            right: 16,
          },
        });
        break;
      case "dateInput":
        if (existedComponentTypes.includes("dateInput")) {
          alert("You can only add one date input component to screen");
          return;
        }
        onAdd({
          id: "dateInput",
          type: "date",
          style: CURRENT_THEME.components.dateInput.style,
          paddings: CURRENT_THEME.components.dateInput.paddings,
          variableName: "dateInputVarName",
          required: false,
          margins: {
            left: 16,
            right: 16,
          },
        });
        break;
      case "numberInput":
        if (existedComponentTypes.includes("numberInput")) {
          alert("You can only add one number input component to screen");
          return;
        }
        onAdd({
          id: "numberInput",
          type: "number",
          style: CURRENT_THEME.components.numberInput.style,
          paddings: CURRENT_THEME.components.numberInput.paddings,
          variableName: "numberInputVarName",
          required: false,
          margins: {
            left: 16,
            right: 16,
          },
        });
        break;
      case "divider":
        onAdd({
          id: "divider",
          style: CURRENT_THEME.components.divider.style,
        });
        break;
      case "spacer":
        onAdd({ id: "spacer" });
        break;
    }
  };

  return (
    <div className="flex flex-wrap pl-6">
      {COMPONENTS.map((type) => (
        <AddButton
          key={type}
          onClick={() => handleAdd(type)}
          className="mr-2 mb-2"
          label={type}
        />
      ))}
    </div>
  );
}

export { AddComponentForm };
