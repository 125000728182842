import { SpacedComponent, StyledComponent } from ".";
import { Action } from "..";

export const BUTTON_TYPES = [
  "main",
  "secondary",
  "modalMain",
  "modalSecondary",
] as const;

export type ButtonType = (typeof BUTTON_TYPES)[number];

export type ButtonComponent = {
  id: "button";
  type: ButtonType;
  text: string;
  action: Action;
  hasInactiveState?: boolean; // shows as disabled if action is not available (hide by default)
} & SpacedComponent &
  StyledComponent;
