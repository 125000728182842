import { CURRENT_THEME } from "src/shared/themes";
import { ComponentView } from "../../ComponentView";
import { ModalScreen } from "src/types/screens/ModalScreen";
import { getStyles } from "src/shared/helpers/styleConverter";

function ModalView({ screen }: { screen: ModalScreen }) {
  const appearance = "light";
  const styles = getStyles({
    style: screen.appearance?.style,
    appearance,
  });

  return (
    <div className="w-full h-full absolute overflow-hidden left-0 top-0 rounded-2xl">
      <div
        style={{
          backgroundColor:
            CURRENT_THEME.colors[
              screen.appearance?.overlayColor?.name ?? "bgOverlay"
            ][appearance],
        }}
        className="absolute left-0 top-0 right-0 bottom-0 rounded-2xl"
      ></div>
      {/* Content */}
      <div style={{ bottom: 58 }} className="w-full absolute px-4">
        {/* Components */}
        <div
          style={{
            backgroundColor: styles.backgroundColor,
            borderRadius: styles.borderRadius,
          }}
          className="relative w-full"
        >
          {screen.components.map((component, index) => (
            <ComponentView
              key={`${component.id}_${index}`}
              component={component}
              appearance={appearance}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export { ModalView };
